import "./Dashboard.css";
import { BsPlusLg } from "react-icons/bs";
import Modal from "react-modal";
import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { components } from "react-select";
import { RiInformationLine } from "react-icons/ri";
import { BsXCircle } from "react-icons/bs";
import { MdCheckCircle } from "react-icons/md";
import { GiCircle } from "react-icons/gi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import grayPfp from "../../assets/gray.PNG";
import {
  ChartRangeContext,
  IsMobileContext,
  SelectedProjectContext,
  UserInfoContext,
} from "../../contexts";
import {
  Button,
  Spinner,
  Tooltip,
  OverlayTrigger,
  ProgressBar,
} from "react-bootstrap";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import closebtn from "../../assets/closebtn.svg";
import candle from "../../assets/candle.png";
import campfire from "../../assets/campfire.png";
import smallicon from "../../assets/small-icon-blue.png";

import hiresluxi from "../../assets/luxi-analysis.png";
import microsoftpartner from "../../assets/microsoft-partner.png";
import paymentStare from "../../assets/payment-stare.png";
import x from "../../assets/x.png";
import rolling from "../../assets/dashboard-graphic.png";
import {
  verifyGoogleAnalytics,
  getMetricsDescriptions,
  getMetricsQuery,
  fetchAnalyticsData,
  buildMetricsCardElements,
  fetchAnalyticsChartData,
  getGTMQuery,
  fetchGTMData,
  fillGTMCardFields,
  fetchGTMChartData,
  getInsightsPid,
  formatPercentageDiff,
  getChartInsights,
  DataAccuracyWarning,
  getMetricsUrls,
  isFlippedMetric,
  getClientMetricsView,
  setClientMetricsViewBlue,
  setClientMetricsViewGrey,
  fetchAnalyticsDataMatomo,
  getMetricsQueryMatomo,
  beautifyPath,
  getMatomoDropdownFields,
  getMatomoCardOptionsPage,
  getMatomoCardOptionsEcommerce,
  getMatomoCardOptionsEvents,
  getMatomoCardOptionsGeneral,
  fetchAnalyticsMatomoChartData,
  addHTTPS,
  isValidMatomoProject,
  updateProjectApproval,
  checkTrackingStatus,
  calcDaysLeft40,
} from "../../helpers";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import MetricsChart from "../reusables/Chart/MetricsChart.tsx";
import { BuildPagesGrid } from "../../helpers";
import { ChartInsightsContext } from "../../contexts";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import { FiArrowLeft } from "react-icons/fi";

import logo from "../../assets/black-logo.png";
import { toast } from "react-toastify";

export default function Dashboard({ invitedUserWithoutProjects }) {
  const selectedProject = useContext(SelectedProjectContext);
  const userInfo = useContext(UserInfoContext);
  const isMobileView = useContext(IsMobileContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [metricsModalOpen, setMetricsModalOpen] = useState(false);
  const [trackerModalOpen, setTrackerModalOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [justCreatedProjectModalOpen, setJustCreatedProjectModalOpen] =
    useState(location.state?.justCreatedProject || false);

  // Analysis status modal
  const [justCreatedProjectEnqueueOpen, setJustCreatedProjectEnqueueOpen] =
    useState(location.state?.justCreatedProjectEnqueue || false);
  const [analysisLogo, setAnalysisLogo] = useState(
    location.state?.logo || null,
  );
  const [analysisState, setAnalysisState] = useState(null);
  const [analysisId, setAnalysisId] = useState(
    location.state?.analysisId || null,
  );
  const [progressBar, setProgressBar] = useState(0);
  //END Analysis status modal

  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedSource, setSelectedSource] = useState({
    value: "matomo",
    label: "Matomo",
  });
  const [selectedData, setSelectedData] = useState(null);

  const [mobileDoubleMenuSelector, setMobileDoubleMenuSelector] =
    useState(false);
  const [mobileMetricsFollowUp, setMobileMetricsFollowUp] = useState(false);
  const [mobileTrackerFollowUp, setMobileTrackerFollowUp] = useState(false);
  const [metricsLoading, setMetricsLoading] = useState(true);
  const [queryLoading, setQueryLoading] = useState(true);
  const [metricsCards, setMetricsCards] = useState([]);
  const [chartInsights, setChartInsights] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filteredMetricsCards, setFilteredMetricsCards] = useState([]);
  const [filteredPagesCards, setFilteredPagesCards] = useState([]);

  const [analyticsCtaClicked, setAnalyticsCtaClicked] = useState(false);

  const [metricsSectionWidth, setMetricsSectionWidth] = useState(0);
  const [metricsStackVertical, setMetricsStackVertical] = useState(false);

  const [metricInsights, setMetricInsights] = useState(null);
  const [pageSpeedInsights, setPageSpeedInsights] = useState(0);

  const [dashboardWidth, setDashboardWidth] = useState(0);
  const dashboardRef = useRef(null);

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const [justAcceptedProject, setJustAcceptedProject] = useState(
    location.state?.projectName || false,
  );

  const [currentChart, setCurrentChart] = useState(-1);

  const [chartOrCard, setChartOrCard] = useState("card");
  const [isSeeAllPagesOverlapping, setIsSeeAllPagesOverlapping] =
    useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartReports, setChartReports] = useState([]);
  const [chartInsightsLoading, setChartInsightsLoading] = useState(true);

  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [dashboardMetricsDataLoading, setDashboardMetricsDataLoading] =
    useState(true);

  //See all dashboard mode
  const [dashboardMode, setDashboardMode] = useState("dashboard");

  const [typeFilter, setTypeFilter] = useState("All");
  const [typeIsMenuOpen, setTypeIsMenuOpen] = useState(false);
  const [metricsFilter, setMetricsFilter] = useState("All");
  const [metricsIsMenuOpen, setMetricsIsMenuOpen] = useState(false);

  const [dateFilter, setDateFilter] = useState("Last month");
  const [dateIsMenuOpen, setDateIsMenuOpen] = useState(false);
  const [pageFilter, setPageFilter] = useState("All");
  const [pageMIsMenuOpen, setPageMIsMenuOpen] = useState(false); //Metrics follow page dropdown filter
  const [pageDropdownOptions, setPageDropdownOptions] = useState([]);
  const [metricTypeDropdownOptions, setMetricTypeDropdownOptions] = useState(
    [],
  );

  const [firstPart, setFirstPart] = useState(null);
  const [secondPart, setSecondPart] = useState(null);
  const [sign, setSign] = useState(null);

  const [pagesOptionsLoading, setPagesOptionsLoading] = useState(true);
  const [matomoDropdownsLoading, setMatomoDropdownsLoading] = useState(true);

  const [adderModalLoading, setAdderModalLoading] = useState(false);

  const [validMatomo, setValidMatomo] = useState(false);

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    const validMatomo = isValidMatomoProject(selectedProject);
    setValidMatomo(validMatomo);
    const isGA4 = selectedProject.platform === "GA4";
    const isMatomo = selectedProject.platform === "matomo" && validMatomo;

    setAdderModalLoading(
      isGA4 || isMatomo ? pagesOptionsLoading && matomoDropdownsLoading : false,
    );
  }, [pagesOptionsLoading, matomoDropdownsLoading, selectedProject]);

  const [matomoCategory, setMatomoCategory] = useState(null);
  const [matomoCategoryOptions, setMatomoCategoryOptions] = useState([
    { label: "The whole website", value: "the whole website" },
    { label: "Page", value: "page" },
    { label: "Goal", value: "goal" },
    { label: "Event", value: "event" },
  ]);
  const [matomoPages, setMatomoPages] = useState([]);
  const [matomoGoals, setMatomoGoals] = useState([]);
  const [matomoEvents, setMatomoEvents] = useState([]);

  const [selectedMatomoPage, setSelectedMatomoPage] = useState(null);
  const [selectedMatomoGoal, setSelectedMatomoGoal] = useState(null);
  const [selectedMatomoEvent, setSelectedMatomoEvent] = useState(null);

  const [isMatomoCategoryOpen, setIsMatomoCategoryOpen] = useState(false);
  const [isMatomoPageOpen, setIsMatomoPageOpen] = useState(false);
  const [isMatomoGoalOpen, setIsMatomoGoalOpen] = useState(false);
  const [isMatomoEventOpen, setIsMatomoEventOpen] = useState(false);
  const [isMatomoMetricTypeOpen, setIsMatomoMetricTypeOpen] = useState(false);

  const [matomoMetricType, setMatomoMetricType] = useState(null);

  const [daysUntilMetrics, setDaysUntilMetrics] = useState(null);

  const [insightDropoffs, setInsightDropoffs] = useState(0);

  const tabItems = [
    {
      name: "Metrics follow",
      label: "Metrics follow",
    },
    {
      name: "Performance metrics",
      label: "Performance metrics",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabItems[0].name);

  const metricsFilters = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--blue-opacity10)" : "inherit",
      color: state.isSelected ? "var(--main-blue)" : "inherit",
      margin: "4px 0px",
    }),
  };

  const [dateOptions] = useState([
    { value: "Last month", label: "Last month" },
  ]);

  const typeOptions = [
    { value: "All", label: "All" },
    { value: "Negative", label: "Negative" },
    { value: "Positive", label: "Positive" },
    { value: "Neutral", label: "Neutral" },
  ];

  //End see all dashboard mode

  // Chart vars
  const [chartOptions, setChartOptions] = useState([]);
  const [chartSelectionOpen, setChartSelectionOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState(null);

  const [chartRangeOptions, setChartRangeOptions] = useState([
    { label: "Last week", value: 2 },
    { label: "Last month", value: 8 },
    { label: "Last 3 months", value: 24 },
    { label: "Last year", value: 96 },
  ]);

  const [chartRangeOpen, setChartRangeOpen] = useState(false);
  const [selectedChartRange, setSelectedChartRange] = useState({
    label: "Last week",
    value: 2,
  });

  // Used to make sure page list return is for correct project
  const callCounter = useRef(0);

  async function populateMatomoDropdowns() {
    if (!isValidMatomoProject(selectedProject)) {
      setMatomoGoals([]);
      setMatomoEvents([]);
      setMatomoPages([]);
      setMatomoDropdownsLoading(false);
      return;
    }

    const fields = await getMatomoDropdownFields({
      params: {
        siteId: selectedProject.matomoId,
      },
    });
    setMatomoGoals(
      fields?.goals?.map((goal, index) => ({
        label: goal,
        value: fields.goalIds[index],
      })) ?? [],
    );
    setMatomoEvents(
      fields?.actions?.map((action) => ({ label: action, value: action })) ??
        [],
    );
    setMatomoPages(
      fields?.titles?.map((titles) => ({
        label: titles,
        value: titles,
      })) ?? [],
    );
    setMatomoDropdownsLoading(false);
  }

  useEffect(() => {
    resetAdderModal();
    setDaysUntilMetrics(null);

    if (!selectedProject || !selectedProject.value) {
      return;
    }

    // Populate metrics adding dropdowns
    if (selectedProject.platform === "matomo") {
      setSourceOptions([{ value: "matomo", label: "Matomo" }]);
      setSelectedSource({ value: "matomo", label: "Matomo" });
      populateMatomoDropdowns();
    }

    // Decide to check script installation
    let date = selectedProject.trackingStartDate;
    if (date) {
      setDaysUntilMetrics(calcDaysLeft40(date));
    } else {
      checkTrackingStatus({ pid: selectedProject.value }).then(
        (startTracking) => {
          if (startTracking) {
            setDaysUntilMetrics(40);
          } else {
            setDaysUntilMetrics(500);
          }
        },
      );
    }
  }, [selectedProject]);

  useEffect(() => {
    setMatomoMetricType(null);
  }, [matomoCategory]);

  useEffect(() => {
    if (analysisId && userInfo) {
      updateProjectApproval(analysisId, true);

      // const unsubscribe = onSnapshot(
      //   doc(db, "projects", analysisId),
      //   (docSnapshot) => {
      //     if (docSnapshot.exists()) {
      //       if (docSnapshot.data().analysisState) {
      //         setAnalysisState(docSnapshot.data().analysisState);
      //       }
      //     }
      //   },
      //   (error) => {},
      // );

      // return () => unsubscribe();
    }
  }, [analysisId, userInfo]);

  useEffect(() => {
    resetDashboard();
    setQueryLoading(false);
    setDashboardMetricsDataLoading(false);
    setDashboardLoading(false);
    setMatomoDropdownsLoading(false);
    setPagesOptionsLoading(false);
    setInsightCount(0);
  }, [invitedUserWithoutProjects]);

  useEffect(() => {
    // Skip the loading screen
    if (analysisState || analysisState === 0 || justCreatedProjectEnqueueOpen) {
      setJustCreatedProjectEnqueueOpen(false);
    }

    // if (analysisState === 100) {
    //   populateDashboardInsightNumbers().then(() => {
    //     setJustCreatedProjectEnqueueOpen(false);
    //   });
    // }
    // if (analysisState >= 0 && analysisState <= 100) {
    //   setProgressBar(Math.round(analysisState));
    // }
  }, [analysisState]);

  async function buildAnalyticsChartData(len) {
    if (currentChart >= pagesCards.length) {
      const tagName = GTMCards[currentChart - pagesCards.length]?.name;

      const promises = Array.from({ length: len }, async (_, i) => {
        const result = await fetchGTMChartData(
          selectedProject.googleAnalyticsId,
          tagName,
          i * 4 + 1,
        );

        if (result && result[tagName] !== undefined) {
          const resultChunk = result[tagName];
          return Array.from({ length: 4 }, (_, i) =>
            resultChunk[`date_range_${i}`]
              ? resultChunk[`date_range_${i}`]
              : null,
          );
        } else {
          return [null, null, null, null];
        }
      });

      const results = (await Promise.all(promises)).flat();

      const resLength = len === 2 ? 30 : len === 8 ? 30 : len === 24 ? 90 : 365;

      if (results.length === 0) {
        return new Array(resLength).fill(0);
      }

      return results[0]
        ? results.slice(0, resLength)
        : results.slice(1, resLength);
    } else {
      // If within the pages cards array
      const metricType = pagesCards[currentChart].metricType;
      const path = pagesCards[currentChart].path;
      // Start from yesterday ie 1 day ago
      const promises = Array.from({ length: len }, async (_, i) => {
        let result;

        result = await fetchAnalyticsChartData(
          selectedProject.googleAnalyticsId,
          path,
          metricType,
          i * 4 + 1,
        );

        if (result && result[path] && result[path][metricType] !== undefined) {
          const resultChunk = result[path][metricType];
          return Array.from({ length: 4 }, (_, i) =>
            resultChunk[`date_range_${i}`]
              ? resultChunk[`date_range_${i}`].data
              : null,
          );
        } else {
          return [null, null, null, null];
        }
      });

      const results = (await Promise.all(promises)).flat();

      const resLength = len === 2 ? 30 : len === 8 ? 30 : len === 24 ? 90 : 365;

      if (results.length === 0) {
        return new Array(resLength).fill(0);
      }

      return results[0]
        ? results.slice(0, resLength)
        : results.slice(1, resLength);
    }
  }

  async function buildAnalyticsMatomoChartData(len) {
    const metricType = pagesCards[currentChart].metricType;
    const path = pagesCards[currentChart].path;

    const res = await fetchAnalyticsMatomoChartData(
      selectedProject.matomoId,
      {
        commercialMetrics: [{ metricType: metricType, path: path }],
        pageMetrics: [],
      },
      len,
    );

    if (res?.[path] && res[path]?.[metricType]) {
      return res[path][metricType];
    } else {
      return new Array(len).fill(0);
    }
  }

  async function deleteFollowUpCard(card) {
    const cardsAfterRemoval = metricsCards.filter((item) => item !== card);
    setFilteredMetricsCards(cardsAfterRemoval);
    setMetricsCards(cardsAfterRemoval);
    await setClientMetricsViewGrey(
      userInfo.uid,
      selectedProject.value,
      cardsAfterRemoval,
    );
  }

  function getPastDate(i) {
    const date = new Date();
    date.setDate(date.getDate() - i - 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  async function fetchChartData() {
    setChartLoading(true);

    populateChartInsights().then((insights) => {
      if (insights) {
        setChartInsights(insights);
      }

      if (selectedProject.platform === "GA4") {
        buildAnalyticsChartData(selectedChartRange.value).then((data) => {
          const chartData = data
            .map((data, i) => {
              // +1 because one day ago
              return { date: getPastDate(i), data: data };
            })
            .reverse();
          setChartData(chartData);
          setChartLoading(false);
        });
      } else if (selectedProject.platform === "matomo") {
        buildAnalyticsMatomoChartData(selectedChartRange.value * 4).then(
          (data) => {
            const chartData = data
              .map((data, i) => {
                // +1 because one day ago
                return { date: getPastDate(i), data: data };
              })
              .reverse();
            setChartData(chartData);
            setChartLoading(false);
          },
        );
      }
    });
  }

  useEffect(() => {
    if (
      selectedProject &&
      (isValidMatomoProject(selectedProject) ||
        selectedProject.googleAnalyticsId) &&
      selectedChart?.metricType &&
      selectedProject?.value &&
      ((currentChart !== -1 && pagesCards && pagesCards[currentChart]) ||
        GTMCards[currentChart - pagesCards?.length])
    ) {
      setChartLoading(true);
      fetchChartData();
    }
  }, [currentChart, selectedProject, selectedChartRange]);

  function countPositiveDifferences(
    pagesCards,
    GTMCards,
    metricsData,
    GTMData,
  ) {
    return [...pagesCards, ...GTMCards].filter((card) => {
      if (!card.path || !card.metricType || !metricsData) {
        return false;
      }
      let diff;
      if (metricsData[card.path] && metricsData[card.path][card.metricType]) {
        diff =
          (metricsData[card.path][card.metricType]["date_range_0"]?.data ?? 0) -
          (metricsData[card.path][card.metricType]["date_range_1"]?.data ?? 0);
      } else if (GTMData[card.name]) {
        diff =
          (GTMData[card.name]["date_range_0"] ?? 0) -
          (GTMData[card.name]["date_range_1"] ?? 0);
      } else {
        return false;
      }
      return isFlippedMetric(card.metricType) ? diff < 0 : diff > 0;
    }).length;
  }

  function countNegativeDifferences(
    pagesCards,
    GTMCards,
    metricsData,
    GTMData,
  ) {
    return [...pagesCards, ...GTMCards].filter((card) => {
      if (!card.path || !card.metricType || !metricsData) {
        return false;
      }
      let diff;
      if (metricsData[card.path] && metricsData[card.path][card.metricType]) {
        diff =
          (metricsData[card.path][card.metricType]["date_range_0"]?.data ?? 0) -
          (metricsData[card.path][card.metricType]["date_range_1"]?.data ?? 0);
      } else if (GTMData[card.name]) {
        diff =
          (GTMData[card.name]["date_range_0"] ?? 0) -
          (GTMData[card.name]["date_range_1"] ?? 0);
      } else {
        return false;
      }
      return isFlippedMetric(card.metricType) ? diff > 0 : diff < 0;
    }).length;
  }

  // Expanding the sidebar changes the width of the dashboard
  // This function is needed to watch
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDashboardWidth(entry.contentRect.width);
      }
    });

    if (dashboardRef.current) {
      resizeObserver.observe(dashboardRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // If the dashboard width or the chart/card view changes
  // Re calculate if the toggle button needs to be offset upwards
  useEffect(() => {
    let isOverlapping = false;
    const ranges = [{ min: 0, max: 800 }];
    isOverlapping = ranges.some(
      (range) => dashboardWidth >= range.min && dashboardWidth <= range.max,
    );
    setIsSeeAllPagesOverlapping(isOverlapping);
  }, [dashboardWidth, chartOrCard]);

  /**
   * Handle page resize
   */
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function closeJustCreatedProjectModal() {
    setJustCreatedProjectModalOpen(false);
    window.history.replaceState({}, document.title);
  }

  const CustomMultiValue = ({ data, selectedData }) => {
    const mobileModifier = mobileMetricsFollowUp ? 1 : 0; // Change displayed selections from 2 to 1 if mobile
    const additionalSelected = selectedData.length - 2 + mobileModifier;
    return (
      selectedData[0].value === data.value && (
        <div>
          {selectedData.slice(0, 2 - mobileModifier).map((option, index) => (
            <span key={option.value}>
              {option.label}
              {index < selectedData.length - 1 ? ", " : null}
            </span>
          ))}
          {selectedData.length > 2 - mobileModifier
            ? `+ ${additionalSelected} others`
            : null}
        </div>
      )
    );
  };

  const onSelect = (option, menuType) => {
    switch (menuType) {
      case "page":
        setSelectedPage(option);
        break;
      case "source":
        setSelectedSource(option);
        break;
      case "data":
        setSelectedData(option);
        break;
      default:
        break;
    }
  };

  /**
   * Options available when either creating new metric card or big card
   */
  const [pageOptions, setPageOptions] = useState([]);

  const [sourceOptions, setSourceOptions] = useState([
    { value: "googleAnalytics", label: "Google Analytics" },
    { value: "matomo", label: "Matomo" },
  ]);

  const metricsDescriptions = getMetricsDescriptions();

  const [pagesCards, setPagesCards] = useState([]);
  const [pagesSectionWidth, setPagesSectionWidth] = useState(0);
  const [pagesStackVertical, setPagesStackVertical] = useState(false);
  const [pagesCardsLength, setPagesCardsLength] = useState(0);
  const [GTMCards, setGTMCards] = useState([]);

  const [insightCount, setInsightCount] = useState(-1);
  const [goodMetrics, setGoodMetrics] = useState(-1);
  const [badMetrics, setBadMetrics] = useState(-1);

  useEffect(() => {
    const options = [];
    [...pagesCards, ...GTMCards].map((card, i) => {
      let path = "";
      if (selectedProject.platform === "GA4") {
        path = card.path === "/" ? "Homepage" : (card.path ?? "No path");
      }
      if (selectedProject.platform === "matomo") {
        path =
          card.path === selectedProject.rootUrl
            ? "Homepage"
            : (card.subtitle ?? "No path");
      }

      const title = card.title ?? "No title";
      if (card && card.path && card.title) {
        options.push({
          label: title + " on " + path,
          value: i,
          metricType: card?.metricType ?? "GTM",
        });
      }
    });
    setChartOptions(options);
    if (options[0]) {
      setSelectedChart(options[0]);
    }
  }, [selectedProject, pagesCards, GTMCards]);

  /**
   * Adder modal for adding a new metric or big card
   * @param submitFunc - function to be called when submitting
   * @param modalTitle - title of the modal
   * @param modalIsOpen - boolean to determine if modal is open
   * @param setModalIsOpen - function to set modalIsOpen
   * @param dataOptions - options for data dropdown
   * @returns {Element}
   */
  function adderModal({
    submitFunc,
    modalTitle,
    modalIsOpen,
    setModalIsOpen,
    dataOptions,
  }) {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setOpenDropdown(null);
        }}
        contentLabel="Example Modal"
        className="custom-modal modal-shape"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgba(7, 21, 43, 0.7)",
            zIndex: 10,
          },
        }}
      >
        <button
          onClick={() => {
            setSelectedData(null);
            setModalIsOpen(false);
            setOpenDropdown(null);
          }}
          className="icon-button"
        >
          <BsXCircle alt="X button" size="30px" color="#1F1F24" />
        </button>
        {adderModalLoading ? (
          <div
            style={{
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner style={{ height: "70px", width: "70px" }} />
          </div>
        ) : (
          <div className="dropdown-section">
            <h4 className="mb-3">{modalTitle}</h4>
            <CustomDropdown
              options={pageOptions.map((page) => ({
                value: page,
                label: beautifyPath(page),
              }))}
              onChange={(option) => onSelect(option, "page")}
              value={selectedPage}
              placeholder="Page"
              unstyled
              menuOpenState={openDropdown === "page"}
              setMenuOpenState={(isOpen) =>
                setOpenDropdown(isOpen ? "page" : null)
              }
            />
            <CustomDropdown
              isMulti
              hideSelectedOptions={false}
              options={dataOptions}
              onChange={(option) => onSelect(option, "data")}
              value={selectedData}
              placeholder="Data type"
              unstyled
              closeMenuOnSelect={false}
              components={{
                MultiValue: (props) => (
                  <CustomMultiValue {...props} selectedData={selectedData} />
                ),
                Option: checkCircle,
              }}
              menuOpenState={openDropdown === "data"}
              setMenuOpenState={(isOpen) =>
                setOpenDropdown(isOpen ? "data" : null)
              }
              isClearable={false}
            />
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "31px" }}
            >
              <button
                type="button"
                className="btn-primary float-right"
                disabled={
                  !selectedPage ||
                  !selectedSource ||
                  !selectedData ||
                  selectedData.length === 0
                }
                onClick={() => submitFunc(false)}
              >
                Validate
              </button>
            </div>
          </div>
        )}
      </Modal>
    );
  }

  function isValidMatomoMetricToAdd(
    selectedSource,
    matomoCategory,
    selectedMatomoPage,
    selectedMatomoGoal,
    selectedMatomoEvent,
    matomoMetricType,
  ) {
    const validSelection1 =
      matomoCategory?.value === "page"
        ? selectedMatomoPage
        : matomoCategory?.value === "goal"
          ? selectedMatomoGoal
          : matomoCategory?.value === "event"
            ? selectedMatomoEvent
            : matomoCategory?.value === "the whole website"
              ? matomoMetricType
              : false;

    const validSelection2 = matomoMetricType && matomoMetricType.length > 0;
    return (
      selectedSource && matomoCategory && validSelection1 && validSelection2
    );
  }

  const matomoMetricAdderValid = useMemo(() => {
    return isValidMatomoMetricToAdd(
      selectedSource,
      matomoCategory,
      selectedMatomoPage,
      selectedMatomoGoal,
      selectedMatomoEvent,
      matomoMetricType,
    );
  }, [
    selectedSource,
    matomoCategory,
    selectedMatomoPage,
    selectedMatomoGoal,
    selectedMatomoEvent,
    matomoMetricType,
  ]);

  function adderModalMatomo({
    submitFunc,
    modalTitle,
    modalIsOpen,
    setModalIsOpen,
    validMatomo,
  }) {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setOpenDropdown(null);
        }}
        contentLabel="Example Modal"
        className="custom-modal modal-shape"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgba(7, 21, 43, 0.7)",
            zIndex: 10,
          },
        }}
      >
        <button
          onClick={() => {
            setSelectedData(null);
            setModalIsOpen(false);
            setOpenDropdown(null);
          }}
          className="icon-button"
        >
          <BsXCircle alt="X button" size="30px" color="#1F1F24" />
        </button>
        {!validMatomo ? (
          <div
            style={{
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Metrics require a valid analytics setup</h3>
          </div>
        ) : adderModalLoading ? (
          <div
            style={{
              height: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner style={{ height: "70px", width: "70px" }} />
          </div>
        ) : (
          <div className="dropdown-section">
            <h4 className="mb-3">{modalTitle}</h4>
            <CustomDropdown
              options={matomoCategoryOptions}
              onChange={(option) => setMatomoCategory(option)}
              value={matomoCategory}
              placeholder="Category"
              unstyled
              menuOpenState={openDropdown === "category"}
              setMenuOpenState={(isOpen) =>
                setOpenDropdown(isOpen ? "category" : null)
              }
            />

            {matomoCategory?.value === "page" && (
              <CustomDropdown
                options={matomoPages}
                onChange={(option) => setSelectedMatomoPage(option)}
                value={selectedMatomoPage}
                placeholder="Page"
                unstyled
                menuOpenState={openDropdown === "matomoPage"}
                setMenuOpenState={(isOpen) =>
                  setOpenDropdown(isOpen ? "matomoPage" : null)
                }
              />
            )}
            {matomoCategory?.value === "goal" && (
              <CustomDropdown
                options={matomoGoals}
                onChange={(option) => {
                  setSelectedMatomoGoal(option);
                }}
                value={selectedMatomoGoal}
                placeholder="Goal"
                unstyled
                menuOpenState={openDropdown === "matomoGoal"}
                setMenuOpenState={(isOpen) =>
                  setOpenDropdown(isOpen ? "matomoGoal" : null)
                }
              />
            )}
            {matomoCategory?.value === "event" && (
              <CustomDropdown
                options={matomoEvents}
                onChange={(option) => setSelectedMatomoEvent(option)}
                value={selectedMatomoEvent}
                placeholder="Event"
                unstyled
                menuOpenState={openDropdown === "matomoEvent"}
                setMenuOpenState={(isOpen) =>
                  setOpenDropdown(isOpen ? "matomoEvent" : null)
                }
              />
            )}

            <CustomDropdown
              isMulti
              hideSelectedOptions={false}
              options={
                matomoCategory?.value === "page"
                  ? getMatomoCardOptionsPage()
                  : matomoCategory?.value === "goal"
                    ? getMatomoCardOptionsEcommerce()
                    : matomoCategory?.value === "event"
                      ? getMatomoCardOptionsEvents()
                      : matomoCategory?.value === "the whole website"
                        ? getMatomoCardOptionsGeneral()
                        : []
              }
              onChange={(option) => setMatomoMetricType(option)}
              value={matomoMetricType}
              placeholder="Data type"
              unstyled
              closeMenuOnSelect={false}
              components={{
                MultiValue: (props) => (
                  <CustomMultiValue
                    {...props}
                    selectedData={matomoMetricType}
                  />
                ),
                Option: checkCircle,
              }}
              menuOpenState={openDropdown === "matomoMetricType"}
              setMenuOpenState={(isOpen) =>
                setOpenDropdown(isOpen ? "matomoMetricType" : null)
              }
              isClearable={false}
            />
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "31px" }}
            >
              <button
                type="button"
                className="btn-primary float-right"
                disabled={!matomoMetricAdderValid}
                onClick={() => submitFunc(false)}
              >
                Validate
              </button>
            </div>
          </div>
        )}
      </Modal>
    );
  }

  /**
   * Add modal but for mobile
   * @param submitFunc - function to be called when submitting
   * @param setFollowUp - function to set follow up modal
   * @param title - title of the modal
   * @param dataOptions - options for data dropdown
   * @returns {Element} - modal
   */
  function adderMobile({ submitFunc, setFollowUp, title, dataOptions }) {
    return (
      <div className="d-flex flex-column">
        <div
          style={{ marginTop: "75px", marginBottom: "100px" }}
          className="d-flex justify-content-between align-items-center"
        >
          <Button
            onClick={() => {
              setSelectedData(null);
              setMobileDoubleMenuSelector(true);
              setFollowUp(false);
            }}
            className="mobile-circle-button"
          >
            <img alt="X" src={closebtn} />
          </Button>
          <h2
            style={{
              color: "#000",
              fontWeight: "700",
              fontSize: "16px",
              marginBottom: "0",
            }}
          >
            {title}
          </h2>
          <div
            style={{
              marginLeft: "25px",
              marginRight: "25px",
              width: "32px",
            }}
          ></div>
        </div>

        <div className="dropdown-section-2">
          <CustomDropdown
            options={sourceOptions}
            onChange={(option) => onSelect(option, "source")}
            value={selectedSource}
            placeholder="Source"
            unstyled
          />
          <CustomDropdown
            options={pageOptions.map((page) => ({
              value: page,
              label: beautifyPath(page),
            }))}
            onChange={(option) => onSelect(option, "page")}
            value={selectedPage}
            placeholder="Page"
            unstyled
          />
          <CustomDropdown
            isMulti
            hideSelectedOptions={false}
            options={dataOptions}
            onChange={(option) => onSelect(option, "data")}
            value={selectedData}
            placeholder="Data type"
            unstyled
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            isClearable={false}
            components={{
              MultiValue: (props) => (
                <CustomMultiValue {...props} selectedData={selectedData} />
              ),
              Option: checkCircle,
            }}
          />
        </div>
        <div
          style={{ marginTop: "20px" }}
          className="d-flex justify-content-end"
        >
          <button
            unstyled
            className="btn-primary float-right metrics-button"
            disabled={
              !selectedPage ||
              !selectedSource ||
              !selectedData ||
              selectedData.length === 0
            }
            onClick={() => {
              submitFunc();
              setFollowUp(false);
              setMobileDoubleMenuSelector(false);
            }}
          >
            Validate
          </button>
        </div>
      </div>
    );
  }

  const checkCircle = (props) => (
    <components.Option {...props}>
      {props.isSelected ? (
        <MdCheckCircle size={24} color="var(--main-blue)" />
      ) : (
        <GiCircle size={24} color="var(--main-blue)" />
      )}{" "}
      <h5
        style={{
          marginBottom: "0",
          marginLeft: "10px",
        }}
      >
        {props.data.label}
      </h5>
    </components.Option>
  );

  const [metricsData, setMetricsData] = useState([]);
  const [metricsQuery, setMetricsQuery] = useState({});
  const [GTMQuery, setGTMQuery] = useState({});

  const chartOrCardRef = useRef(chartOrCard);

  useEffect(() => {
    chartOrCardRef.current = chartOrCard;
    setCurrentChart(0);
    if (chartOptions.length > 0) {
      setSelectedChart(chartOptions[0]);
    } else {
      setSelectedChart(null);
    }
  }, [chartOrCard]);

  async function updateMetricsCards(
    pagesCardsLength,
    metricsCards,
    GTMCardsLength,
    clientMetrics,
  ) {
    // Query the project to see if it has updated
    if (selectedProject?.value) {
      const project = (
        await getDoc(doc(db, "projects", selectedProject.value))
      ).data();

      let commercialCards = clientMetrics?.blueMetrics ?? [];

      // Updates the blue pages cards only if designer has added a new one
      if (
        pagesCardsLength !== commercialCards?.length ||
        GTMCardsLength !== project?.tagManagerCards?.length
      ) {
        const GTMCards = fillGTMCardFields(project?.tagManagerCards ?? []);

        setPagesCards([...(commercialCards ?? []), ...(GTMCards ?? [])]);
        setFilteredPagesCards([
          ...(commercialCards ?? []),
          ...(GTMCards ?? []),
        ]);
        setMetricsQuery(getMetricsQuery(metricsCards, commercialCards));
        setGTMQuery(getGTMQuery(GTMCards));
      }
    }
  }

  const selectedProjectRef = useRef();

  /**
   * Fetch the metrics cards from the database, and then format the metrics query.
   * @returns {Promise<void>}
   */
  function fetchMetricsCards() {
    let unsubscribe;

    if (selectedProject?.value && selectedProject?.platform) {
      setMetricsLoading(true);

      const projectRef = doc(db, "projects", selectedProject.value);

      unsubscribe = onSnapshot(projectRef, async (projectDoc) => {
        const project = projectDoc.data();

        const clientMetrics = await getClientMetricsView(
          userInfo.uid,
          selectedProject.value,
        );

        if (!clientMetrics?.blueMetrics?.length) {
          setMetricsData([]);
          setQueryLoading(false);
          setDashboardMetricsDataLoading(false);
        }

        const metricsCards = clientMetrics?.greyMetrics ?? [];
        const commercialCards = clientMetrics?.blueMetrics ?? [];
        let GTMcardsLocal = [];

        if (selectedProject.platform === "GA4") {
          GTMcardsLocal = fillGTMCardFields(project?.tagManagerCards ?? []);
          setGTMCards(GTMcardsLocal);
          setFilteredPagesCards([
            ...(commercialCards ?? []),
            ...(GTMcardsLocal ?? []),
          ]);
          setMetricsQuery(getMetricsQuery(metricsCards, commercialCards));

          // Matomo or GA4
          updateMetricsCards(
            commercialCards.length,
            metricsCards,
            GTMcardsLocal.length,
            clientMetrics,
          );
        } else {
          setFilteredPagesCards(commercialCards ?? []);
          setMetricsQuery(getMetricsQueryMatomo(metricsCards, commercialCards));
          setGTMCards([]);
          //updatemetricscards?
        }

        setPagesCards(commercialCards ?? []);
        setMetricsCards(metricsCards ?? []);
        setFilteredMetricsCards(metricsCards ?? []);
        // Format the metrics query

        setMetricsLoading(false);
      });
    }

    // Return cleanup function
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }

  function populateDashboardInsightNumbers() {
    return new Promise((resolve, reject) => {
      getInsightsPid(selectedProject?.value)
        .then((i) => {
          let numInsights = i.length;
          setInsightCount(numInsights);

          let totalDropoffs = 0;

          i.forEach((insight) => {
            if (!insight.impact) return;
            insight.impact.forEach((impact) => {
              totalDropoffs += Number(impact.dropoffs);
            });
          });

          setInsightDropoffs(totalDropoffs);

          const filteredInsights = i.filter((insight) =>
            insight.uxErrorType.some((type) => type.value === "metric"),
          );
          setMetricInsights(filteredInsights?.length ?? 0);
          const pageSpeedInsights = i.filter(
            (insight) => insight.ruleId === "8_1_2",
          );

          setPageSpeedInsights(pageSpeedInsights.length);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async function fetchPages() {
    const currentCall = ++callCounter.current;
    // Undefined check
    if (!selectedProject || !selectedProject?.googleAnalyticsId) {
      setPageOptions([]);
      return;
    }

    const verifyGoogleAnalyticsResponse = await verifyGoogleAnalytics({
      projectId: selectedProject.googleAnalyticsId,
    });

    // Invalid Id check
    if (
      !verifyGoogleAnalyticsResponse ||
      verifyGoogleAnalyticsResponse ===
        "No access to project, verify the account is authorized." ||
      verifyGoogleAnalyticsResponse === "Invalid Project ID"
    ) {
      setPageOptions([]);
      return;
    }

    const pagesOptionsQuery = {
      projectId: selectedProject?.googleAnalyticsId,
      rootUrl: selectedProject?.rootUrl,
      platform: selectedProject?.platform,
      params: {},
    };

    if (selectedProject?.platform === "matomo") {
      pagesOptionsQuery.params.idSite = selectedProject.matomoId;
    }

    const pagesOptionsArray = await getMetricsUrls(pagesOptionsQuery);

    if (selectedProject?.otherUrls) {
      selectedProject?.otherUrls.forEach((url) => {
        const urlObject = new URL(addHTTPS(url));
        const pathName = urlObject.pathname;
        pagesOptionsArray.unshift(pathName);
      });
    }

    const urlObject = new URL(addHTTPS(selectedProject?.rootUrl));
    const pathName = urlObject.pathname;
    pagesOptionsArray.unshift(pathName);

    const pagesOptionsSet = [...new Set(pagesOptionsArray)];

    if (currentCall === callCounter.current) {
      setPageOptions(pagesOptionsSet);
      setPagesOptionsLoading(false);
    }
  }

  function resetDashboard() {
    setChartOrCard("card");
    setCurrentChart(-1);
    setChartInsights([]);
    setSelectedChart(null);
    setChartOptions([]);
    setSign(null);

    setMetricTypeDropdownOptions([]);
    setPageDropdownOptions([]);

    setMetricsData([]);
    setInsightCount(-1);

    const isLoading =
      selectedProject?.platform === "GA4"
        ? selectedProject?.googleAnalyticsId
        : selectedProject?.matomoId;

    // isLoading is a string but used as a bool
    setQueryLoading(isLoading);
    setDashboardMetricsDataLoading(isLoading);
    setDashboardLoading(true);

    setMatomoDropdownsLoading(true);
    setPagesOptionsLoading(true);
    resetAdderModal();

    setGoodMetrics(0);
    setBadMetrics(0);
  }

  /**
   * Handle fetching the metrics cards, pages on project change
   */
  useEffect(() => {
    if (!selectedProject?.platform || !selectedProject?.value) {
      return;
    }

    resetDashboard();

    setDashboardLoading(true);
    populateDashboardInsightNumbers();
    // populate chart with new insights

    selectedProjectRef.current = selectedProject?.value;
    setPageOptions([]);
    setPagesOptionsLoading(true);
    fetchPages();

    setMetricsCards([]);
    setPagesCards([]);

    const cleanup = fetchMetricsCards();
    return () => {
      cleanup();
    };
  }, [selectedProject]);

  /**
   * Filter metrics cards based on type and metrics
   */
  useEffect(() => {
    activeTab === "Metrics follow"
      ? setFilteredMetricsCards(
          metricsCards
            .filter((card) => {
              let diff =
                metricsData[card.path]?.[card.metricType]?.["date_range_0"]
                  ?.data -
                metricsData[card.path]?.[card.metricType]?.["date_range_1"]
                  ?.data;
              if (typeFilter === "All") {
                return true;
              }
              if (typeFilter === "Positive") {
                return isFlippedMetric(card.metricType) ? diff < 0 : diff > 0;
              }
              if (typeFilter === "Negative") {
                return isFlippedMetric(card.metricType) ? diff > 0 : diff < 0;
              }
              if (typeFilter === "Neutral") {
                return diff === 0;
              }
            })
            .filter((card) => {
              if (metricsFilter === "All") return true;
              if (card.title === metricsFilter) {
                return true;
              }
            })
            .filter((card) => {
              if (pageFilter === "All") return true;
              if (card.subtitle === pageFilter) {
                return true;
              }
            }),
        )
      : setFilteredPagesCards(
          [...(pagesCards ?? []), ...GTMCards]
            .filter((card) => {
              let diff = metricsData[card.path]?.[card.metricType]
                ? metricsData[card.path][card.metricType]["date_range_0"]
                    ?.data -
                  metricsData[card.path][card.metricType]["date_range_1"]?.data
                : GTMData[card.name]?.["date_range_0"] -
                  GTMData[card.name]?.["date_range_1"];

              if (typeFilter === "All") {
                return true;
              }
              if (typeFilter === "Positive") {
                return isFlippedMetric(card.metricType) ? diff < 0 : diff > 0;
              }
              if (typeFilter === "Negative") {
                return isFlippedMetric(card.metricType) ? diff > 0 : diff < 0;
              }
              if (typeFilter === "Neutral") {
                return diff === 0 || isNaN(diff);
              }
            })
            .filter((card) => {
              if (metricsFilter === "All") return true;
              if (card.title === metricsFilter) {
                return true;
              }
            })
            .filter((card) => {
              if (pageFilter === "All") return true;
              if (card.subtitle === pageFilter) {
                return true;
              }
            }),
        );
  }, [
    typeFilter,
    metricsFilter,
    metricsCards,
    dateFilter,
    pageFilter,
    pagesCards,
  ]);

  function setFilterOptions(cards) {
    setTypeFilter("All");
    setMetricsFilter("All");
    setDateFilter("Last month");
    setPageFilter("All");

    const uniqueTitles = [...new Set(cards.map((card) => card.title))];
    const uniqueSubtitles = [...new Set(cards.map((card) => card.subtitle))];

    setMetricTypeDropdownOptions(
      [{ value: "All", label: "All" }].concat(
        uniqueTitles.map((title) => ({
          value: title,
          label: title,
        })),
      ),
    );

    setPageDropdownOptions(
      [{ value: "All", label: "All" }].concat(
        uniqueSubtitles.map((subtitle) => ({
          value: subtitle,
          label: subtitle,
        })),
      ),
    );
  }

  useEffect(() => {
    if (activeTab === "Metrics follow" && metricsCards?.length > 0) {
      setFilterOptions(metricsCards);
    }

    if (activeTab === "Performance metrics" && pagesCards?.length > 0) {
      setFilterOptions(pagesCards);
    }
  }, [pagesCards, metricsCards, activeTab, dashboardMode]);

  const fetchAnalyticsRef = useRef();

  /**
   * Fetch the analytics data from the Google Analytics API
   * @returns {Promise<void>}
   */
  fetchAnalyticsRef.current = async function fetchAnalytics() {
    if (!selectedProject) {
      return;
    }
    // Check if the query is valid.
    const query =
      selectedProject.platform === "GA4"
        ? getMetricsQuery(metricsCards, pagesCards)
        : getMetricsQueryMatomo(metricsCards, pagesCards);
    if (
      query &&
      ((query.pageMetrics && query.pageMetrics?.length > 0) ||
        (query.commercialMetrics && query.commercialMetrics?.length > 0))
    ) {
      setQueryLoading(true);

      if (
        selectedProject.platform === "GA4" &&
        query.pages &&
        query.pages.length > 0
      ) {
        fetchAnalyticsData(selectedProject.googleAnalyticsId, query).then(
          (data) => {
            setMetricsData(data);
            setQueryLoading(false);
          },
        );
      } else if (selectedProject.platform === "matomo") {
        fetchAnalyticsDataMatomo(selectedProject.matomoId, query).then(
          (data) => {
            setMetricsData(data);
            setQueryLoading(false);
          },
        );
      }
    }
  };

  // Periodically fetch analytics
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchAnalyticsRef.current();
    }, 1000 * 5);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Also fetch when the query changes.
  useEffect(() => {
    fetchAnalyticsRef.current();
  }, [metricsQuery]);

  const [GTMData, setGTMData] = useState({});
  useEffect(() => {
    const query = getGTMQuery(GTMCards);
    if (query.name.length > 0) {
      fetchGTMData(selectedProject.googleAnalyticsId, query.name).then(
        (data) => {
          if (data) {
            setGTMData(data);
          }
        },
      );
    }
  }, [GTMCards]);

  function resetAdderModal() {
    setSelectedSource({ value: "matomo", label: "Matomo" });
    setSelectedPage(null);
    setSelectedData(null);
    setSelectedMatomoPage(null);
    setSelectedMatomoGoal(null);
    setSelectedMatomoEvent(null);
    setMatomoCategory(null);
    setMatomoMetricType(null);
  }

  function getPathData() {
    let pathLabel, pathValue, dataArr;

    if (selectedProject.platform === "GA4") {
      pathLabel = selectedPage.label;
      pathValue = selectedPage.value;
      dataArr = selectedData;
    }

    if (selectedProject.platform === "matomo") {
      if (matomoCategory?.value === "page") {
        pathLabel = selectedMatomoPage.label;
        pathValue =
          selectedMatomoPage.value === "/"
            ? selectedProject.rootUrl
            : selectedMatomoPage.value;
      } else if (matomoCategory?.value === "goal") {
        pathLabel = selectedMatomoGoal.label;
        pathValue = selectedMatomoGoal.value;
      } else if (matomoCategory?.value === "event") {
        pathLabel = selectedMatomoEvent.label;
        pathValue = selectedMatomoEvent.value;
      } else if (matomoCategory?.value === "the whole website") {
        pathLabel = "the whole website";
        pathValue = "the whole website";
      }
      dataArr = matomoMetricType;
    }

    return { pathLabel, pathValue, dataArr };
  }

  /**
   * Submit the new page card.
   */
  function trackPerformanceSubmit() {
    if (!selectedProject.platform) {
      return;
    }
    let { pathLabel, pathValue, dataArr } = getPathData();
    setTrackerModalOpen(false);

    const newPagesCards = dataArr
      .map((data) => {
        if (
          !pagesCards.some(
            (card) => card.path === pathValue && card.metricType === data.value,
          )
        ) {
          setMetricsQuery((prevState) => ({
            ...prevState,
            pages: (() => {
              if (prevState.pages && prevState.pages.includes(pathValue)) {
                return prevState.pages;
              }
              return [...(prevState.pages ?? []), pathValue];
            })(),
            commercialMetrics: (() => {
              if (
                prevState.commercialMetrics &&
                prevState.commercialMetrics.includes(data.value)
              ) {
                return prevState.commercialMetrics;
              }
              return [...(prevState.commercialMetrics ?? []), data.value];
            })(),
          }));

          const subtitle =
            pathValue === selectedProject.rootUrl || pathValue === "/"
              ? "Home"
              : pathLabel;

          const card = {
            path: pathValue,
            title: data.label,
            metricType: data.value,
            subtitle: subtitle,
            text1: data.text1 ?? "You have",
            text2: data.text2 ?? data.label,
          };
          return card;
        }
      })
      .filter((value) => !!value);

    setClientMetricsViewBlue(userInfo.uid, selectedProject.value, [
      ...pagesCards,
      ...newPagesCards,
    ]);

    setPagesCards((prevState) => [...prevState, ...newPagesCards]);

    resetAdderModal();
  }

  const AiMetricsBodyWaitingForInstall = () => {
    const navigate = useNavigate();
    return (
      <>
        <div className="fc-grey fs-14 fw-500 lh-20 mt-20">
          You need to install the script to have information on metrics.
        </div>
        <button
          onClick={() => {
            navigate(
              `/send-code?type=none&siteid=${selectedProject.matomoId}&sample=10`,
            );
          }}
          className="btn-septenary fw-700 mt-16"
        >
          Install the script
        </button>
      </>
    );
  };
  const AiMetricsBodyCollectingData = ({ daysUntilMetrics }) => {
    return (
      <>
        <div className="fc-grey fs-14 fw-700 lh-20 mt-20 manrope">
          Time before available
        </div>
        <div className="fc-black fs-20 fw-700 lh-2371 manrope">
          <span className="fs-30">{daysUntilMetrics}</span> days
        </div>
        <div className="fc-grey fs-14 fw-700 lh-20 mt-20">
          Luxifer AI analyzes user behavior. Metrics anomalies soon.
        </div>
      </>
    );
  };
  const AiMetricsBodyReady = ({ dashboardLoading, metricInsights }) => {
    const navigate = useNavigate();
    return (
      <>
        {" "}
        <div
          className="fs-30 fw-700 lh-20 mb-32"
          style={{ height: "20px", marginTop: "23px" }}
        >
          {dashboardLoading ? (
            <Spinner style={{ height: "20px", width: "20px" }} />
          ) : (
            <>{metricInsights ?? 0}</>
          )}
        </div>
        <button
          onClick={() => {
            navigate("/insights/view?errorType=metric");
          }}
          className="btn-septenary fw-700"
        >
          Consult metrics
        </button>
      </>
    );
  };

  const AiMetricsCard = ({
    dashboardLoading,
    metricInsights,
    daysUntilMetrics,
  }) => {
    return (
      <div
        style={{
          width: "295px",
        }}
        className="bg-grey fc-black dashboard-card-common"
      >
        <div>
          <i className="fa-regular fa-eye fs-20 mb-12"></i>
          <div className="fs-16 fw-700 lh-20 mb-16" style={{ width: "111px" }}>
            Metrics to pay attention to
          </div>
          {daysUntilMetrics === null ? (
            <div className="mt-32">
              <Spinner style={{ height: "75px", width: "75px" }} />
            </div>
          ) : daysUntilMetrics === 500 ? (
            <AiMetricsBodyWaitingForInstall />
          ) : daysUntilMetrics >= 0 ? (
            <AiMetricsBodyCollectingData daysUntilMetrics={daysUntilMetrics} />
          ) : daysUntilMetrics < 0 ? (
            <AiMetricsBodyReady
              dashboardLoading={dashboardLoading}
              metricInsights={metricInsights}
            />
          ) : (
            <>Error</>
          )}
        </div>
      </div>
    );
  };

  const FollowCardsComponent = (props) => {
    const selectedProject = props.selectedProject;
    if (!selectedProject) {
      return (
        <div
          style={{
            height: "20vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner style={{ height: "70px", width: "70px" }} />
        </div>
      );
    }

    if (!isValidMatomoProject(selectedProject)) {
      return (
        <div style={{ marginTop: "25.46px", marginBottom: "10px" }}>
          <h2>Your project has no analytics setup</h2>
        </div>
      );
    }

    return (
      <>
        <div style={{ marginTop: "25.46px", marginBottom: "10px" }}>
          {DataAccuracyWarning()}
          <h5>Filters</h5>
        </div>
        <div style={{ display: "flex", marginBottom: "50.45px" }}>
          <div style={{ width: "127px", marginRight: "20px" }}>
            <CustomDropdown
              onChange={(option) => setMetricsFilter(option.value)}
              value={{ value: metricsFilter, label: metricsFilter }}
              placeholder={"Type"}
              unstyled
              styles={metricsFilters}
              options={metricTypeDropdownOptions}
              menuOpenState={metricsIsMenuOpen}
              setMenuOpenState={setMetricsIsMenuOpen}
            />
          </div>
          <div style={{ width: "166px", marginRight: "20px" }}>
            <CustomDropdown
              onChange={(option) => setDateFilter(option.value)}
              value={{ value: dateFilter, label: dateFilter }}
              placeholder={"Date"}
              options={dateOptions}
              unstyled
              menuOpenState={dateIsMenuOpen}
              setMenuOpenState={setDateIsMenuOpen}
            />
          </div>
          <div style={{ width: "154px", marginRight: "20px" }}>
            <CustomDropdown
              onChange={(option) => setPageFilter(option.value)}
              value={{ value: pageFilter, label: pageFilter }}
              placeholder={"Page"}
              options={pageDropdownOptions}
              unstyled
              menuOpenState={pageMIsMenuOpen}
              setMenuOpenState={setPageMIsMenuOpen}
            />
          </div>
          <div style={{ width: "174px" }}>
            <CustomDropdown
              onChange={(option) => setTypeFilter(option.value)}
              value={{ value: typeFilter, label: typeFilter }}
              placeholder={"Positive / Negative"}
              options={typeOptions}
              unstyled
              menuOpenState={typeIsMenuOpen}
              setMenuOpenState={setTypeIsMenuOpen}
            />
          </div>
        </div>
        {filteredMetricsCards.length === 0 && !metricsLoading && (
          <div
            className="fs-16 fc-black"
            style={{ marginTop: "-40.45px", marginBottom: "18px" }}
          >
            No metrics were found with above filters
          </div>
        )}
        <div className="d-flex flex-wrap">
          {metricsCards.length === 0 && metricsLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <div className="dashboard-metrics-holder">
              <div className="dashboard-metrics-card-new ">
                <button
                  onClick={() => {
                    if (selectedProject.platform === "matomo") {
                      setMetricsModalOpen(true);
                    } else {
                      toast.warning("GA4 metrics have been phased out");
                    }
                  }}
                  className="dashboard-card-new dashboard-metrics-card d-flex align-items-center justify-content-center"
                  style={{ marginRight: 0 }}
                >
                  <BsPlusLg
                    color="var(--main-blue)"
                    size="32px"
                    className="me-2"
                  />
                  <div>Add metrics</div>
                </button>
              </div>

              {filteredMetricsCards.map((card) => {
                return (
                  <div
                    className="dashboard-metrics-card"
                    key={card.title + card.subtitle + card.metricType}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>{card.title}</Tooltip>
                          }
                        >
                          <div className="title">{card.title}</div>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            metricsDescriptions[card.metricType] ? (
                              <Tooltip id={`tooltip-top`}>
                                {metricsDescriptions[card.metricType]}
                              </Tooltip>
                            ) : (
                              <></>
                            )
                          }
                        >
                          <button className="icon-button">
                            <RiInformationLine
                              alt="info icon"
                              size="16px"
                              color="#1F1F24"
                            />
                          </button>
                        </OverlayTrigger>
                      </div>
                      {!["reader"]?.includes(userInfo?.role) && (
                        <button
                          className="icon-button"
                          style={{ margin: "0px" }}
                          onClick={() => deleteFollowUpCard(card)}
                        >
                          <img
                            className="delete-metric-btn"
                            src={x}
                            alt="close button"
                          />
                        </button>
                      )}
                    </div>
                    <div className="subtitle">{card.subtitle}</div>
                    <div className="subtitle"></div>
                    {(() => {
                      return buildMetricsCardElements(
                        queryLoading,
                        metricsData,
                        card,
                      );
                    })()}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  };

  const MetricsCardsComponent = (props) => {
    const selectedProject = props.selectedProject;
    if (!selectedProject) {
      return (
        <div
          style={{
            height: "20vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner style={{ height: "70px", width: "70px" }} />
        </div>
      );
    }

    if (!isValidMatomoProject(selectedProject)) {
      return (
        <div style={{ marginTop: "25.46px", marginBottom: "10px" }}>
          <h2>Your project has no analytics setup</h2>
        </div>
      );
    }

    return (
      <>
        <div style={{ marginTop: "25.46px", marginBottom: "10px" }}>
          {DataAccuracyWarning()}
          <h5>Filters</h5>
        </div>
        <div style={{ display: "flex", marginBottom: "50.45px" }}>
          <div style={{ width: "127px", marginRight: "15px" }}>
            <CustomDropdown
              onChange={(option) => setMetricsFilter(option.value)}
              value={{ value: metricsFilter, label: metricsFilter }}
              placeholder={"Type"}
              unstyled
              styles={metricsFilters}
              options={metricTypeDropdownOptions}
              menuOpenState={metricsIsMenuOpen}
              setMenuOpenState={setMetricsIsMenuOpen}
            />
          </div>
          <div style={{ width: "166px", marginRight: "20px" }}>
            <CustomDropdown
              onChange={(option) => setDateFilter(option.value)}
              value={{ value: dateFilter, label: dateFilter }}
              placeholder={"Date"}
              options={dateOptions}
              unstyled
              menuOpenState={dateIsMenuOpen}
              setMenuOpenState={setDateIsMenuOpen}
            />
          </div>
          <div style={{ width: "154px", marginRight: "20px" }}>
            <CustomDropdown
              onChange={(option) => setPageFilter(option.value)}
              value={{ value: pageFilter, label: pageFilter }}
              placeholder={"Page"}
              options={pageDropdownOptions}
              unstyled
              menuOpenState={pageMIsMenuOpen}
              setMenuOpenState={setPageMIsMenuOpen}
            />
          </div>
          <div style={{ width: "174px" }}>
            <CustomDropdown
              onChange={(option) => setTypeFilter(option.value)}
              value={{ value: typeFilter, label: typeFilter }}
              placeholder={"Positive / Negative"}
              options={typeOptions}
              unstyled
              menuOpenState={typeIsMenuOpen}
              setMenuOpenState={setTypeIsMenuOpen}
            />
          </div>
        </div>
        {filteredPagesCards?.length === 0 && !metricsLoading && (
          <div
            className="fs-16 fc-black"
            style={{ marginTop: "-40.45px", marginBottom: "18px" }}
          >
            No metrics were found with above filters
          </div>
        )}
        <BuildPagesGrid
          metricsLoading={metricsLoading}
          filteredPagesCards={filteredPagesCards}
          queryLoading={queryLoading}
          metricsData={metricsData}
          canEdit={true}
          setTrackerModalOpen={setTrackerModalOpen}
          GTMData={GTMData}
          cards={pagesCards}
          setFilteredCards={setFilteredPagesCards}
          setCards={setPagesCards}
          pid={selectedProject?.value}
          role={userInfo.role}
        />
      </>
    );
  };

  // Submit the new metrics card.
  function addMetricsSubmit() {
    if (!selectedProject.platform) {
      return;
    }
    let { pathLabel, pathValue, dataArr } = getPathData();
    setMetricsModalOpen(false);

    const newMetricsCards = dataArr
      .map((data) => {
        if (
          !metricsCards.some(
            (card) => card.path === pathValue && card.metricType === data.value,
          )
        ) {
          setMetricsQuery((prevState) => ({
            ...prevState,
            pages: (() => {
              if (prevState.pages && prevState.pages.includes(pathValue)) {
                return prevState.pages;
              }
              return [...(prevState.pages ?? []), pathValue];
            })(),
            pageMetrics: (() => {
              if (
                prevState.pageMetrics &&
                prevState.pageMetrics.includes(data.value)
              ) {
                return prevState.pageMetrics;
              }
              return [...(prevState.pageMetrics ?? []), data.value];
            })(),
          }));
          return {
            path: pathValue,
            title: data.label,
            metricType: data.value,
            subtitle: pathLabel,
          };
        }
      })
      .filter((value) => !!value);

    const cards = [...metricsCards, ...newMetricsCards];

    setClientMetricsViewGrey(userInfo.uid, selectedProject.value, cards);

    setMetricsCards((prevState) => [...prevState, ...newMetricsCards]);

    resetAdderModal();
  }

  // Chart insights
  const populateChartInsights = () => {
    return new Promise((resolve, reject) => {
      getChartInsights(selectedProject.value, selectedChart.metricType)
        .then((insights) => {
          resolve(insights);
        })
        .catch((error) => reject(error));
    });
  };
  useEffect(() => {
    if (insightCount > -1 && !metricsLoading) {
      setDashboardLoading(false);
    }
  }, [insightCount, metricsLoading]);

  useEffect(() => {
    if (!queryLoading) {
      if (pagesCards && pagesCards.length > 0) {
        let count = countPositiveDifferences(
          pagesCards,
          GTMCards,
          metricsData,
          GTMData,
        );
        setGoodMetrics(count);

        let count2 = countNegativeDifferences(
          pagesCards,
          GTMCards,
          metricsData,
          GTMData,
        );
        setBadMetrics(count2);
      }
      setDashboardMetricsDataLoading(false);
    }
  }, [metricsData, GTMData, dashboardMetricsDataLoading]);

  useEffect(() => {
    if (
      chartData &&
      chartData.length > 0 &&
      chartData[
        chartData[chartData.length - 1]?.data
          ? chartData.length - 1
          : chartData.length - 2
      ]?.data &&
      chartData[chartData[0]?.data ? 0 : 1]?.data
    ) {
      let x = formatPercentageDiff(
        chartData[
          chartData[chartData.length - 1]?.data
            ? chartData.length - 1
            : chartData.length - 2
        ]?.data ?? 0,
        chartData[chartData[0].data ? 0 : 1]?.data ?? 0,
      );

      setFirstPart(x.firstPart);
      setSecondPart(x.secondPart);
      setSign(x.sign);

      if (firstPart === "+NaN") {
        setFirstPart("N/A");
        setSecondPart("");
      }
    } else {
      setFirstPart("0");
      setSecondPart("00");
      setSign("+");
    }
  }, [chartData]);

  if (dashboardMode == "dashboard") {
    // if (!isMobileView) {
    return (
      <div className="d-flex flex-column dashboard-width" ref={dashboardRef}>
        <img
          src={rolling}
          style={{
            top: "0",
            right: "0px",
            position: "absolute",
            height: "188.6px",
            width: "1187.75px",

            pointerEvents: "none",
          }}
        ></img>
        <div>
          <div className="fs-24 fw-500 lh-324 fc-black charlimit">
            Hello{userInfo?.firstName ? " " + userInfo.firstName : ""},
          </div>
          <div
            className="fs-18 fw-700 lh-243 fc-black"
            style={{ marginTop: "33.54px", marginBottom: "10.46px" }}
          >
            Welcome to your Dashboard
          </div>
          <div
            className="fs-16 fw-500 fc-gray lh-20"
            style={{ width: "400px", marginBottom: "46.23px" }}
          >
            Here is the current performance of your digital product. Follow the
            recommendations to improve your product.
          </div>
        </div>
        {!selectedProject || Object.keys(selectedProject).length === 0 ? (
          <h2>You have no projects.</h2>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "21px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "506px",
              }}
              className="bg-blue fc-white dashboard-card-common"
            >
              <div>
                <i className="fa-regular fa-lightbulb fs-20 mb-8"></i>
                <div className="fs-16 fw-700 lh-20 mb-16">Insights</div>

                {dashboardLoading ? (
                  <div className="mb-32">
                    <Spinner style={{ height: "44px", width: "44px" }} />
                  </div>
                ) : (
                  <>
                    <div
                      className="fs-30 fw-700 lh-20 mb-8"
                      style={{ height: "20px" }}
                    >
                      {insightCount}
                    </div>
                    <div
                      className="fw-16 fw-500 lh-20 mb-32"
                      style={{
                        maxWidth: "400px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {selectedProject?.purchaseValue != null &&
                      selectedProject?.currency != null ? (
                        `+${(
                          selectedProject.purchaseValue * insightDropoffs
                        ).toLocaleString()}${
                          selectedProject.currency
                        } potential gain.`
                      ) : (
                        <button
                          className="unstyled underline"
                          onClick={() => {
                            navigate(
                              `/manage-projects?pid=${selectedProject.value}&tab=Commercial value`,
                            );
                          }}
                        >
                          Set your client purchase value here
                        </button>
                      )}
                    </div>
                  </>
                )}

                <button
                  onClick={() => {
                    navigate("/insights/view");
                  }}
                  className="btn-senary fw-700"
                >
                  Consult insights
                </button>
              </div>
            </div>

            <AiMetricsCard
              dashboardLoading={dashboardLoading}
              metricInsights={metricInsights}
              daysUntilMetrics={daysUntilMetrics}
            />

            <div
              style={{
                width: "295px",
              }}
              className="bg-grey fc-black dashboard-card-common"
            >
              <div>
                <i className="fa-regular fa-chart-mixed fs-20 mb-16"></i>
                <div
                  className="fs-16 fw-700 lh-20 mb-16"
                  style={{ width: "111px" }}
                >
                  Performance metrics
                </div>
                <div
                  className="fs-30 fw-700 lh-20 mb-16 d-flex fc-white"
                  style={{ height: "40px" }}
                >
                  {dashboardMetricsDataLoading ? (
                    <Spinner
                      className="fc-black"
                      style={{ height: "40px", width: "40px" }}
                    />
                  ) : (
                    <>
                      <div
                        className="fvc  mr-10"
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "var(--dashboard-trend-positive)",
                          padding: "10px 18px",
                        }}
                      >
                        {goodMetrics}{" "}
                        <span className="fs-1334 ml-8"> Good</span>
                      </div>
                      <div
                        className="fvc"
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "var(--dashboard-trend-negative)",
                          padding: "10px 18px",
                        }}
                      >
                        {badMetrics} <span className="fs-1334 ml-8">Bad</span>
                      </div>
                    </>
                  )}
                </div>
                <button
                  onClick={() => {
                    setDashboardMode("seeAll");
                    setActiveTab("Performance metrics");
                  }}
                  className="btn-septenary fw-700"
                >
                  Check performance
                </button>
              </div>
            </div>

            <div
              style={{
                width: "400px",
              }}
              className="bg-grey fc-black dashboard-card-common"
            >
              <div>
                <i className="fa-regular fa-gauge-simple-max fs-20 mb-12"></i>
                <div className="fs-16 fw-700 lh-20 mb-16">Pages speed</div>
                <div
                  className={`fs-30 fw-700 lh-20 mb-24 ${
                    selectedProject?.loadTime / 1000 < 3 ? "fc-green" : "fc-red"
                  }`}
                >
                  {selectedProject?.loadTime ? (
                    <>
                      {Math.floor(selectedProject.loadTime / 1000)}
                      <span className="fs-24">
                        ,
                        {((selectedProject.loadTime / 1000) % 1)
                          .toFixed(2)
                          .slice(2)}
                        s
                      </span>
                    </>
                  ) : (
                    <span className="fc-grey fs-24">Not analyzed</span>
                  )}
                </div>
                <div className="fw-16 fw-500 lh-20 mb-16">
                  We detect{" "}
                  <span className="fw-700">{pageSpeedInsights} pages</span> with
                  bad speed score.
                </div>
                <button
                  onClick={() => toast.warn("Work in progress!")}
                  className="btn-septenary fw-700"
                >
                  Consult
                </button>
              </div>
            </div>

            {/*               <div
                style={{
                  width: "400px",
                  height: "241px",
                }}
                className="bg-grey fc-black dashboard-card-common"
              >
                <i className="fa-regular fa-chart-mixed fs-20 mb-16"></i>
                <div
                  className="fs-16 fw-700 lh-20 mb-16"
                  style={{ width: "111px" }}
                >
                  Calcul data consumption
                </div>
                <div className="fs-30 fw-700 lh-20 mb-16 d-flex fc-white">
                  <div
                    className="fvc"
                    style={{
                      borderRadius: "8px",
                      backgroundColor: "var(--dashboard-trend-negative)",
                      padding: "10px 18px",
                    }}
                  >
                    <span className="fs-1334">Bad</span>
                  </div>
                </div>
                <button
                  onClick={() => toast.warn("Not implemented yet")}
                  className="btn-septenary fw-700"
                >
                  See details
                </button>
              </div> */}
          </div>
        )}

        <Modal
          isOpen={justCreatedProjectModalOpen}
          onRequestClose={closeJustCreatedProjectModal}
          contentLabel="Example Modal"
          className="custom-modal modal-shape2"
          appElement={document.getElementById("root")}
          style={{
            overlay: {
              background: "rgb(42, 0, 255, 0.7)",
              backdropFilter: "blur(8px)",
              zIndex: 10,
            },
          }}
        >
          <div>
            <button
              className="x-button d-flex justify-content-center align-items-center"
              onClick={closeJustCreatedProjectModal}
            >
              <BsXCircle alt="X button" size="32px" color="#0F0C22" />
            </button>
          </div>
          <div className="text-center">
            <img
              src={logo}
              alt="logo"
              style={{
                width: "24.5px",
                height: "28px",
                marginTop: "32px",
                marginBottom: "34px",
              }}
            />
            <h3 className="mb-25px">
              Congrats {userInfo?.firstName ?? "User"}, you're all set!
            </h3>
          </div>
          <div
            className="row justify-content-center"
            style={{ marginTop: "-12px" }}
          >
            <button
              className="to-dashboard-btn col-5"
              onClick={closeJustCreatedProjectModal}
              style={{ width: "163.56px", height: "44px" }}
            >
              Go to my dashboard
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={paymentModalOpen}
          onRequestClose={closeJustCreatedProjectModal}
          contentLabel="Example Modal"
          className="custom-modal payment-modal-shape"
          appElement={document.getElementById("root")}
          style={{
            overlay: {
              background: "rgba(99, 69, 248, 1)",
              backdropFilter: "blur(8px)",
              zIndex: 10,
            },
          }}
        >
          <div className="payment-container">
            <img
              src={paymentStare}
              alt="Faded LUXI logo"
              style={{ position: "absolute", left: "-60px", top: "246px" }}
            ></img>
            <div className="row">
              <div
                className="col"
                style={{
                  fontWeight: "900",
                  fontSize: "32px",
                  lineHeight: "32px",
                  marginTop: "79px",
                  marginBottom: "34.7px",
                  maxWidth: "280px",
                  marginRight: "116px",
                }}
              >
                Select a plan
                <div className="row d-flex">
                  <div
                    style={{ width: "280px", marginTop: "153px" }}
                    className="d-block fw-400 fs-28 fc-grey lh-336"
                  >
                    ”If You Think Good Design is Expensive, You Should Look At
                    The Cost of Bad Design”
                  </div>
                  <img
                    src={microsoftpartner}
                    alt="Microsoft partner logo"
                    style={{
                      width: "203.25px",
                      height: "50px",
                      marginTop: "313px",
                    }}
                  ></img>
                </div>
              </div>
              <div className="col payment-col1">
                <div className="payment-content">
                  <div className="fs-24 fw-700 lh-24">
                    <span className="fs-20">€</span>2,100
                  </div>
                  <div style={{ marginTop: "-4px" }} className="fc-grey">
                    €700/month vat.excluded
                  </div>
                  <div>
                    <img
                      src={candle}
                      alt="Candle icon"
                      style={{
                        width: "21px",
                        height: "24px",
                        marginTop: "50px",
                      }}
                    ></img>
                  </div>
                  <div
                    style={{ marginTop: "12px" }}
                    className="fs-24 fw-500 lh-24"
                  >
                    Follow up
                  </div>
                  <div
                    className="fs-32 fw-700 lh-32"
                    style={{ marginTop: "-14px" }}
                  >
                    3 months
                  </div>
                  <div
                    className="fc-grey lh-192 fs-16 fw-400"
                    style={{ width: "303px", marginTop: "8px" }}
                  >
                    Follow up your project during 3 months and see the magic
                    progress.
                  </div>
                  <ul
                    className="fs-18 fw-700 lh-216 payment-ul-spacing"
                    style={{ width: "319px", marginTop: "32px" }}
                  >
                    <li>
                      Full analysis{" "}
                      <span className="fw-400">
                        (160 UX/UI criteria analysed (user engagement, content,
                        best UX/UI practices,...){" "}
                      </span>
                    </li>
                    <li>
                      UX &{" "}
                      <a target="blank" className="styled-link">
                        Design services
                      </a>
                    </li>
                    <li>
                      Generating designs and code (html/CSS) based on your
                      design system or live product.{" "}
                    </li>
                    <li>A/B testing </li>
                  </ul>
                </div>
                <button
                  className="payment-select-blue mx-auto d-block"
                  style={{ marginTop: "52px" }}
                >
                  Select
                </button>
              </div>
              <div className="col payment-col2">
                <div className="plan-tab fw-700 fc-white fs-16 lh-16">
                  Yearly plan
                </div>
                <div className="payment-content payment-content-offset">
                  <div>
                    <div className="fs-24 fw-700 lh-24">
                      <span className="fs-20">€</span>7,980
                    </div>
                    <div style={{ marginTop: "-4px" }} className="fc-grey">
                      €665/month vat.excluded
                    </div>
                  </div>
                  <div className="blue-discount" style={{ float: "right" }}>
                    -5%
                  </div>
                  <div>
                    <img
                      src={campfire}
                      alt="Campfire icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginTop: "50px",
                      }}
                    ></img>
                  </div>
                  <div
                    style={{ marginTop: "12px" }}
                    className="fs-24 fw-500 lh-24"
                  >
                    Follow up
                  </div>
                  <div
                    className="fs-32 fw-700 lh-32"
                    style={{ marginTop: "-14px" }}
                  >
                    1 year
                  </div>
                  <div
                    className="fc-grey lh-192 fs-16 fw-400"
                    style={{ width: "303px", marginTop: "8px" }}
                  >
                    Follow up your project during 12 months and see the amazing
                    progress.
                  </div>
                  <ul
                    className="fs-18 fw-700 lh-216 payment-ul-spacing"
                    style={{
                      width: "302px",
                      marginTop: "32px",
                      marginBottom: "149px",
                    }}
                  >
                    <li>
                      <span className="fw-400">
                        Same offer than “Follow up 3 months” offer but for 12
                        months with
                      </span>{" "}
                      -5% reduction :-)
                    </li>
                    <li>Free Analytic configuration</li>
                  </ul>
                </div>
                <button
                  className="payment-select-blue mx-auto d-block"
                  style={{ marginTop: "161px" }}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={justCreatedProjectEnqueueOpen}
          contentLabel="Example Modal"
          className="dashboard-analysis-modal custom-modal"
          appElement={document.getElementById("root")}
          style={{
            overlay: {
              background: "rgb(42, 0, 255, 0.7)",
              backdropFilter: "blur(8px)",
              zIndex: 10,
            },
          }}
        >
          <div>
            <div>
              <div
                style={{
                  position: "relative",
                  height: "200px",
                  width: "205px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <img
                  src={hiresluxi}
                  alt="Luxi analyzing your project"
                  style={{
                    height: "200px",
                    width: "175px",
                    position: "absolute",
                    zIndex: "50",
                    marginTop: "22vh",
                    top: 0,
                  }}
                ></img>
                <img
                  className="img-keep-ratio white-border"
                  src={analysisLogo ?? grayPfp}
                  alt="project logo"
                  style={{
                    height: "auto",
                    width: "59px",
                    position: "absolute",
                    left: "145px",
                    top: "140px",
                    zIndex: "51",
                    borderRadius: "5px",
                    marginTop: "22vh",
                    border: "2px solid white",
                  }}
                ></img>
              </div>
              <div
                style={{
                  position: "relative",
                  maxWidth: "667.72px",
                  height: "116px",
                  textAlign: "center",
                  marginTop: "57px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "33vh",
                }}
                className="fw-400 fs-24 lh-288"
              >
                {analysisState === -1 ? (
                  <>
                    Hey, we detected an issue. Sorry for that.
                    <div>We will come back to you later.</div>
                    <div>You can leave for now.</div>
                  </>
                ) : (
                  <>
                    {" "}
                    Hey {userInfo?.firstName},{" "}
                    <span className="fw-700">
                      LUXI is analyzing 100+ user experience criteria on your
                      digital product.
                    </span>{" "}
                    You will receive an email as soon as the analysis is
                    finished. But of course you can stay :-)
                  </>
                )}
              </div>
              {analysisState !== -1 && (
                <>
                  <div
                    style={{ marginLeft: "12.7vw", width: "70vw" }}
                    className=""
                  >
                    <div style={{ marginTop: "113px" }} className="">
                      <span
                        style={{
                          width: (analysisState / 100) * 70 + "vw",
                          display: "inline-block",
                          transition: "width 0.6s",
                        }}
                      ></span>
                      <img src={smallicon} style={{}}></img>
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "11px" }}
                    className="vhc analysis-progress"
                  >
                    <ProgressBar now={analysisState}></ProgressBar>
                    <span
                      className="fw-700 fs-18 fc-blue lh-216"
                      style={{ marginLeft: "16px" }}
                    >
                      {Math.round(analysisState)}%
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
    // } else {
    //   return (
    //     <div className="mobile-background d-flex flex-column">
    //       <div
    //         style={{ marginTop: "75px" }}
    //         className="d-flex justify-content-between align-items-center"
    //       >
    //         <Button className="mobile-circle-button">
    //           <img alt="-" src={hamburger} />
    //         </Button>
    //         <h2
    //           style={{
    //             color: "#FFF",
    //             fontWeight: "700",
    //             fontSize: "16px",
    //             marginBottom: "0",
    //           }}
    //         >
    //           Dashboard
    //         </h2>
    //         <Button
    //           onClick={() => setMobileDoubleMenuSelector(true)}
    //           className="mobile-circle-button"
    //         >
    //           <img alt="+" src={plusmenu} />
    //         </Button>
    //       </div>
    //       <Carousel
    //         interval={null}
    //         className="d-flex justify-content-center"
    //         style={{
    //           marginTop: "75px",
    //           width: "100vw",
    //           height: "168px",
    //         }}
    //       >
    //         {pagesCards.map((card, i) => {
    //           // Same thing as for regular dashboard.
    //           let valueData = null,
    //             value = null,
    //             deltaData = null,
    //             delta = null,
    //             deltaString = queryLoading ? "Loading..." : "No Metric Found",
    //             deltaDecimal = "";

    //           if (
    //             metricsData[card.path] &&
    //             metricsData[card.path]?.[card.metricType] &&
    //             metricsData[card.path]?.[card.metricType]["date_range_0"] &&
    //             metricsData[card.path]?.[card.metricType]["date_range_1"]
    //           ) {
    //             value =
    //               metricsData[card.path]?.[card.metricType]["date_range_0"];
    //             delta =
    //               metricsData[card.path]?.[card.metricType]["date_range_1"];

    //             let isPercent =
    //               value?.dataType === "TYPE_FLOAT" &&
    //               delta?.dataType === "TYPE_FLOAT";

    //             valueData = value?.data * (isPercent ? 100 : 1);
    //             deltaData = valueData - delta?.data * (isPercent ? 100 : 1);

    //             deltaString = deltaData.toString();
    //             if (isPercent) {
    //               if (deltaString.includes(".")) {
    //                 deltaDecimal = deltaString.split(".")[1].slice(0, 2);
    //                 deltaString = deltaString.split(".")[0];
    //               } else {
    //                 deltaDecimal = "00";
    //               }
    //             }
    //           }
    //           let sign =
    //             deltaData === 0
    //               ? "neutral"
    //               : deltaData > 0
    //                 ? "positive"
    //                 : "negative";
    //           const notFound =
    //             deltaString === "No Metric Found" ||
    //             deltaString === "Loading...";

    //           return (
    //             <Carousel.Item
    //               key={i}
    //               style={{
    //                 width: "100vw",
    //                 height: "150px",
    //               }}
    //             >
    //               <Carousel.Caption>
    //                 {!notFound ? (
    //                   <>
    //                     <h2
    //                       style={{
    //                         fontSize: "20px",
    //                         fontWeight: "500",
    //                       }}
    //                     >
    //                       {card.title}
    //                     </h2>
    //                     <h4
    //                       style={{
    //                         fontSize: "14px",
    //                         fontWeight: "500",
    //                       }}
    //                     >
    //                       {card.subtitle}
    //                     </h4>
    //                     <div
    //                       style={{
    //                         marginTop: "18px",
    //                       }}
    //                     >
    //                       <span
    //                         className={`dashboard-card-stats ${sign} px-2 py-1`}
    //                       >
    //                         {deltaString}
    //                         <span className="decimals">
    //                           {deltaDecimal}
    //                         </span>{" "}
    //                       </span>
    //                     </div>
    //                   </>
    //                 ) : (
    //                   <h2
    //                     style={{
    //                       fontSize: "20px",
    //                       fontWeight: "500",
    //                       marginBottom: "40px",
    //                     }}
    //                   >
    //                     No data for {card.title} found in {card.subtitle}
    //                   </h2>
    //                 )}
    //               </Carousel.Caption>
    //             </Carousel.Item>
    //           );
    //         })}
    //       </Carousel>

    //       <div
    //         style={{
    //           marginTop: "auto",
    //           marginBottom: "42px",
    //         }}
    //       >
    //         <h2
    //           style={{
    //             marginLeft: "25px",
    //             color: "#FFF",
    //             fontSize: "16px",
    //             fontWeight: "700",
    //             marginBottom: "23px",
    //           }}
    //         >
    //           Follow up metrics
    //         </h2>
    //         <div className="mobile-metrics-cards-holder">
    //           {metricsCards.map((card) => {
    //             return (
    //               <div
    //                 className="dashboard-metrics-card dashboard-card-mobile-margin"
    //                 key={card.title + card.subtitle + card.metricType}
    //               >
    //                 <div className="d-flex align-items-center">
    //                   <div className="title">{card.title}</div>
    //                   <button className="icon-button">
    //                     <RiInformationLine
    //                       alt="info icon"
    //                       size="16px"
    //                       color="#707070"
    //                     />
    //                   </button>
    //                 </div>
    //                 <div className="subtitle">{card.subtitle}</div>
    //                 <div className="subtitle"></div>
    //                 {(() => {
    //                   let valueData = null,
    //                     value = null,
    //                     valueString = queryLoading
    //                       ? "Loading..."
    //                       : "No Metric Found",
    //                     valueDecimal = "",
    //                     deltaData = null,
    //                     delta = null,
    //                     deltaString = "",
    //                     deltaDecimal = "";

    //                   if (
    //                     metricsData[card.path] &&
    //                     metricsData[card.path]?.[card.metricType] &&
    //                     metricsData[card.path]?.[card.metricType][
    //                       "date_range_0"
    //                     ] &&
    //                     metricsData[card.path]?.[card.metricType][
    //                       "date_range_1"
    //                     ]
    //                   ) {
    //                     value =
    //                       metricsData[card.path]?.[card.metricType][
    //                         "date_range_0"
    //                       ];
    //                     delta =
    //                       metricsData[card.path]?.[card.metricType][
    //                         "date_range_1"
    //                       ];

    //                     let isPercent =
    //                       value?.dataType === "TYPE_FLOAT" &&
    //                       delta?.dataType === "TYPE_FLOAT";

    //                     valueData = value?.data * (isPercent ? 100 : 1);
    //                     deltaData =
    //                       delta?.dataType === "TYPE_FLOAT"
    //                         ? valueData - delta?.data * 100
    //                         : (value.data / delta.data - 1) * 100;

    //                     valueString = valueData.toString();
    //                     deltaString = deltaData.toString();
    //                     if (isPercent) {
    //                       if (valueString.includes(".")) {
    //                         valueDecimal = valueString
    //                           .split(".")[1]
    //                           .slice(0, 2);
    //                         valueString = valueString.split(".")[0];
    //                       } else {
    //                         valueDecimal = "00";
    //                       }
    //                     } else {
    //                       let parts = valueString.split(".");
    //                       valueString =
    //                         parts[0] +
    //                         (parts.length === 2
    //                           ? "." + parts[1].slice(0, 2)
    //                           : "");
    //                     }
    //                     if (deltaString.includes(".")) {
    //                       deltaDecimal = deltaString.split(".")[1].slice(0, 2);
    //                       deltaString = deltaString.split(".")[0];
    //                     } else {
    //                       deltaDecimal = "00";
    //                     }
    //                   }
    //                   let sign =
    //                     deltaData === 0
    //                       ? "neutral"
    //                       : deltaData > 0
    //                         ? "positive"
    //                         : "negative";

    //                   return (
    //                     <div className="d-flex align-items-start">
    //                       <div className="percent">
    //                         {valueString}
    //                         <span className="subpercent">
    //                           {valueDecimal ? "," + valueDecimal + "%" : ""}
    //                         </span>
    //                       </div>
    //                       {delta && (
    //                         <div
    //                           className={`custom-progress dashboard-card-substats ${sign}`}
    //                         >
    //                           {isNaN(deltaData)
    //                             ? ""
    //                             : (sign === "positive" ? "+" : "") +
    //                               deltaString}
    //                           <span className="decimals2">
    //                             {isNaN(deltaData)
    //                               ? "N/A"
    //                               : "," + deltaDecimal + "%"}
    //                           </span>
    //                         </div>
    //                       )}
    //                     </div>
    //                   );
    //                 })()}
    //               </div>
    //             );
    //           })}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }
  } else if (dashboardMode == "seeAll") {
    return (
      <div>
        {selectedProject?.platform === "matomo" &&
          adderModalMatomo({
            submitFunc: addMetricsSubmit,
            modalTitle: "Add metrics",
            modalIsOpen: metricsModalOpen,
            setModalIsOpen: setMetricsModalOpen,
            validMatomo: validMatomo,
          })}
        {selectedProject?.platform === "matomo" &&
          adderModalMatomo({
            submitFunc: trackPerformanceSubmit,
            modalTitle: "Track performance of a new page",
            modalIsOpen: trackerModalOpen,
            setModalIsOpen: setTrackerModalOpen,
            validMatomo: validMatomo,
          })}
        <Link to={"/"}>
          <div
            onClick={() => setDashboardMode("dashboard")}
            style={{
              width: "158px",
              height: "24px",
              textAlign: "center",
            }}
            className="fs-14 fw-700 fc-black"
          >
            <FiArrowLeft
              color="#000"
              size="24px"
              style={{ marginRight: "10px", marginBottom: "2px" }}
            />
            Back to Dashboard
          </div>
        </Link>
        <div className="nav-line2 w-100" style={{ marginTop: "23px" }} />
        <div className="nav-section d-flex" style={{ marginTop: "32px" }}>
          {tabItems.map((item, i) => {
            return (
              <div key={i}>
                <Button className="todo-tab-item" key={i}>
                  <div
                    onClick={() => setActiveTab(item.name)}
                    className={
                      activeTab === item.name ? "active-tab fw-700" : ""
                    }
                  >
                    <div
                      className={
                        activeTab === item.name
                          ? "fc-blue fs-16"
                          : "fc-black fs-16"
                      }
                    >
                      {item.label}
                    </div>
                  </div>
                </Button>
                <div
                  className="todo-tab-bar2"
                  style={{
                    background: activeTab === item.name ? "#2A00FF" : "none",
                    width:
                      activeTab === item.name &&
                      activeTab === "Performance metrics"
                        ? "156px"
                        : activeTab === "Metrics follow"
                          ? "104px"
                          : "46px",
                  }}
                ></div>
              </div>
            );
          })}
        </div>
        <div className="nav-line2" />
        {activeTab === "Performance metrics" ? (
          <>
            <>
              <div className="row chart-card-toggle-row" style={{ top: 283 }}>
                <div
                  onClick={() => setChartOrCard("card")}
                  className={`vhc chart-card-toggle-btn-${
                    chartOrCard === "card" ? "active" : "inactive"
                  }`}
                >
                  <i className="fa-solid fa-grid-2 fa-xl"></i>
                </div>
                <div
                  onClick={() => {
                    setChartOrCard("chart");
                    setCurrentChart(0);
                  }}
                  className={`vhc chart-card-toggle-btn-${
                    chartOrCard === "chart" ? "active" : "inactive"
                  }`}
                >
                  <i className="fa-regular fa-chart-scatter fa-xl"></i>
                </div>
              </div>

              {chartOrCard === "card" ? (
                <MetricsCardsComponent
                  selectedProject={selectedProject}
                ></MetricsCardsComponent>
              ) : chartLoading || !chartData ? (
                <div
                  style={{
                    height: "20vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner style={{ height: "70px", width: "70px" }} />
                </div>
              ) : (
                <>
                  <div style={{ marginTop: "25.46px", marginBottom: "10px" }}>
                    {DataAccuracyWarning()}
                    <h5>Filters</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "-50px",
                      height: "48px",
                    }}
                  >
                    <div
                      style={{
                        paddingRight: "0px",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "400px",
                          marginRight: "24px",
                          minWidth: "400px",
                        }}
                      >
                        <CustomDropdown
                          onChange={(option) => {
                            setSelectedChart(option);
                            setCurrentChart(option.value);
                          }}
                          value={selectedChart}
                          placeholder={"Metric on page"}
                          unstyled
                          options={chartOptions}
                          menuOpenState={chartSelectionOpen}
                          setMenuOpenState={setChartSelectionOpen}
                        />
                      </div>
                      <div
                        style={{
                          width: "176px",
                          marginRight: "24px",
                          minWidth: "176px",
                        }}
                      >
                        <CustomDropdown
                          onChange={(option) => {
                            setSelectedChartRange(option);
                          }}
                          value={selectedChartRange}
                          placeholder={"Period"}
                          unstyled
                          options={chartRangeOptions}
                          menuOpenState={chartRangeOpen}
                          setMenuOpenState={setChartRangeOpen}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        className="fw-700 fs-32 lh-324"
                        style={{
                          paddingLeft: "24px",
                          borderLeft: "1px solid #D7D7D7",
                        }}
                      >
                        {sign && selectedProject.googleAnalyticsId && (
                          <div
                            className={`f-satoshi  ${
                              isFlippedMetric(selectedChart?.metricType)
                                ? sign === "+"
                                  ? "fc-red"
                                  : sign === "-"
                                    ? "fc-green"
                                    : "fc-yellow"
                                : sign === "+"
                                  ? "fc-green"
                                  : sign === "-"
                                    ? "fc-red"
                                    : "fc-yellow"
                            }`}
                          >
                            {firstPart !== "N/A" ? (
                              <>
                                {firstPart ?? 0},
                                <span className="fs-18">
                                  {secondPart ?? "00"}%
                                </span>
                              </>
                            ) : (
                              <>{firstPart ?? 0}</>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="cursor-pointer"
                    style={{
                      marginLeft: "-50px",
                      marginTop: "37px",
                      marginBottom: "300px",
                    }}
                  >
                    <ChartRangeContext.Provider
                      value={selectedChartRange.value}
                    >
                      <ChartInsightsContext.Provider value={chartInsights}>
                        <MetricsChart
                          width={1300}
                          height={600}
                          currentChart={currentChart + 1}
                          setCurrentChart={setCurrentChart}
                          numCharts={pagesCards?.length + GTMCards?.length ?? 1}
                          data={chartData}
                          chartInsights={chartInsights}
                          range={selectedChartRange.value}
                          selectedChart={selectedChart}
                        />
                      </ChartInsightsContext.Provider>
                    </ChartRangeContext.Provider>
                  </div>
                </>
              )}
            </>
          </>
        ) : activeTab === "Metrics follow" ? (
          <FollowCardsComponent
            selectedProject={selectedProject}
          ></FollowCardsComponent>
        ) : (
          <div>Invalid tab</div>
        )}
      </div>
    );
  }
}
