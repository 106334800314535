import { useEffect, useState } from "react";
import CustomInput from "../CustomInput/CustomInput";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import "./AdminManagePayment.css";
import { Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { ToastContainer, toast } from "react-toastify";

export default function AdminManagePayment(props) {
  const [user, loading] = useAuthState(auth);
  const [userFilter, setUserFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [planOptions, setPlanOptions] = useState([
    { label: "Start", value: "start" },
    { label: "Ultra", value: "ultra" },
    { label: "Corporate", value: "corporate" },
  ]);

  const handleFilterChange = (value) => {
    setUserFilter(value);
    setFilteredUsers(
      users.filter(
        (u) =>
          u?.firstName?.toLowerCase().includes(value.toLowerCase()) ||
          u?.lastName?.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  useEffect(() => {
    if (user) {
      fetchUsers();
    }
  }, [user]);

  async function fetchUsers() {
    const usersQuery = query(
      collection(db, "users"),
      where("role", "==", "client"),
    );
    const usersSnapshot = await getDocs(usersQuery);
    const users = await Promise.all(
      usersSnapshot.docs.map(async (doc) => {
        return {
          id: doc.id,
          ...doc.data(),
          planOpen: false,
          isLoading: false,
          plan: doc.data().paymentPlan
            ? {
                label: capitalizeFirstLetter(doc.data().paymentPlan),
                value: doc.data().paymentPlan,
              }
            : null,
        };
      }),
    );
    setUsers(users);
    setFilteredUsers(users);
  }

  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async function updateUserPlan(uid, plan) {
    return new Promise(async (resolve, reject) => {
      try {
        const userRef = doc(db, "users", uid);
        await updateDoc(userRef, {
          paymentPlan: plan,
        });
        toast.success(`Client is now a: ${plan}`);
        resolve();
      } catch (e) {
        toast.error("Failed to update user plans");
        reject();
      }
    });
  }

  return (
    <div>
      <ToastContainer />
      <div className="fs-24 fw-700 lh-243 mb-3 fc-black">
        Manage payment plans
      </div>
      <div className="fw-500 fs-16 lh-20 fc-grey">
        Change the payment plans of users. This will also change all users they
        invited.
      </div>
      <div style={{ height: "60px" }} className="row mb-4 mt-5 w-100 vhc">
        <div className="col-2">
          <CustomInput
            placeholder="Filter by user"
            containerClassName="observation-text-input-container half-width"
            inputClassNames="w-100"
            className={userFilter === "" ? "active" : ""}
            valueState={userFilter}
            onChange={(e) => handleFilterChange(e.target.value)}
            value={userFilter}
          />
        </div>
        <div className="col-10"> </div>
      </div>

      <hr className="mt-4 mb-5"></hr>

      <div className="user-card-container">
        {filteredUsers.map((user, i) => (
          <div className="user-card" key={i}>
            <div className="user-card-row fs-16 fc-black fw-500">
              <div className="user-pfp">
                <img src={user.pfp}></img>
              </div>
              <div className="user-info-container">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      {user?.firstName + (user?.lastName || "")}
                    </Tooltip>
                  }
                >
                  <div className="user-info fw-700">
                    {user?.firstName ?? "No Name"} {user?.lastName ?? ""}
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            <div className=" no-margins-or-padding">
              <div style={{ marginTop: "22px" }}>
                {user.isLoading ? (
                  <Spinner
                    style={{
                      height: "60px",
                      width: "60px",
                      marginLeft: "100px",
                    }}
                  />
                ) : (
                  <CustomDropdown
                    options={planOptions}
                    onChange={async (option) => {
                      const modifiedUsers = [...filteredUsers];
                      modifiedUsers[i].isLoading = true;
                      setFilteredUsers(modifiedUsers);
                      updateUserPlan(user.id, option.value).then(() => {
                        const modifiedUsers = [...filteredUsers];
                        modifiedUsers[i].plan = option;
                        modifiedUsers[i].isLoading = false;
                        setFilteredUsers(modifiedUsers);
                      });
                    }}
                    value={user.plan}
                    placeholder="Payment Plan"
                    unstyled
                    blueChevron
                    menuOpenState={user.planOpen}
                    setMenuOpenState={(state) => {
                      const modifiedUsers = [...filteredUsers];
                      modifiedUsers[i].planOpen = state;
                      setFilteredUsers(modifiedUsers);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
