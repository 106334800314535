import { useRef, useEffect, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import SankeyComponent from "./SankeyComponent.tsx";
import PopperTooltip from "../PopperTooltip/PopperTooltip";
import { useNavigate } from "react-router-dom";
import config from "../../../config.js";

export default function SankeyContainer({
  sankeyData,
  goal,
  width,
  height,
  maxLinkWidth,
  //Dropoff
  max,
  //Pagination
  setPageCoordinates,
  setTotalPages,
  setPage,
  pageWidth,
  // setPageHeights,

  selectedNodes,
  setSelectedNodes,
  selectedLinks,
  setSelectedLinks,
  rsOptions,
  setRsOptions,
  lsOptions,
  setLsOptions,

  //Sidebar
  hideFunnelFilters,

  //Hover
  pageInsightCounts,

  //Zoom
  zoom,
  setZoom,
}) {
  const marginX = 0;
  const marginY = 331;
  const ref = useRef();
  const navigate = useNavigate();

  const [hoveredId, setHoveredId] = useState(null);
  const [hoveredUrl, setHoveredUrl] = useState(null);

  const [tooltipContent, setTooltipContent] = useState(null);
  const [coordinates, setCoordinates] = useState({
    x: 0,
    y: 0,
    visible: false,
  });

  // Funnel builder
  const [funnel, setFunnel] = useState([]);
  const [showFunnelButton, setShowFunnelButton] = useState(false);

  function resetTooltip() {
    setHoveredId(null);
    setHoveredUrl(null);
    setCoordinates({ x: 0, y: 0, visible: false });
  }

  // Process funnel data
  useEffect(() => {
    if (!funnel || !funnel.length) {
      setShowFunnelButton(false);
      return;
    }
    setShowFunnelButton(true);
  }, [funnel]);

  function analyzeThisfunnel(funnel) {
    const sankeyPath = funnel
      .sort((a, b) => a.depth - b.depth)
      .map((step) => {
        if (goal.type === "click" && step.pageName === goal.name) {
          return {
            comparison: goal.type,
            condition: "==",
            value: goal.value,
            name: goal.name,
          };
        }

        return {
          comparison: step.type,
          condition: "==",
          value: step.pageName,
          name: step.pageName,
        };
      });
    navigate("/funnels/view", {
      state: {
        sankeyPath: sankeyPath,
      },
    });
  }

  useEffect(() => {
    if (!hoveredUrl) {
      return;
    }

    const insightCounts = pageInsightCounts?.[hoveredUrl];
    if (!insightCounts) {
      resetTooltip();
      return;
    }

    setCoordinates({
      visible: true,
    });
    setTooltipContent(
      <div className="sankey-popper no-select">
        <div className="mb-20 fs-14 fw-600">More information</div>
        <div className="mb-20 fs-16 fw-500">
          Luxifer has {insightCounts} insights to improve user experience. Check
          details.
        </div>
        <button
          className="btn-primary fs-14 w-100"
          onClick={() => {
            navigate("/insights/view", {
              state: {
                selectedUrl: hoveredUrl,
              },
            });
          }}
        >
          <i className="fa-regular fa-lightbulb mr-8"></i> See insights of the
          page
        </button>
      </div>,
    );
  }, [hoveredUrl]);

  //Toggle sidebar, hide tooltip
  useEffect(() => {
    resetTooltip();
  }, [hideFunnelFilters, sankeyData]);

  const { events } = useDraggable(ref, {
    isMounted: true,
    decayRate: 0,
  });

  function calculateCurrentPage(scrollLeft) {
    return Math.floor((scrollLeft + config.HEATMAP_OFFSET) / pageWidth);
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const handleScroll = () => {
      resetTooltip();
      if (ref.current && ref.current.scrollLeft) {
        const currentPageIndex = calculateCurrentPage(ref.current.scrollLeft);
        setPage(currentPageIndex);
      }
    };

    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const links = document.querySelectorAll(".visx-sankey-link");
    links.forEach((link) => {
      link.addEventListener("mouseover", () => {
        link.parentNode.appendChild(link);
      });
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("mouseover", () => {
          link.parentNode.appendChild(link);
        });
      });
    };
  }, []);

  return (
    <div>
      <div
        ref={ref}
        {...events}
        style={{
          width: width ? width : `calc(100% + ${marginX}px)`,
          height: height ? height : `calc(100vh - ${marginY}px)`,
          overflow: "scroll",
          transition: `all ${hideFunnelFilters ? 0.1 : 0.5}s`,
        }}
      >
        <PopperTooltip
          coordinates={coordinates}
          tooltip={tooltipContent}
          containerRef={ref}
          popperClassName={"sankeyPopper"}
          hideOnClick={false}
          hideOnScroll={true}
          type={"id"}
          hoveredId={hoveredId}
        />

        <div
          id="sankey"
          style={{
            transform: `scale(${zoom})`,
            transformOrigin: "top left",
          }}
        >
          <SankeyComponent
            data={sankeyData}
            goal={goal}
            maxLinkWidth={maxLinkWidth}
            //Dropoff
            max={max}
            //Pagination
            setPageCoordinates={setPageCoordinates}
            setTotalPages={setTotalPages}
            // setPageHeights={setPageHeights}

            //Selection
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            selectedLinks={selectedLinks}
            setSelectedLinks={setSelectedLinks}
            rsOptions={rsOptions}
            setRsOptions={setRsOptions}
            lsOptions={lsOptions}
            setLsOptions={setLsOptions}
            //Funnel builder
            setFunnel={setFunnel}
            //Hover
            setHoveredId={setHoveredId}
            setHoveredUrl={setHoveredUrl}
          />
        </div>
      </div>
      {showFunnelButton && (
        <div className="sankey-analyze-funnel-btn-container">
          <button
            onClick={() => analyzeThisfunnel(funnel)}
            className="btn-nonary fw-700 vhc"
          >
            <i className="fa-regular fa-magnifying-glass-chart mr-8 fs-20"></i>
            Analyze this funnel
          </button>
        </div>
      )}
    </div>
  );
}
