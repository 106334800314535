import "./InsightsV6.css";
import {
  formatNumberSpaceSeparated,
  formatPercentagePlain,
  getImpactChip,
  getProjectGoals,
  getProjectPersonas,
  sumProperty,
} from "../../helpers";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import InsightModal from "./InsightModal";
import { Spinner, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import Modal from "react-modal";
import { db } from "../../firebase";
import { components } from "react-select";
import { MdCheckCircle } from "react-icons/md";
import { GiCircle } from "react-icons/gi";
import { SelectedProjectContext, UserInfoContext } from "../../contexts";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  getUxErrorLabel,
  getUxErrorIcon,
  getFixEaseOptions,
  getSeverityOptions,
  getWCAGSeverityOptions,
  getWcagLevel,
  updateInsightsStatusBulk,
  updateInsightsFastStatusBulk,
  markInsightAsSeen,
} from "../../helpers";
import { toast } from "react-toastify";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import luxiFaded from "../../assets/luxi-blue-faded.png";
import { InsightsTable } from "./InsightsTable";
import config from "../../config";

export function InsightsV6(props) {
  const selectedProject = useContext(SelectedProjectContext);
  const userInfo = useContext(UserInfoContext);
  const [isLoading, setIsLoading] = useState(true);
  const [insights, setInsights] = useState([]);
  const [filteredInsights, setFilteredInsights] = useState([]);
  const [selectedInsight, setSelectedInsight] = useState(null);

  const [newInsightsCount, setNewInsightsCount] = useState(0);
  const [seenInsightsCount, setSeenInsightsCount] = useState(0);
  const [solvedInsightsCount, setSolvedInsightsCount] = useState(0);
  const [deletedInsightsCount, setDeletedInsightsCount] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState(0);
  const [largestInsightDigits, setLargestInsightDigits] = useState(0);
  const [idsToMarkAsSeen, setIdsToMarkAsSeen] = useState([]);

  const [selectedPage, setSelectedPage] = useState([]);
  const [selectedEaseToFix, setSelectedEaseToFix] = useState([]);
  const [selectedSeverity, setSelectedSeverity] = useState([]);
  const [selectedWCAGSeverity, setSelectedWCAGSeverity] = useState([]);
  const [selectedObservationTypes, setSelectedObservationTypes] = useState([]);
  const [isHighImpactON, setIsHighImpactON] = useState(false);

  const [pageOptions, setPageOptions] = useState([]);
  const [easeToFixOptions, setEaseToFixOptions] = useState([]);
  const [severityOptions, setSeverityOptions] = useState([]);
  const [wcagSeverityOptions, setWcagSeverityOptions] = useState([,]);
  const observationTypesOptions = [
    { value: "navigationAndStructure", label: "Navigation and Structure" },
    { value: "designAndTypography", label: "Design and Typography" },
    {
      value: "accessibilityAndConformity",
      label: "Accessibility and Conformity",
    },
    { value: "contentAndEngagement", label: "Content and Engagement" },
    { value: "mobileAndResponsive", label: "Mobile and Responsive" },
    { value: "metric", label: "Metric" },
    { value: "persona", label: "Persona" },
  ];

  const [openDropdown, setOpenDropdown] = useState(null);

  // const defaultFilter = { value: "All", label: "All" };

  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(
    location.state?.selectedInsightAdmin ? "all" : "new",
  );
  const statuses = [
    { status: "all", count: insights.length },
    { status: "new", count: newInsightsCount },
    { status: "seen", count: seenInsightsCount },
    { status: "solved", count: solvedInsightsCount },
    { status: "deleted", count: deletedInsightsCount },
  ];

  const [sortedBy, setSortedBy] = useState("num");
  const [sortOrder, setSortOrder] = useState("asc");

  const [impactResolver, setImpactResolver] = useState(null);

  const openModal = (insight) => {
    setSelectedInsight(insight);
    setIsModalOpen(true);

    window.history.replaceState(null, "", window.location.pathname);
    setId(null);
    setPid(null);

    if (!userHasSeen(insight)) {
      markInsightAsSeen(insight.id, userInfo.uid);
    }
  };

  const closeModal = () => {
    if (idsToMarkAsSeen.length > 0) {
      idsToMarkAsSeen.forEach((id) => {
        markInsightAsSeen(id, userInfo.uid, setInsights);
      });
    }
    setIsModalOpen(false);
    setSelectedInsight(null);
  };

  // const openFiltersModal = () => {
  //   setIsFiltersModalOpen(true);
  // };

  // const closeFiltersModal = () => {
  //   setIsFiltersModalOpen(false);
  // };

  const populateInsightsAsync = async (pid) => {
    await populateInsights(pid);
  };

  const handleDeleteInsight = (insightId) => {
    setInsights((prevInsights) =>
      prevInsights.filter(
        (insight) => insight.status !== "deleted" && insight.id !== insightId,
      ),
    );
  };
  async function populateImpactMaps(pid) {
    const [goals, personas] = await Promise.all([
      getProjectGoals(pid),
      getProjectPersonas(pid),
    ]);

    const createMap = (items, key, value) =>
      new Map(items.map((item) => [item[key], item[value]]));

    setImpactResolver({
      goals: createMap(goals, "id", "label"),
      personas: createMap(personas, "id", "name"),
      pfps: createMap(personas, "id", "pfp"),
    });
  }

  function userHasSeen(insight) {
    return insight.seenBy && insight.seenBy.includes(userInfo.uid);
  }

  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(location.search),
  );

  const [id, setId] = useState(searchParams.get("id"));
  const [pid, setPid] = useState(searchParams.get("pid"));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSearchParams(params);
    if (params.get("id")) {
      setSelectedStatus("all");
    }
    setId(params.get("id"));
    setPid(params.get("pid"));
  }, [location]);

  useEffect(() => {
    if (
      location.state?.filterByMetric ||
      searchParams.get("errorType") === "metric"
    ) {
      setSelectedObservationTypes([{ value: "metric", label: "Metric" }]);
      setSelectedStatus("all");
    }
  }, []);

  useEffect(() => {
    closeModal();
    if (isLoading || !selectedProject || selectedProject.value !== pid) return;

    if (!isLoading && insights.length > 0) {
      const selectedInsight = location.state?.selectedInsightAdmin
        ? insights.find(
            (insight) => insight.id === location.state?.selectedInsightAdmin,
          )
        : id && pid
          ? insights.find((insight) => insight.id === id)
          : null;

      if (selectedInsight) {
        openModal(selectedInsight);
      }
    }
  }, [isLoading, location.state, insights, selectedProject]);

  useEffect(() => {
    if (pid && props.projects) {
      const project = props.projects.find((project) => project.value === pid);
      props.setSelectedProject(project ?? props.projects[0] ?? {});
      if (!project && userInfo.role !== "admin") {
        toast.warn("The requested project was not found");
      }
    }
  }, [props.projects, pid]);

  useEffect(() => {
    setCheckedStates({});

    if (selectedProject?.value) {
      populateInsightsAsync(selectedProject.value);
      populateImpactMaps(selectedProject.value);
    }
  }, [selectedProject]);

  useEffect(() => {
    setCheckedStates({});

    if (pid && id) {
      populateInsightsAsync(pid);
      populateImpactMaps(pid);
    }
  }, [pid, id]);

  useEffect(() => {
    if (filteredInsights.length === 0 && isModalOpen) {
      setIsModalOpen(false);
    }
  }, [filteredInsights.length, isModalOpen]);

  useEffect(() => {
    if (selectedInsight && selectedInsight.id) {
      const updatedInsight = insights.find(
        (insight) =>
          insight.id === selectedInsight.id && insight.status !== "deleted",
      );
      if (updatedInsight && updatedInsight !== selectedInsight) {
        setSelectedInsight(updatedInsight);
      }
    }
  }, [insights]);

  useEffect(() => {
    if (insights.length > 0) {
      const newCount = insights.filter(
        (insight) =>
          !userHasSeen(insight) &&
          insight.status !== "complete" &&
          insight.status !== "deleted",
      ).length;

      const seenCount = insights.filter(
        (insight) =>
          userHasSeen(insight) &&
          insight.status !== "complete" &&
          insight.status !== "deleted",
      ).length;

      const solvedCount = insights.filter(
        (insight) => insight.status === "complete",
      ).length;
      const deletedCount = insights.filter(
        (insight) => insight.status === "deleted",
      ).length;

      setNewInsightsCount(newCount);
      setSeenInsightsCount(seenCount);
      setSolvedInsightsCount(solvedCount);
      setDeletedInsightsCount(deletedCount);
    } else {
      setNewInsightsCount(0);
      setSeenInsightsCount(0);
      setSolvedInsightsCount(0);
      setDeletedInsightsCount(0);
    }
  }, [insights]);

  function resetFilters() {
    setSelectedPage([]);
    setSelectedEaseToFix([]);
    setSelectedSeverity([]);
    setSelectedWCAGSeverity("");
    setIsHighImpactON(false);
    setSelectedObservationTypes([]);
  }

  useEffect(() => {
    const filters = [
      selectedObservationTypes.length > 0 ? 1 : 0,
      selectedPage.length > 0 ? 1 : 0,
      selectedEaseToFix.length > 0 ? 1 : 0,
      selectedSeverity.length > 0 ? 1 : 0,
      selectedWCAGSeverity.length > 0 ? 1 : 0,
      isHighImpactON ? 1 : 0,
    ];

    const activeFilterCounter = filters.reduce((acc, curr) => acc + curr, 0);
    setActiveFilters(activeFilterCounter);
  }, [
    selectedPage,
    selectedEaseToFix,
    selectedSeverity,
    selectedWCAGSeverity,
    isHighImpactON,
    selectedObservationTypes,
    selectedStatus,
  ]);

  const checkCircle = (props) => (
    <components.Option {...props}>
      <div className="d-flex align-items-start">
        <div>
          {props.isSelected ? (
            <MdCheckCircle size={24} color="var(--main-blue)" />
          ) : (
            <GiCircle size={24} color="var(--main-blue)" />
          )}{" "}
        </div>

        <div>
          <h5
            style={{
              marginTop: "3px",
              marginBottom: "0",
              marginLeft: "10px",
            }}
          >
            {props.data.label}
          </h5>
        </div>
      </div>
    </components.Option>
  );

  const observationTypesOption = (props) => {
    const uxIcon = getUxErrorIcon(props.data.value);

    return (
      <components.Option {...props}>
        <div className="d-flex align-items-start">
          <div
            className={`observation-icon_dropdown fs-20 ${
              props.isSelected ? "selected" : ""
            }`}
          >
            {uxIcon}
          </div>
          <div
            style={{
              marginTop: "3px",
              marginBottom: "0",
              marginLeft: "10px",
            }}
            className={`observation_type_label ${
              props.isSelected ? "selected" : ""
            }`}
          >
            <h5>{props.data.label}</h5>
          </div>
        </div>
      </components.Option>
    );
  };

  const GainAndUsers = (props) => {
    const insight = props.insight;

    if (
      !insight.impact ||
      insight.impact.length === 0 ||
      !impactResolver?.goals
    ) {
      return;
    }

    const uniqueGoals = new Set(insight.impact.map((item) => item.goalId)).size;

    if (uniqueGoals === 0) {
      return;
    }

    let goalsImpactedText = "";
    const sumImpact = sumProperty(insight.impact, "dropoffs");
    if (sumImpact === 0) {
      return;
    }

    if (uniqueGoals === 1) {
      const goalId = insight.impact[0].goalId;
      const goalName = impactResolver.goals.get(goalId) ?? "Unknown goal";
      const value = formatPercentagePlain(
        sumImpact / insight.impactGlobalSessions,
      );

      goalsImpactedText = `+${value} on ${goalName}`;
    } else {
      goalsImpactedText = `${uniqueGoals} goals impacted`;
    }

    const users = formatNumberSpaceSeparated(insight?.impactedSessions ?? 0);

    return (
      <div style={{ marginLeft: "10px" }}>
        <div>{goalsImpactedText}</div>
        <div style={{ color: "grey", paddingBottom: "23px" }}>
          {" "}
          {users ? `${users} user${users > 1 ? "s" : ""}` : ""}
        </div>
      </div>
    );
  };

  const ImpactChip = (props) => {
    const insight = props.insight;

    if (insight.source === "IBM") {
      return getImpactChip(
        0,
        0,
        true,
        "fvc fc-white impact-chip-default ml-10",
      );
    }

    if (insight.impact && insight.impact.length > 0) {
      const totalDropoffs = insight.impact.reduce(
        (acc, curr) => acc + Number(curr.dropoffs),
        0,
      );

      if (
        totalDropoffs === 0 ||
        Number(insight.impactGlobalGoalsAchieved) === 0
      )
        return;

      const averageDropoff = totalDropoffs / insight.impact.length;
      return getImpactChip(
        averageDropoff,
        insight.impactGlobalGoalsAchieved,
        false,
        "fvc fc-white impact-chip-default ml-10",
      );
    }

    return;
  };

  const dropdowns = [
    {
      name: "Observation Types",
      options: observationTypesOptions,
      value: selectedObservationTypes,
      onChange: (option) => setSelectedObservationTypes(option),
    },
    {
      name: "Pages",
      options: pageOptions,
      value: selectedPage,
      onChange: (option) => setSelectedPage(option),
    },
    {
      name: "Ease to Fix",
      options: easeToFixOptions,
      value: selectedEaseToFix,
      onChange: (option) => setSelectedEaseToFix(option),
    },
    {
      name: "Severity",
      options: severityOptions,
      value: selectedSeverity,
      onChange: (option) => setSelectedSeverity(option),
    },
    {
      name: "WCAG",
      options: wcagSeverityOptions,
      value: selectedWCAGSeverity,
      onChange: (option) => setSelectedWCAGSeverity(option),
    },
  ];

  const populateInsights = async (pid) => {
    if (!selectedProject?.value && !pid) return;
    const projectId = pid || selectedProject.value;
    setIsLoading(true);

    const querySnapshot = await getDocs(
      query(collection(db, "insights"), where("projectId", "==", projectId)),
    );

    const insightsArrays = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    try {
      let insightsListTemp = insightsArrays;
      // Order by num, which will similar to creation date
      insightsListTemp.sort((a, b) => a.num - b.num);

      const pageSet = new Set();

      insightsListTemp.forEach((item) => {
        if (item.pageName) {
          pageSet.add(item.pageName);
        } else {
          pageSet.add("N/A");
        }
      });

      const pageList = Array.from(pageSet)
        .sort()
        .map((value) => ({ value, label: value }));

      setPageOptions([...pageList]);
      setEaseToFixOptions([...getFixEaseOptions()]);
      setSeverityOptions([...getSeverityOptions()]);
      setWcagSeverityOptions([...getWCAGSeverityOptions()]);
      setInsights(insightsListTemp);

      setFilteredInsights(insightsListTemp);

      if (location?.state?.selectedUrl) {
        setSelectedPage([
          {
            value: location?.state?.selectedUrl,
            label: location?.state?.selectedUrl,
          },
        ]);
      }

      if (id && insightsListTemp) {
        const selectedInsight = insightsListTemp.find(
          (insight) => insight.id === id,
        );
        const insightToSelect = selectedInsight ?? insightsListTemp[0];
        if (!selectedInsight) {
          toast.warn("The requested insight was not found.");
        }
        setSelectedInsight(insightToSelect);
      } else if (location?.state?.selectedInsightAdmin) {
        const selectedInsight = insightsListTemp.find(
          (insight) => insight.id === location?.state?.selectedInsightAdmin,
        );
        setSelectedInsight(selectedInsight ?? {});
      } else {
        setSelectedInsight(insightsListTemp[0] ?? {});
      }
    } catch (error) {
      console.error("Error populating insights:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [checkedStates, setCheckedStates] = useState({});
  const selectedRowCount = Object.keys(checkedStates).length;

  const handleCheckboxClick = (rowId) => {
    setCheckedStates((prevState) => {
      const newState = { ...prevState };
      if (newState[rowId]) {
        delete newState[rowId];
      } else {
        newState[rowId] = true;
      }
      return newState;
    });
  };

  useEffect(() => {
    if (filteredInsights && filteredInsights.length > 0) {
      const maxNum = Math.max(
        ...filteredInsights.map((insight) => insight.num),
      );
      const numDigits = maxNum.toString().length;
      setLargestInsightDigits(numDigits + "em");
    }
  }, [filteredInsights]);

  const handleBulkDeleteInsights = async () => {
    const selectedInsightIds = Object.keys(checkedStates);

    try {
      setIsLoading(true);
      updateInsightsStatusBulk(selectedInsightIds, "deleted");

      const projectId = selectedProject?.value;

      if (projectId) {
        updateInsightsFastStatusBulk(projectId, selectedInsightIds, "deleted");
        setIsLoading(false);
      }

      setInsights((prevInsights) =>
        prevInsights.map((insight) =>
          selectedInsightIds.includes(insight.id)
            ? { ...insight, status: "deleted" }
            : insight,
        ),
      );

      setCheckedStates({});
      toast.success("Selected insights have been deleted.");
    } catch (error) {
      toast.error("Failed to delete selected insights.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    filterInsights();
  }, [
    selectedPage,
    selectedEaseToFix,
    selectedSeverity,
    selectedWCAGSeverity,
    isHighImpactON,
    insights,
    selectedObservationTypes,
    selectedStatus,
  ]);

  const filterInsights = () => {
    let filtered = insights;

    if (selectedPage.length > 0) {
      const selectedValues = selectedPage.map((option) => option.value);
      filtered = filtered.filter((insight) => {
        const pageName = insight.pageName || "N/A";
        return selectedValues.includes(pageName);
      });
    }

    if (selectedEaseToFix.length > 0) {
      const selectedValues = selectedEaseToFix.map((option) => option.value);
      filtered = filtered.filter((insight) => {
        const fixEase = insight.fixEase || "N/A";
        return selectedValues.includes(fixEase);
      });
    }

    if (selectedSeverity.length > 0) {
      const selectedValues = selectedSeverity.map((option) => option.value);
      filtered = filtered.filter((insight) => {
        const severity = insight.severity || "N/A";
        return selectedValues.includes(severity);
      });
    }

    if (selectedWCAGSeverity.length > 0) {
      const selectedValues = selectedWCAGSeverity.map((option) => option.value);
      filtered = filtered.filter((insight) => {
        const wcagLevel = getWcagLevel(insight.ruleId) || "N/A";
        return selectedValues.includes(wcagLevel);
      });
    }

    if (selectedObservationTypes.length > 0) {
      const selectedValues = selectedObservationTypes.map(
        (option) => option.value,
      );
      filtered = filtered.filter((insight) => {
        const uxErrorTypes = insight.uxErrorType?.map(
          (error) => error.value,
        ) || ["N/A"];
        return uxErrorTypes.some((type) => selectedValues.includes(type));
      });
    }

    if (isHighImpactON) {
      filtered = filtered.filter((insight) => insight.highImpact === true);
    }

    switch (selectedStatus) {
      case "new":
        filtered = filtered.filter(
          (insight) =>
            !userHasSeen(insight) &&
            insight.status !== "complete" &&
            insight.status !== "deleted",
        );
        break;
      case "seen":
        filtered = filtered.filter(
          (insight) =>
            userHasSeen(insight) &&
            insight.status !== "deleted" &&
            insight.status !== "complete",
        );
        break;
      case "solved":
        filtered = filtered.filter((insight) => insight.status === "complete");
        break;
      case "deleted":
        filtered = filtered.filter((insight) => insight.status === "deleted");
        break;
      default:
        break;
    }

    setFilteredInsights(filtered);
  };

  function sortInsightsByField(field, order) {
    const sortedInsights = [...filteredInsights];

    if (field === "uxErrorType") {
      sortedInsights.sort((a, b) => {
        const lengthA = a[field] ? a[field].length : 0;
        const lengthB = b[field] ? b[field].length : 0;

        if (lengthA < lengthB) {
          return order === "asc" ? -1 : 1;
        }
        if (lengthA > lengthB) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    } else if (field === "seenBy") {
      sortedInsights.sort((a, b) => {
        const aSeen = userHasSeen(a);
        const bSeen = userHasSeen(b);

        if (aSeen && !bSeen) {
          return order === "asc" ? 1 : -1;
        }
        if (!aSeen && bSeen) {
          return order === "asc" ? -1 : 1;
        }
        return 0;
      });
    } else {
      sortedInsights.sort((a, b) => {
        if (a[field] < b[field]) {
          return order === "asc" ? -1 : 1;
        }
        if (a[field] > b[field]) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredInsights(sortedInsights);
    if (order === "asc") {
      setSortOrder("desc");
    } else {
      setSortOrder("asc");
    }
  }

  const insightWidths = [
    "64px",
    "dynamic",
    "2000px",
    "96px",
    "166px",
    "184px",
    "208px",
    "1px",
  ];
  const insighMinWidths = [
    "64px",
    "dynamic",
    "200px",
    "96px",
    "166px",
    "184px",
    "208px",
    "1px",
  ];
  const insightHeaders = [
    {
      title: "",
      icon: <i></i>,
      field: "checkbox",
      render: (row) => (
        <div
          className="v6-insights-checkbox"
          onClick={() => handleCheckboxClick(row.id)}
          style={{ cursor: "pointer" }}
        >
          <i
            className={
              checkedStates[row.id]
                ? "fa-solid fa-square-check checked_checkbox"
                : "fa-light fa-square"
            }
          ></i>
        </div>
      ),
    },
    {
      title: "",
      icon: <i className="fa-thin fa-hashtag fs-16 ml--10"></i>,
      field: "number",
      render: (row) => <div className="row-number">{row.num}</div>,
      sort: () => sortInsightsByField("num", sortOrder),
    },
    {
      title: "Insight",
      className: "fs-16 fc-grey ml--10",
      icon: <></>,
      field: "title",
      render: (row) => <div className="row-title">{row.title}</div>,
      sort: () => sortInsightsByField("title", sortOrder),
    },
    {
      title: "New",
      icon: (
        <i
          className="fa-thin fa-stars icon"
          style={{ marginBottom: "0px" }}
        ></i>
      ),
      paddingTop: "20px",
      field: "new",
      render: (row) =>
        !userHasSeen(row) ? (
          <div className="new_button">
            <button className="btn-new fw-700">New</button>
          </div>
        ) : null,
      sort: () => sortInsightsByField("seenBy", sortOrder),
    },
    {
      title: "Impact",
      paddingTop: "20px",
      icon: <i className="fa-thin fa-timer icon"></i>,
      field: "severity",
      render: (row) => <ImpactChip insight={row} />,
    },
    {
      title: "Gain & Users",
      paddingTop: "20px",
      icon: <i className="fa-thin fa-sack-dollar icon"></i>,
      field: "gainAndUsers",
      render: (row) => <GainAndUsers insight={row} />,
    },
    {
      title: "Observation type",
      icon: <i className="fa-thin fa-timer icon"></i>,
      field: "uxErrorType",
      sort: () => sortInsightsByField("uxErrorType", sortOrder),
      render: (row) => (
        <div className="error-icons" style={{ marginLeft: "10px" }}>
          {row.uxErrorType?.map((error, index) => (
            <OverlayTrigger
              key={`top-${index}`}
              placement="top"
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {getUxErrorLabel(error.value)}
                </Tooltip>
              }
            >
              <div
                className="vhc fvc"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {getUxErrorIcon(error.value)}
              </div>
            </OverlayTrigger>
          ))}
        </div>
      ),
    },
    {
      title: "",
      field: "explore",
      render: () => (
        <button className="btn-septenary btn-v6 fw-700 explore-button hide-until-hover">
          Explore
        </button>
      ),
    },
  ];

  /*
      const FilterModal = () => {
        const [isPageMenuOpen, setIsPageMenuOpen] = useState(false);
        const [isEaseToFixMenuOpen, setIsEaseToFixMenuOpen] = useState(false);
        const [isSeverityMenuOpen, setIsSeverityMenuOpen] = useState(false);
        const [isWCAGSeverityMenuOpen, setIsWCAGSeverityMenuOpen] = useState(false);

        const observationTypes = [
          "navigationAndStructure",
          "designAndTypography",
          "accessibilityAndConformity",
          "contentAndEngagement",
          "mobileAndResponsive",
          "metric",
          "persona",
        ];

        const [dropdowns, setDropdowns] = useState([
          {
            name: "Pages",
            options: pageOptions || [defaultFilter],
            value: selectedPage,
            isMenuOpen: isPageMenuOpen,
            menuOpenHandler: () => setIsPageMenuOpen(true),
            menuCloseHandler: () => setIsPageMenuOpen(false),
          },
          {
            name: "Ease to Fix",
            options: easeToFixOptions || [defaultFilter],
            value: selectedEaseToFix,
            isMenuOpen: isEaseToFixMenuOpen,
            menuOpenHandler: () => setIsEaseToFixMenuOpen(true),
            menuCloseHandler: () => setIsEaseToFixMenuOpen(false),
          },
          {
            name: "Severity",
            options: severityOptions || [defaultFilter],
            value: selectedSeverity,
            isMenuOpen: isSeverityMenuOpen,
            menuOpenHandler: () => setIsSeverityMenuOpen(true),
            menuCloseHandler: () => setIsSeverityMenuOpen(false),
          },
          {
            name: "WCAG Severity",
            options: wcagSeverityOptions || [defaultFilter],
            value: selectedWCAGSeverity,
            isMenuOpen: isWCAGSeverityMenuOpen,
            menuOpenHandler: () => setIsWCAGSeverityMenuOpen(true),
            menuCloseHandler: () => setIsWCAGSeverityMenuOpen(false),
          },
        ]);

        const showWCAGSeverity = selectedObservationTypes.includes(
          "accessibilityAndConformity",
        );

        useEffect(() => {
          setDropdowns([
            {
              ...dropdowns[0],
              options: pageOptions || [defaultFilter],
              value: selectedPage,
            },
            {
              ...dropdowns[1],
              options: easeToFixOptions || [defaultFilter],
              value: selectedEaseToFix,
            },
            {
              ...dropdowns[2],
              options: severityOptions || [defaultFilter],
              value: selectedSeverity,
            },
            ...(showWCAGSeverity
              ? [
                  {
                    ...dropdowns[3],
                    options: wcagSeverityOptions || [defaultFilter],
                    value: selectedWCAGSeverity,
                  },
                ]
              : []),
          ]);
        }, [
          pageOptions,
          easeToFixOptions,
          severityOptions,
          wcagSeverityOptions,
          selectedPage,
          selectedEaseToFix,
          selectedSeverity,
          selectedWCAGSeverity,
          showWCAGSeverity,
        ]);

        const onSelect = (option, menuType) => {
          switch (menuType) {
            case "Pages":
              setSelectedPage(option);
              modifyDropdowns(0, "value", option, setDropdowns);
              break;
            case "Ease to Fix":
              setSelectedEaseToFix(option);
              modifyDropdowns(1, "value", option, setDropdowns);
              break;
            case "Severity":
              setSelectedSeverity(option);
              modifyDropdowns(2, "value", option, setDropdowns);
              break;
            case "WCAG Severity":
              setSelectedWCAGSeverity(option);
              modifyDropdowns(3, "value", option, setDropdowns);
              break;
            default:
              break;
          }
        };

        const filterNames = ["Pages", "Ease to Fix", "Severity", "WCAG Severity"];

        const onToggleMenu = (state, menuType) => {
          const index = filterNames.indexOf(menuType);
          if (index !== -1) {
            modifyDropdowns(index, "isMenuOpen", state, setDropdowns);
          }
        };

        useEffect(() => {
          if (!showWCAGSeverity && selectedWCAGSeverity.value !== "All") {
            setSelectedWCAGSeverity(defaultFilter);
          }
        }, [showWCAGSeverity, selectedWCAGSeverity]);

        const handleHighImpactToggle = () => {
          toast.warning("Working in progress");
          setIsHighImpactON(!isHighImpactON);
        };

        const toggleObservationType = (type) => {
          setSelectedObservationTypes((prevSelected) => {
            if (prevSelected.includes(type)) {
              return prevSelected.filter((t) => t !== type);
            } else {
              return [...prevSelected, type];
            }
          });
        };

        return (
          <Modal
            isOpen={isFiltersModalOpen}
            onRequestClose={closeFiltersModal}
            contentLabel="Filters"
            className="filters-modal custom-modal"
            overlayClassName="filters-modal-overlay"
            appElement={document.getElementById("root")}
          >
            <div className="filters-modal-container">
              <div className="filters-modal-header">
                <div className="modal-header__title fs-20 fw-700">Filters</div>
                <i
                  className="fa-solid fa-xmark close-icon"
                  onClick={closeFiltersModal}
                ></i>

                <div className="observation_types fs-18 fw-700">
                  Observation types
                </div>
                <div
                  className="observation_types__selected fs-14 fw-700 fc-grey"
                  style={{ marginBottom: "15px" }}
                >
                  {selectedObservationTypes.length} selected
                </div>
                <div className="observation_types_icons">
                  {observationTypes.map((type) => (
                    <div
                      key={type}
                      className={`observation-icon ${
                        selectedObservationTypes.includes(type) ? "selected" : ""
                      }`}
                      onClick={() => toggleObservationType(type)}
                      title={getUxErrorLabel(type)}
                    >
                      {getUxErrorIcon(type)}
                    </div>
                  ))}
                </div>

                <div className="filter_section">
                  <div className="flex">
                    {dropdowns.map((dropdown, i) =>
                      dropdown.name !== "WCAG Severity" ||
                      (dropdown.name === "WCAG Severity" && showWCAGSeverity) ? (
                        <div key={i} className="insight-dropdown">
                          <CustomDropdown
                            options={dropdown.options}
                            onChange={(option) => onSelect(option, dropdown.name)}
                            placeholder={dropdown.name}
                            value={dropdown.value}
                            unstyled
                            setMenuOpenState={(res) =>
                              onToggleMenu(res, dropdown.name)
                            }
                            menuOpenState={dropdown.isMenuOpen}
                          />
                        </div>
                      ) : null,
                    )}
                  </div>
                </div>

                <div className="high_impact__filter">
                  <div className="high_impact__filter_body">
                    <div className="title fs-18 fw-700">High Impact</div>
                    <button
                      type="button"
                      onClick={handleHighImpactToggle}
                      className="high_impact-toggle align-items-center fw-700"
                      style={{
                        backgroundColor: isHighImpactON ? "#2A00FF" : "#707070",
                      }}
                    >
                      <div
                        className={
                          isHighImpactON
                            ? "high_impact-toggle-circle-on"
                            : "high_impact-toggle-circle-off"
                        }
                      ></div>
                    </button>
                  </div>
                  <div
                    className="subtitle fs-14 fw-700 fc-grey"
                    style={{ marginBottom: "57px" }}
                  >
                    Select only “high impact” insights
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        );
      }; */

  const NullMultiValue = () => null;
  const CustomControl = ({ children, ...props }) => {
    return (
      <components.Control {...props} style={{ position: "relative" }}>
        {children}
      </components.Control>
    );
  };

  const CustomDropdownIndicator = (props) => {
    const { menuIsOpen } = props.selectProps;
    return (
      <div
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          pointerEvents: "none",
        }}
      >
        {menuIsOpen ? (
          <FiChevronUp color="#000000" size="24px" />
        ) : (
          <FiChevronDown color="#000000" size="24px" />
        )}
      </div>
    );
  };

  const NullClearIndicator = () => null;

  const CustomValueContainer = ({ children, ...props }) => {
    const selectedCount = props.selectProps.value.length;
    const showButton = selectedCount > 0;

    return (
      <components.ValueContainer {...props}>
        <div className="placeholder-count-container">
          <div className="always-placeholder">
            {props.selectProps.placeholder}
          </div>

          <div className="count_button">
            <button
              className={`selected-count-button ${!showButton ? "hidden" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {selectedCount}
            </button>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: "-9999px",
            height: 0,
            overflow: "hidden",
          }}
        >
          {React.Children.map(children, (child) =>
            child && child.type !== components.Placeholder ? child : null,
          )}
        </div>
      </components.ValueContainer>
    );
  };

  return (
    <div className="insights-page">
      {isLoading ? (
        <div className="spinner-container">
          <Spinner animation="border" className="loading-spinner" />
        </div>
      ) : insights.length === 0 ? (
        <h5 style={{ marginLeft: "70px", marginTop: "50px" }}>
          No insights for this project to show yet. You'll find your insights
          here when they are ready!
        </h5>
      ) : (
        <>
          <h2 className="fs-24 fw-700" style={{ marginBottom: "24px" }}>
            {insights.length} insights
          </h2>
          <div
            className="filter-section v6insights"
            style={{
              marginBottom: "18px",
            }}
          >
            <div className="d-flex flex-wrap">
              {dropdowns.map((dropdown, i) => {
                const OptionComponent =
                  dropdown.name === "Observation Types"
                    ? observationTypesOption
                    : checkCircle;

                const commonOptionStyles = (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? "var(--blue-opacity10)"
                    : "inherit",
                  color: state.isSelected ? "var(--main-blue)" : "inherit",
                  margin: "4px 0px",
                });

                const customStyles = {
                  option: commonOptionStyles,
                  ...(dropdown.name === "Pages" && {
                    menu: (provided) => ({
                      ...provided,
                      width: "500px",
                    }),
                  }),
                };
                return (
                  <div key={i} className="insights-custom-dropdown mb-3">
                    <CustomDropdown
                      options={dropdown.options}
                      onChange={dropdown.onChange}
                      isMulti={true}
                      hideSelectedOptions={false}
                      closeMenuOnSelect={false}
                      placeholder={dropdown.name}
                      value={dropdown.value}
                      unstyled
                      components={{
                        Option: OptionComponent,
                        MultiValue: NullMultiValue,
                        Control: CustomControl,
                        ValueContainer: CustomValueContainer,
                        ClearIndicator: NullClearIndicator,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                      styles={customStyles}
                      menuOpenState={openDropdown === dropdown.name ?? null}
                      onMenuOpen={() => setOpenDropdown(dropdown.name)}
                      onMenuClose={() => setOpenDropdown(null)}
                      onBlur={() => setOpenDropdown(null)}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="insights-filters"> */}
          {/* <button
                  className="btn-septenary btn-v6-fix fw-700 d-flex v6-insights-filters-button"
                  onClick={openFiltersModal}
                >
                  <div style={{ height: "20px" }}>FILTERS</div>

                  {activeFilters > 0 && (
                    <div className="active-filters-indicator">{activeFilters}</div>
                  )}
                </button> */}
          {/* <button className="btn-septenary btn-v6-fix fw-700 gain-button">
                  +12 098$ potential gain
                </button>
                <button className="btn-septenary btn-v6-fix fw-700 goals-button">
                  6 goals can be improved
                </button>
                <button className="btn-septenary btn-v6-fix fw-700 users-button">
                  1923 users impacted
                </button> */}
          {/* </div> */}
          <hr
            style={{
              margin: "2px",
              marginBottom: "31.8px",
              width: "100%",
            }}
          ></hr>
          <div className="insight__status">
            <div className="fs-14 fw-700 fc-grey d-flex align-items-start">
              {statuses.map(({ status, count }) => (
                <p
                  key={status}
                  className={selectedStatus === status ? "fc-blue" : ""}
                  onClick={() => {
                    setSelectedStatus(status);
                    setCheckedStates({});
                  }}
                >
                  {status.charAt(0).toUpperCase() + status.slice(1)} ({count})
                </p>
              ))}
            </div>
          </div>

          {filteredInsights.length > 0 ? (
            <InsightsTable
              widths={insightWidths}
              headers={insightHeaders}
              rows={filteredInsights}
              minWidths={insighMinWidths}
              openModal={openModal}
              digits={largestInsightDigits}
            />
          ) : (
            <div className="no-results-container">
              <div className="no-results-container-content">
                <img
                  src={luxiFaded}
                  style={{ width: "115px", marginRight: "32px" }}
                ></img>
                <div>
                  <div className="fc-black2 fw-500 fs-18">
                    There are no insights.
                  </div>
                  <div className="fc-black2 fw-500 fs-18">
                    Probably because you have active filters.
                  </div>
                  <div className="no-results-btn-content">
                    <button
                      className="btn-denary fw-700"
                      onClick={resetFilters}
                    >
                      Clear filters
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={
              selectedRowCount > 0 ? "delete-insightsv6-btn-container" : ""
            }
          >
            <div className="delete-insightsv6-btn d-flex align-items-start justify-content">
              {selectedRowCount > 0 &&
                userInfo.role !== "reader" &&
                selectedStatus !== "deleted" && (
                  <>
                    <button className="btn-septenary fw-700 btn-curved-left">
                      {selectedRowCount} insights selected
                    </button>
                    <div className="d-flex align-items-start justify-content">
                      <button
                        className="fc-white fw-700 btn-curved-right"
                        onClick={() => setIsDeleteModalOpen(true)}
                      >
                        <i
                          className="fa-regular fa-trash"
                          style={{ marginRight: "10px" }}
                        ></i>{" "}
                        Delete
                      </button>
                    </div>
                  </>
                )}
            </div>
          </div>

          {selectedInsight && isModalOpen && (
            <InsightModal
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              insights={filteredInsights}
              initialInsight={selectedInsight}
              onDeleteInsight={handleDeleteInsight}
              markInsightAsSeen={markInsightAsSeen}
              setInsights={setInsights}
              setFilteredInsights={setFilteredInsights}
              userRole={userInfo.role}
              impactResolver={impactResolver}
            />
          )}
          {/* {isFiltersModalOpen && (
                <FilterModal
                  selectedObservationTypes={selectedObservationTypes}
                  setSelectedObservationTypes={setSelectedObservationTypes}
                />
              )} */}
          <Modal
            isOpen={isDeleteModalOpen}
            onRequestClose={() => setIsDeleteModalOpen(false)}
            contentLabel="Insight Delete Modal"
            className="insight-delete-modal modal-shape d-flex flex-column align-items-center justify-content-around"
            appElement={document.getElementById("root")}
            style={{
              overlay: {
                background: "rgba(7, 21, 43, 0.7)",
                zIndex: 10,
              },
            }}
          >
            <h4 className="text-center">
              Are you sure you want to delete selected insights?
            </h4>
            <div className="d-flex justify-content-center insight-delete-button-height">
              <Button
                type="button"
                className="btn-primary me-3"
                onClick={() => {
                  handleBulkDeleteInsights();
                  setIsDeleteModalOpen(false);
                }}
              >
                Delete
              </Button>
              <Button
                type="button"
                className="btn-secondary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}
