import { useEffect, useState } from "react";
import CustomInput from "../CustomInput/CustomInput";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";

export default function AdminUserTracking(props) {
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [visits, setVisits] = useState(null);
  const [filteredVisits, setFilteredVisits] = useState(null);

  const handleFilterChange = (value) => {
    setFilter(value);
    const filtered = Object.keys(visits).reduce((acc, visitId) => {
      const filteredGroup = visits[visitId].filter((e) =>
        e?.name?.toLowerCase().includes(value.toLowerCase()),
      );
      if (filteredGroup.length > 0) {
        acc[visitId] = filteredGroup;
      }
      return acc;
    }, {});
    setFilteredVisits(filtered);
  };

  async function getAdminVisits() {
    try {
      const visitsQuery = query(
        collection(db, "visitActivity"),
        orderBy("timestamp", "asc"),
      );

      const visitsSnapshot = await getDocs(visitsQuery);
      const visitsList = visitsSnapshot.docs.map((doc) => doc.data());

      const groupedVisits = visitsList.reduce((acc, element) => {
        const visitId = element.visitId;
        if (!acc[visitId]) {
          acc[visitId] = [];
        }
        acc[visitId].push(element);
        return acc;
      }, {});

      setVisits(groupedVisits);
      setFilteredVisits(groupedVisits);
    } catch (error) {
      console.error("Error fetching visits: ", error);
    } finally {
      setLoading(false);
    }
  }

  async function logElementDetails(elementId) {
    try {
      const elementRef = doc(db, "elements", elementId);
      const elementSnapshot = await getDoc(elementRef);
      const elementData = elementSnapshot.data();
      console.log(elementData);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setLoading(true);
    getAdminVisits();
  }, []);

  return (
    <div className="mb-50">
      <div className="fs-24 fw-700 lh-243 mb-3 fc-black mb-50">
        User tracking admin
      </div>
      <div className="col-2 mb-16">
        <CustomInput
          placeholder="Filter by element name"
          containerClassName="observation-text-input-container half-width"
          inputClassNames="w-100"
          className={filter === "" ? "active" : ""}
          valueState={filter}
          onChange={(e) => handleFilterChange(e.target.value)}
          value={filter}
        />
      </div>
      {loading || !filteredVisits ? (
        <>Loading</>
      ) : (
        <div>
          {Object.values(filteredVisits)
            ?.reverse()
            .map((group, i) => (
              <div
                key={i}
                style={{
                  backgroundColor: "rgba(42, 0, 255, 0.1)",
                  marginBottom: "16px",
                  width: "100%",
                  padding: "16px 16px 8px 16px",
                  borderRadius: "8px",
                }}
              >
                {group.map((act, j) => (
                  <button
                    style={{ width: "max-content" }}
                    className="btn-octonary mr-8 mb-8"
                    key={`${i}-${j}`}
                    onClick={() => {
                      console.log(act);
                      logElementDetails(act.elementId);
                    }}
                  >
                    {act.name}{" "}
                    <i
                      onClick={() => window.open(act.url, "_blank")}
                      className="fa-regular fa-arrow-up-right-from-square"
                    ></i>
                  </button>
                ))}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
