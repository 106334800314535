import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./fonts/satoshi/WEB/fonts/Satoshi-Regular.ttf";
import Root from "./components/root";
import CreateProject from "./components/CreateProject/CreateProject";
import OnboardDesigner from "./components/OnboardDesigner/OnboardDesigner";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { IsMobileContext } from "./contexts";
import SendCode from "./components/SendCode/SendCode";

// const isMobile = window.innerWidth < 577;
const isMobile = false;

const router = createBrowserRouter([
  {
    path: "/*",
    element: <Root />,
    errorElement: <div>404 not found</div>,
  },
  {
    path: "/create-project",
    element: <CreateProject />,
  },
  {
    path: "/onboard-designer",
    element: <OnboardDesigner />,
  },
  {
    path: "/privacy",
    element: <div>Privacy</div>,
  },
  {
    path: "/terms-and-conditions",
    element: <div>Terms and Conditions</div>,
  },
  {
    path: "/cookies",
    element: <div>Cookies policy</div>,
  },
  {
    path: "/send-code",
    element: <SendCode />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <IsMobileContext.Provider value={isMobile}>
    <RouterProvider router={router}>
      <Root />
    </RouterProvider>
  </IsMobileContext.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
