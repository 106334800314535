import TopNavbar from "./CustomSidebar/TopNavbar";
import { useEffect } from "react";

export default function MainLayout({
  userChannels,
  handleChannelClick,
  openedChannel,
  children,
  isEmptyBar,
  setSelectedProject,
  setUserInfo,
  activeMenuItem,
  sidebarCollapsed,
  setSidebarCollapsed,
  isUserSettings,
  hideSidebar,
  profileView,
  onlineChats,
  unreadMessagesArr,
  setUserChannels,
  isFunnel,
  hideFunnelFilters,
  setHideFunnelFilters,
  setAdminPage,
  setHeatmapFunnelFilters,
  heatmapFunnelFilters,
  heatmapOrFunnel,
  heatmapPrefsLoaded,
  setHeatmapPrefsLoaded,
  storyState,
  setStoryState,
  tagOptions,
  setTagOptions,
  disabledFilters,
}) {
  useEffect(() => {
    const handleResize = () => {
      if (document.body.scrollWidth > window.innerWidth + 20) {
        document.body.style.overflowX = "auto";
      } else {
        document.body.style.overflowX = "hidden";
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div className="navbar-wrapper">
        <TopNavbar
          setSidebarCollapsed={setSidebarCollapsed}
          sidebarCollapsed={sidebarCollapsed}
          setSelectedProject={setSelectedProject}
          userChannels={userChannels}
          handleChannelClick={handleChannelClick}
          openedChannel={openedChannel}
          activeMenuItem={activeMenuItem}
          isEmptyBar={isEmptyBar}
          setUserInfo={setUserInfo}
          isUserSettings={isUserSettings}
          hideSidebar={hideSidebar}
          profileView={profileView}
          onlineChats={onlineChats}
          unreadMessagesArr={unreadMessagesArr}
          setUserChannels={setUserChannels}
          isFunnel={isFunnel}
          hideFunnelFilters={hideFunnelFilters}
          setHideFunnelFilters={setHideFunnelFilters}
          setAdminPage={setAdminPage}
          setHeatmapFunnelFilters={setHeatmapFunnelFilters}
          heatmapFunnelFilters={heatmapFunnelFilters}
          heatmapOrFunnel={heatmapOrFunnel}
          heatmapPrefsLoaded={heatmapPrefsLoaded}
          setHeatmapPrefsLoaded={setHeatmapPrefsLoaded}
          storyState={storyState}
          setStoryState={setStoryState}
          tagOptions={tagOptions}
          setTagOptions={setTagOptions}
          disabledFilters={disabledFilters}
        />
      </div>

      {/* <CustomSidebar
        setSidebarCollapsed={setSidebarCollapsed}
        sidebarCollapsed={sidebarCollapsed}
        setSelectedProject={setSelectedProject}
        userChannels={userChannels}
        handleChannelClick={handleChannelClick}
        openedChannel={openedChannel}
        activeMenuItem={activeMenuItem}
        isEmptyBar={isEmptyBar}
        setUserInfo={setUserInfo}
        isUserSettings={isUserSettings}
        hideSidebar={hideSidebar}
        profileView={profileView}
        onlineChats={onlineChats}
        unreadMessagesArr={unreadMessagesArr}
        setUserChannels={setUserChannels}
        isFunnel={isFunnel}
        hideFunnelFilters={hideFunnelFilters}
        setHideFunnelFilters={setHideFunnelFilters}
        setAdminPage={setAdminPage}
        setHeatmapFunnelFilters={setHeatmapFunnelFilters}
        heatmapFunnelFilters={heatmapFunnelFilters}
        heatmapOrFunnel={heatmapOrFunnel}
        heatmapPrefsLoaded={heatmapPrefsLoaded}
        setHeatmapPrefsLoaded={setHeatmapPrefsLoaded}
      />  */}
      <div
        className={
          hideSidebar ? "" : `main-parent${hideFunnelFilters ? " reduced" : ""}`
        }
      >
        {children}
      </div>
    </div>
  );
}
