import StoryPage from "./StoryPage";
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import config from "../../config";
import {
  formatNumberSpaceSeparated,
  formatPercentagePlain,
  getImpactChip,
} from "../../helpers";

const StorySelector = ({
  page,
  deviceType,
  mouseX,
  mouseY,
  setSelectedElement,
  setStoryState,
  storyState,
  story,
  setSelectedStory,
  hideFunnelFilters,
  setMinHeight,
}) => {
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [elementsRendered, setElementsRendered] = useState(false);
  const linesRef = useRef([]);
  const scrollHandlerRef = useRef(null);
  const [lifetimeLines, setLifetimeLines] = useState([]);

  useEffect(() => {
    function getIconText(type) {
      if (type === "goal") return "fa-fire-flame-curved";
      if (type === "page") return "fa-page";
      if (type === "exit") return "fa-person-from-portal";
      return null;
    }

    function buildElements(c, i) {
      const storyItem = document.createElement("div");
      storyItem.id = `story-selector-card-${i}`;
      storyItem.className = "story-selector-card";
      storyItem.style.left = config.HEATMAP_PAGE_WIDTH + 100 + "px";

      const impactChipContainer = document.createElement("div");
      const impactChipHTML = getImpactChip(
        c.displayDropoffs,
        c.impactConversions,
        false,
        "fvc fc-white impact-chip-default mb-10",
      );
      if (impactChipHTML) {
        const root = createRoot(impactChipContainer);
        root.render(impactChipHTML);
      }

      const iconText = getIconText(c.type);
      if (!iconText) return null;

      const icon = document.createElement("i");
      icon.className = `fa-regular ${iconText} mr-8 fs-20`;

      const title = document.createElement("span");
      title.className = "fs-14 fc-black2 fw-700 wrapword";
      title.innerText = c.value;

      const users = document.createElement("span");
      users.className = "fs-14 fc-grey fw-700";
      const percentageFormatted = formatPercentagePlain(
        (c.sessions - c.dropoffs) / story.totalVisits,
      );
      users.innerText = `${percentageFormatted} of Users,`;

      const sessions = document.createElement("span");
      sessions.className = "fs-14 fc-grey fw-600";
      const sessionsFormatted = formatNumberSpaceSeparated(
        c.sessions - c.dropoffs,
      );
      const plural = c.sessions - c.dropoffs > 1 ? "s" : "";
      sessions.innerText = ` ${sessionsFormatted} Session${plural}`;

      const arrow = document.createElement("i");
      arrow.className = `fa-regular fa-arrow-down mr-10 fs-14 fc-blue`;

      const dropoffs = document.createElement("span");
      dropoffs.className = "fs-14 fc-blue fw-700";
      const dropoffsFormatted = formatPercentagePlain(
        c.displayDropoffs / c.sessions,
      );
      dropoffs.innerText = ` ${dropoffsFormatted} dropoff`;

      const headerContainer = document.createElement("div");
      headerContainer.className = "mb-10";
      const usersContainer = document.createElement("div");
      const dropoffContainer = document.createElement("div");
      dropoffContainer.className = "mb-10";

      const selectButton = document.createElement("button");
      selectButton.className = "btn-nonary fw-700";
      selectButton.innerText = "Analyze this path";
      selectButton.onclick = () => {
        setSelectedStory({ ...c, totalTraffic: story.totalVisits });
        setStoryState(4);
      };

      headerContainer.appendChild(icon);
      headerContainer.appendChild(title);
      usersContainer.appendChild(users);
      usersContainer.appendChild(sessions);
      dropoffContainer.appendChild(arrow);
      dropoffContainer.appendChild(dropoffs);

      storyItem.appendChild(impactChipContainer);
      storyItem.appendChild(headerContainer);
      storyItem.appendChild(usersContainer);
      storyItem.appendChild(dropoffContainer);
      storyItem.appendChild(selectButton);

      if (i > 0) {
        const previousItem = storySelectorArea.children[i - 1];
        const previousItemHeight = previousItem.offsetHeight;
        storyItem.style.top = `${
          previousItem.offsetTop + previousItemHeight + 90
        }px`;
      } else {
        storyItem.style.top = "100px";
      }

      storySelectorArea.appendChild(storyItem);
    }

    const storySelectorArea = document.getElementById("story-selector-area");
    if (!storySelectorArea) return;
    const linesArr = [];

    if (!elementsRendered) {
      story.stories.forEach((c, i) => {
        buildElements(c, i);
      });
    } else {
      story.stories.forEach((c, i) => {
        const start = document.getElementById("story-start-element");
        const end = document.getElementById(`story-selector-card-${i}`);
        const line = new window.LeaderLine(
          window.LeaderLine.pointAnchor(start, {
            x: start.offsetWidth + 15,
            y: start.offsetHeight / 2,
          }),
          window.LeaderLine.pointAnchor(end, {
            x: -15,
            y: end.offsetHeight / 2,
          }),
          {
            startSocket: "right",
            endSocket: "left",
            path: "fluid",
            color: "white",
            endPlug: "behind",
            size: 1,
          },
        );
        linesArr.push(line);
        setLifetimeLines([...lifetimeLines, line]);
      });
    }

    // Calculate for card overflow
    let height = 1000;
    Array.from(storySelectorArea.children).forEach((item) => {
      const itemBottom = item.offsetTop + item.offsetHeight;
      if (itemBottom > height) {
        height = itemBottom;
      }
    });

    setMinHeight(height + 500);

    linesRef.current = linesArr;

    const scrollHandler = () => {
      linesRef.current.forEach((line) => line?.position());
    };

    const scrollArea = document.getElementById("story-scroll-container");
    scrollArea.addEventListener("scroll", scrollHandler, true);

    scrollHandlerRef.current = scrollHandler;
  }, [story, elementsRendered]);

  useEffect(() => {
    return () => {
      linesRef.current.forEach((line) => {
        line.remove();
      });
      linesRef.current = [];
      lifetimeLines.forEach((line) => {
        line.remove();
      });

      const scrollArea = document.getElementById("story-scroll-container");
      const functionRef = scrollHandlerRef.current;
      if (scrollArea && functionRef) {
        scrollArea.removeEventListener("scroll", functionRef, true);
      }
    };
  }, []);

  useEffect(() => {
    if (!scrollHandlerRef.current) return;

    let animationFrameId;

    const updateScrollHandler = () => {
      scrollHandlerRef.current();
      animationFrameId = requestAnimationFrame(updateScrollHandler);
    };

    animationFrameId = requestAnimationFrame(updateScrollHandler);

    const timeoutId = setTimeout(() => {
      cancelAnimationFrame(animationFrameId);
    }, 300);

    return () => {
      cancelAnimationFrame(animationFrameId);
      clearTimeout(timeoutId);
    };
  }, [hideFunnelFilters]);

  return (
    <div
      style={{
        position: "relative",
        width: "calc(100% + 80px)",
        minWidth: "1300px",
      }}
    >
      <div
        className="story-wide-overlay"
        style={{
          height: canvasHeight + 1,
          top: config.STORY_PAGE_HEADER_HEIGHT,
        }}
      ></div>
      <div
        id="story-selector-area"
        style={{
          top: config.STORY_PAGE_HEADER_HEIGHT,
        }}
      ></div>
      <StoryPage
        pageCount={1}
        i={0}
        page={page}
        deviceType={deviceType}
        mouseX={mouseX}
        mouseY={mouseY}
        blockMouse={true}
        storyState={storyState}
        setStoryState={setStoryState}
        setSelectedElement={setSelectedElement}
        setCanvasHeight={setCanvasHeight}
        setElementsRendered={setElementsRendered}
      />
    </div>
  );
};

export default StorySelector;
