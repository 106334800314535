import StoryPage from "./StoryPage";
import { useEffect, useState, useRef } from "react";
import config from "../../config";
import { createRoot } from "react-dom/client";
import { formatPercentagePlain } from "../../helpers";

const StoryDisplay = ({
  deviceType,
  mouseX,
  mouseY,
  setStoryState,
  storyState,
  selectedStory,
  hideFunnelFilters,
  setTotalPages,
}) => {
  const [pages, setPages] = useState(null);
  const [elementsRendered, setElementsRendered] = useState([]);
  const [sequencesWithTrueId, setSequencesWithTrueId] = useState([]);
  const [idSequencePairs, setIdSequencePairs] = useState(null);

  const [tallestPageHeight, setTallestPageHeight] = useState(null);

  const linesRef = useRef([]);
  const scrollHandlerRef = useRef(null);
  const [lifetimeLines, setLifetimeLines] = useState([]);

  const lockedFocusRef = useRef(null);

  const hoverableElements = useRef([]);

  const storySelectorArea = useRef(null);

  // Prep elements for drawing and build sequences
  // Note: elements themselves are drawn on the storyPage component
  useEffect(() => {
    function buildSequencesWithTrueId(sequences) {
      if (sequences.length === 0) return [];

      sequences.forEach((sequence) => {
        if (sequence.length === 0) return;

        let currentUrl = sequence[0].url;
        let index = 0;
        sequence.forEach((el) => {
          if (currentUrl !== el.url) {
            currentUrl = el.url;
            index++;
          }

          el.trueId = `${el.elementId}-${index}`;
        });
      });

      return sequences;
    }

    function segmentActivityIntoElementsOnPages(sequences) {
      const groupedElements = [];
      sequences.forEach((sequence) => {
        if (sequence.length === 0) return;

        let currentUrl = sequence[0].url;
        let index = 0;
        sequence.forEach((el) => {
          if (currentUrl !== el.url) {
            currentUrl = el.url;
            index++;
          }

          const group = groupedElements[index] || [];
          group.push({
            ...el,
            trueId: `${el.elementId}-${index}`,
            pageIndex: index,
          });
          groupedElements[index] = group;
        });
      });

      return groupedElements;
    }

    if (!selectedStory) return;
    const pages = [];

    const sequencesWithTrueId = buildSequencesWithTrueId(
      selectedStory.sequences,
    );

    setSequencesWithTrueId(sequencesWithTrueId);

    const elementsOnPage = segmentActivityIntoElementsOnPages(
      selectedStory.sequences,
    );

    let urlPath = "";
    selectedStory.pageUrls.forEach((url, i) => {
      urlPath = urlPath === "" ? url : [urlPath, url].join(" => ");

      const page = {
        traffic: selectedStory.pageTraffic[i],
        totalTraffic: selectedStory.totalTraffic,
        trafficPercentage: formatPercentagePlain(
          selectedStory.pageTraffic[i] / selectedStory.totalTraffic,
        ).slice(0, -1),
        url,
        title: selectedStory.pageTitles[i],
        elements: elementsOnPage[i],
        img: selectedStory.pageScreenshots[i],
      };

      pages.push(page);
    });

    setPages(pages);
  }, [selectedStory]);

  // Draw lines connecting elements, once all elements are rendered by the StoryPage
  // Also draw the action description boxes on the midpoints
  useEffect(() => {
    function setupRenderLeaderLines() {
      function renderLeaderLine(start, end, linesArr) {
        try {
          const line = new window.LeaderLine(start, end, {
            path: "fluid",
            color: "rgba(255, 255, 255, 1)",
            endPlug: "arrow3",
            endPlugSize: 3,
            size: 1,
            positionByWindowResize: false,
          });

          linesArr.push(line);
          setLifetimeLines((prev) => [...prev, line]);
        } catch (e) {}
      }

      function getIdSequencePairs(sequencesWithTrueId) {
        const idSequencePairs = new Set();
        sequencesWithTrueId.forEach((sequence) => {
          for (let i = 0; i < sequence.length - 1; i++) {
            const id1 = sequence[i].trueId;
            const id2 = sequence[i + 1].trueId;

            const idSequencePair = `${id1} => ${id2}`;
            idSequencePairs.add(idSequencePair);
          }
        });

        return Array.from(idSequencePairs);
      }

      const linesArr = [];
      const idSequencePairs = getIdSequencePairs(sequencesWithTrueId);
      setIdSequencePairs(idSequencePairs);

      idSequencePairs.forEach((pair) => {
        const startId = pair.split(" => ")[0];
        const endId = pair.split(" => ")[1];
        const start = document.getElementById(startId);
        const end = document.getElementById(endId);

        if (!start || !end) {
          return;
        }

        renderLeaderLine(start, end, linesArr);
      });

      // Update line positions on scroll
      linesRef.current = linesArr;
      const scrollHandler = () => {
        linesRef.current.forEach((line) => line?.position());
      };

      const scrollArea = document.getElementById("story-scroll-container");
      scrollArea.addEventListener("scroll", scrollHandler, true);
      scrollHandlerRef.current = scrollHandler;
    }

    function setupRenderActionCards() {
      function renderActionCard(
        element,
        totalTraffic,
        elementsContainer,
        nonDropoffMap,
        trueSessionMap,
      ) {
        // Placeholder for different interactions
        function getIconText(type) {
          if (type === "exit") {
            return "fa-person-from-portal";
          }
          return "fa-fire-flame-curved";
        }

        if (!elements || elements.size === 0) return;
        const startData = element;
        if (!startData) return;
        const startId = element.trueId;
        const trueSessions =
          trueSessionMap.get(startData.trueId) || startData.sessions;

        const parentRect = elementsContainer.getBoundingClientRect();
        const start = document.getElementById(startId);
        const startRect = start.getBoundingClientRect();
        const x = startRect.x - parentRect.x + startRect.width / 2;
        const y = startRect.y - parentRect.y + startRect.height + 16;

        const users = formatPercentagePlain(trueSessions / totalTraffic);
        const continues = nonDropoffMap?.get(startId) ?? null;
        const dropoff = continues
          ? formatPercentagePlain((trueSessions - continues) / trueSessions)
          : "100%";

        let iconText = getIconText();
        if (!continues) {
          iconText = getIconText("exit");
        }

        const cardHTML = (
          <div
            id={`${startId}-card`}
            className="story-action-card story-full-opacity"
            style={{ position: "absolute", top: y, left: x > 160 ? x : 160 }}
          >
            <div className="w-100 fw-700 d-flex">
              <div>
                <i className={`fa-regular ${iconText} mr-8 fs-20`}></i>
              </div>
              <div className="w-100">
                <div className="fc-black2 limit-2-lines wrapword">
                  <div>Click on {startData.name}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="fc-grey fw-600">{`${users} of Users`}</div>
                  <div className="d-flex fc-blue">
                    <i
                      className={"fvc fa-regular fa-arrow-down mr-10 fs-14"}
                    ></i>
                    <div>{`${dropoff} dropoff`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

        const cardContainer = document.createElement("div");
        const root = createRoot(cardContainer);
        root.render(cardHTML);
        elementsContainer.appendChild(cardContainer);
        hoverableElements.current.push(cardContainer);
      }

      function filterTopElementsOfEachPage(trueSessionMap) {
        const elements = new Map();
        pages.forEach((page) => {
          const uniqueElements = new Set(page.elements.map((el) => el.trueId));
          let elementsOnPage = Array.from(uniqueElements).map((id) =>
            page.elements.find((el) => el.trueId === id),
          );
          if (elementsOnPage.length > config.STORY_MAX_ELEMENTS_PER_PAGE) {
            elementsOnPage = Array.from(uniqueElements)
              .map((id) => page.elements.find((el) => el.trueId === id))
              .sort(
                (a, b) =>
                  trueSessionMap.get(b.trueId) - trueSessionMap.get(a.trueId),
              )
              .slice(0, config.STORY_MAX_ELEMENTS_PER_PAGE);
          }

          elementsOnPage.forEach((el) => {
            elements.set(el.trueId, {
              ...el,
              sessions: trueSessionMap.get(el.trueId) || el.sessions,
            });
          });
        });
        return elements;
      }

      const elementsContainer = document.getElementById("story-selector-area");
      if (!elementsContainer) return;
      storySelectorArea.current = elementsContainer;

      const nonDropoffMap = new Map();
      const trueSessionMap = new Map();
      const sessionTransitionMap = new Map();
      selectedStory.sequences.forEach((sequence) => {
        if (sequence.length === 0) return;
        let sequenceKey = sequence[0].trueId;

        sequence.forEach((el, i) => {
          if (i == 0) return;
          sequenceKey = `${sequenceKey} => ${el.trueId}`;
          if (!sessionTransitionMap.has(sequenceKey)) {
            // ex. A>B>C, A>B has 100 -> 50 sessions.
            // ex. A>B>D, A>B has 100 -> 50 sessions.
            // Because sequences A>B>C and A>B>D share the same A>B, we need to NOT sum them.
            // This is to avoid doublecounting.
            sessionTransitionMap.set(sequenceKey, el.sessions);
          }
        });
      });

      sessionTransitionMap.forEach((sessions, key) => {
        // The number of sessions in the last element will tell us
        // the dropoff of the second last element once we sum all of them.
        // ex. A>B>C, C has 50 sessions.
        // ex. A>B>D, D has 50 sessions.
        // This means 100 sessions made it to the next step from B.
        // If 300 sessions were at B, that means 200/300 of them were dropoffs.
        // This loop is to build the map that provides the 100 value for that calculation.
        const elements = key.split(" => "); // Assume there will be at least 2 elements
        const secondLastElementId = elements[elements.length - 2];
        nonDropoffMap.set(
          secondLastElementId,
          (nonDropoffMap.get(secondLastElementId) || 0) + sessions,
        );

        // A>B>C and X>B>Y
        // B is present in both, so need to combine them for their true session count.
        const lastElementId = elements[elements.length - 1];
        trueSessionMap.set(
          lastElementId,
          (trueSessionMap.get(lastElementId) || 0) + sessions,
        );
      });

      const elements = filterTopElementsOfEachPage(trueSessionMap);
      elements.forEach((el) => {
        renderActionCard(
          el,
          pages[0].totalTraffic,
          elementsContainer,
          nonDropoffMap,
          trueSessionMap,
        );
      });
    }

    if (
      !pages ||
      pages.length === 0 ||
      sequencesWithTrueId.length === 0 ||
      elementsRendered.length !== pages.length ||
      elementsRendered.length !== selectedStory.pageUrls.length
    ) {
      return;
    }

    setupRenderLeaderLines();
    setupRenderActionCards();
  }, [selectedStory, elementsRendered, pages, sequencesWithTrueId]);

  useEffect(() => {
    setTotalPages(selectedStory.pageUrls.length);
    const handleMouseClick = (event) => {
      const clickedElement = hoverableElements.current.find((el) =>
        el.contains(event.target),
      );
      if (clickedElement) {
        lockedFocusRef.current = clickedElement.firstChild;
      } else {
        lockedFocusRef.current = null;
      }
    };

    document.addEventListener("click", handleMouseClick);

    return () => {
      document.removeEventListener("click", handleMouseClick);
      // Clean up leaderline listeners
      linesRef.current.forEach((line) => {
        line.remove();
      });
      linesRef.current = [];
      lifetimeLines.forEach((line) => {
        line.remove();
      });

      const scrollArea = document.getElementById("story-scroll-container");
      const functionRef = scrollHandlerRef.current;
      if (scrollArea && functionRef) {
        scrollArea.removeEventListener("scroll", functionRef, true);
      }
    };
  }, []);

  // Opacity on hover control for cards
  useEffect(() => {
    function cascadeHover(hoveredElement) {
      if (!idSequencePairs || idSequencePairs.length === 0) return;
      const hoveredId = hoveredElement.id.split("-card")[0];
      idSequencePairs.forEach((pair) => {
        pair = pair.split(" => ");
        if (pair.includes(hoveredId)) {
          const otherId = pair.find((id) => id !== hoveredId);
          const element = document.getElementById(`${otherId}-card`);
          if (element) {
            element.classList.add("story-full-opacity");
          }
        }
      });
    }

    if (hoverableElements.length === 0 || !storySelectorArea.current) return;

    let closest = null;
    let minDistance = Infinity;
    let mouseInside = false;

    hoverableElements.current.forEach((el) => {
      if (!el || !el.firstChild) return;
      const rect = el.firstChild.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + window.scrollY + rect.height / 2;

      mouseInside =
        mouseInside || // skip calculation if already true
        (mouseX >= rect.left &&
          mouseX <= rect.right &&
          mouseY >= rect.top + window.scrollY &&
          mouseY <= rect.bottom + window.scrollY);

      const dx = mouseX - centerX;
      const dy = mouseY - centerY;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < minDistance) {
        minDistance = distance;
        closest = el.firstChild;
      }
    });

    let focus;
    if (closest && mouseInside) {
      focus = closest;
    }

    if (lockedFocusRef.current) {
      focus = lockedFocusRef.current;
    } // Higher priority, if element was clicked use that as focus.

    if (focus) {
      hoverableElements.current.forEach((el) => {
        if (!el || !el.firstChild) return;
        el.firstChild.classList.remove("story-full-opacity");
        el.firstChild.classList.remove("story-top-layer");
      });
      cascadeHover(focus);

      focus.classList.add("story-full-opacity");
      focus.classList.add("story-top-layer");
      const elementId = focus.id.split("-card")[0];
      lifetimeLines.forEach((line) => {
        if ([line.start.id, line.end.id].includes(elementId)) {
          line.color = "rgba(255, 255, 255, 1)";
        } else {
          line.color = "rgba(255, 255, 255, 0.0)";
        }
      });
    } else {
      lifetimeLines.forEach((line) => {
        line.color = "rgba(255, 255, 255, 1)";
      });
      hoverableElements.current.forEach((el) => {
        if (!el || !el.firstChild) return;
        el.firstChild.classList.add("story-full-opacity");
      });
    }
  }, [hoverableElements, mouseX, mouseY, lockedFocusRef.current]);

  // The lines need to update positions when filter menu is opened/closed
  useEffect(() => {
    if (!scrollHandlerRef.current) return;

    let animationFrameId;

    const updateScrollHandler = () => {
      scrollHandlerRef.current();
      animationFrameId = requestAnimationFrame(updateScrollHandler);
    };

    animationFrameId = requestAnimationFrame(updateScrollHandler);

    const timeoutId = setTimeout(() => {
      cancelAnimationFrame(animationFrameId);
    }, 300);

    return () => {
      cancelAnimationFrame(animationFrameId);
      clearTimeout(timeoutId);
    };
  }, [hideFunnelFilters]);

  if (
    !selectedStory?.pageUrls ||
    selectedStory.pageUrls.length === 0 ||
    !pages ||
    pages.length === 0
  ) {
    return (
      <h3
        style={{
          marginRight: `-${config.HEATMAP_PAGE_WIDTH}px`,
        }}
      >
        Invalid, please select another story.
      </h3>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        minWidth: "1300px",
        display: "flex",
      }}
    >
      <div
        id="story-selector-area"
        style={{
          top: config.STORY_PAGE_HEADER_HEIGHT,
        }}
      ></div>
      <>
        {pages.map((page, i) => {
          return (
            <div
              style={{
                paddingRight:
                  i == pages.length - 1
                    ? `calc(100% - ${config.HEATMAP_PAGE_WIDTH}px)`
                    : 0,
              }}
              key={i}
            >
              <StoryPage
                pageCount={selectedStory.pageUrls.length}
                page={page}
                i={i}
                deviceType={deviceType}
                mouseX={mouseX}
                mouseY={mouseY}
                setStoryState={setStoryState}
                storyState={storyState}
                mergedPages={true}
                setTooltipContent={() => {}}
                setTooltipCoords={() => {}}
                blockMouse={true}
                setElementsRendered={setElementsRendered}
                setTallestPageHeight={setTallestPageHeight}
                tallestPageHeight={tallestPageHeight}
              />
            </div>
          );
        })}
      </>
    </div>
  );
};

export default StoryDisplay;
