import { useEffect, useRef, useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import config from "../../config";
import { getScreenWidth } from "../../helpers";

const StoryPage = ({
  // General
  i,
  page,
  deviceType,
  mouseX,
  mouseY,
  setModalShow,
  setSelectedElement,
  setTooltipContent,
  setTooltipCoords,
  setGoalName,
  blockMouse,
  setStoryState,
  storyState,

  // Stage 1
  setStoryStartPage,
  canvasHeight,
  setCanvasHeight,

  // Stage 3 & 4
  setElementsRendered,

  // Stage 4
  setTallestPageHeight,
  tallestPageHeight,

  // etc
  savedScroll,
  setSavedScroll,
  pageCount = 1,

  mergedPages = false,
}) => {
  const stickyOffset = -98; // Offset caused by topbar
  const heatmapPageWidth = config.HEATMAP_PAGE_WIDTH;
  const storyPageOffset = config.STORY_PAGE_OFFSET;
  const storyPageWidth = heatmapPageWidth + storyPageOffset;

  const showAllElements = true;
  const showOnlyValidElements = false;

  const [imgSrcSized, setImgSrcSized] = useState("");
  const latestTimestampRefImages = useRef(0);
  const canvasRef = useRef(null);
  const imgRef = useRef(null);

  const itemRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const [scaledElements, setScaledElements] = useState(null);
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.01 },
    );

    if (itemRef.current) {
      observer.observe(itemRef.current);
    }

    return () => {
      if (itemRef.current) {
        observer.unobserve(itemRef.current);
      }
    };
  }, []);

  useEffect(() => {
    function checkIsHovering() {
      const rect = itemRef.current.getBoundingClientRect();
      return (
        mouseX >= rect.left + window.scrollX &&
        mouseX <= rect.right + window.scrollX &&
        mouseY >= rect.top + window.scrollY &&
        mouseY <= rect.bottom + window.scrollY
      );
    }

    function pointInZone(pointX, pointY, zone) {
      return (
        pointX >= zone.x &&
        pointX <= zone.x + zone.w &&
        pointY >= zone.y &&
        pointY <= zone.y + zone.h
      );
    }

    function checkElementHovers(ctx, elements) {
      const rect = itemRef.current.getBoundingClientRect();
      const relativeMouseX = mouseX - rect.left + itemRef.current.scrollLeft;
      const relativeMouseY =
        mouseY -
        rect.top +
        itemRef.current.scrollTop -
        window.scrollY +
        stickyOffset;
      const hoverableItems = elements.filter((item) =>
        pointInZone(relativeMouseX, relativeMouseY, item),
      );

      if (hoverableItems.length === 0) return;

      const focus = hoverableItems.reduce((smallest, item) => {
        const smallestArea = smallest.w * smallest.h;
        const itemArea = item.w * item.h;
        return itemArea < smallestArea ? item : smallest;
      });

      ctx.strokeStyle = "white";
      ctx.lineWidth = 2;
      ctx.strokeRect(focus.x, focus.y, focus.w, focus.h);
      setTooltipCoords({
        x: focus.x + focus.w + i * storyPageWidth - 10,
        y: focus.y + focus.h - window.scrollY + 92 - 10,
        visible: true,
      });
      setTooltipContent(
        <div className={focus.isValid ? "heatmap-popper" : ""}>
          <div>
            {focus.isValid && (
              <button
                className="btn-nonary fs-13 fw-700 d-flex mb-8"
                onClick={() => {
                  setSavedScroll(window.scrollY);
                  const filteredElement = page.elements.filter(
                    (element) => element.id === focus.id,
                  );
                  setStoryStartPage({ ...page, elements: filteredElement });
                  setStoryState(2);
                }}
              >
                <i className="fs-20 mr-8 fa-regular fa-chart-network"></i>
                Discover Story of this element
              </button>
            )}

            <button
              className="btn-nonary fs-13 fw-700"
              onClick={() => {
                setSelectedElement(focus);
                setModalShow(true);
                setGoalName(focus.name);
              }}
            >
              <i className="fs-20 mr-8 fa-regular fa-star"></i>
              Register this element as a goal
            </button>
          </div>
        </div>,
      );
    }

    if (blockMouse) return;
    if (!isVisible || !itemRef.current || !scaledElements) return;
    if (!checkIsHovering()) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (showAllElements) {
      scaledElements.forEach((element) => {
        ctx.strokeStyle = element.isValid ? "#00FF00" : "red";
        ctx.lineWidth = 1;
        ctx.strokeRect(element.x, element.y, element.w, element.h);
      });
    }

    checkElementHovers(ctx, scaledElements);
  }, [mouseX, mouseY]);

  useEffect(() => {
    if (!isVisible && storyState !== 4) return;
    let isMounted = true;
    const currentTimestamp = Date.now();

    const loadImage = async (src) => {
      if (!src) {
        return "";
      }
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = src;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const cropWidth = getScreenWidth(deviceType);
          const cropHeight = img.naturalHeight;
          const scale = heatmapPageWidth / cropWidth;
          canvas.width = heatmapPageWidth;
          canvas.height = cropHeight * scale;

          if (setTallestPageHeight && storyState === 4)
            setTallestPageHeight((prev) => Math.max(prev, cropHeight * scale));

          ctx.drawImage(
            img,
            0,
            0,
            cropWidth,
            cropHeight,
            0,
            0,
            heatmapPageWidth,
            cropHeight * scale,
          );

          const scaledElements = page.elements
            ? page.elements.map((element) => ({
                ...element,
                // Basic fields
                x: element.x * scale,
                y: element.y * scale,
                w: element.w * scale,
                h: element.h * scale,
                isValid: element.isValid,
                id: element.id,

                // Stage 4 fields
                elementId: `${element.elementId}-${element.pageIndex}`,
                name: element.name,
                pageIndex: element.pageIndex ?? 0,
                traffic: element.sessions ?? 0,
              }))
            : [];

          const filteredScaledElements = scaledElements.filter((element) => {
            if (!showOnlyValidElements) return true;
            return element.isValid;
          });
          setScaledElements(filteredScaledElements);

          canvasRef.current.width = canvas.width;
          canvasRef.current.height = canvas.height;

          if (showAllElements && ![2, 3, 4].includes(storyState)) {
            const canvas2 = canvasRef.current;
            const ctx2 = canvas2.getContext("2d");
            ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
            scaledElements.forEach((element) => {
              ctx2.strokeStyle = element.isValid ? "#00FF00" : "red";
              ctx2.lineWidth = 1;
              ctx2.strokeRect(element.x, element.y, element.w, element.h);
            });
          }

          const croppedImageSrc = canvas.toDataURL();
          if ([2, 3].includes(storyState)) {
            setCanvasHeight(canvas.height);
          }
          resolve(croppedImageSrc);
        };

        img.onerror = (e) => {
          reject(e);
        };
      });
    };

    loadImage(page.img)
      .then((croppedImage) => {
        if (isMounted && currentTimestamp > latestTimestampRefImages.current) {
          latestTimestampRefImages.current = currentTimestamp;
          setImgSrcSized(croppedImage);
        }
      })
      .catch((e) => {
        console.error("Error loading image:", e);
      });

    return () => {
      isMounted = false;
    };
  }, [isVisible]);

  // Draw the "Generating stories..." tooltip on stage 2
  // Draw the focused element on stages 2 and 3
  useEffect(() => {
    function renderElement(
      container,
      el,
      offset = 0,
      id = "story-start-element",
    ) {
      // Create and style the focused element
      const focusedElement = document.createElement("div");
      focusedElement.style.position = "absolute";
      focusedElement.style.border = "1px solid white";
      focusedElement.style.left = `${el.x + offset}px`;
      focusedElement.style.top = `${el.y}px`;
      focusedElement.style.width = `${el.w}px`;
      focusedElement.style.height = `${el.h}px`;
      focusedElement.style.zIndex = 10;
      focusedElement.id = id;
      container.appendChild(focusedElement);
    }

    function renderGeneratingComponents() {
      function renderGeneratingStoriesTooltip() {
        let x = el.x + el.w + 5;
        let y = el.y + el.h + 5;
        if (y < 30) {
          y = 30;
        }
        if (y > elementsContainer.clientHeight - 60) {
          y = elementsContainer.clientHeight - 60;
        }

        // Create and style the tooltip
        const tooltip = document.createElement("div");
        tooltip.className = "generating-stories-tooltip";
        tooltip.style.left = `${x}px`;
        tooltip.style.top = `${y}px`;
        tooltip.innerText = "Generating stories...";
        elementsContainer.appendChild(tooltip);
      }

      const el = scaledElements[0];
      const elementsContainer = document.getElementById("generator-container");
      renderElement(elementsContainer, el);
      renderGeneratingStoriesTooltip();
    }

    function renderSelectorComponents() {
      const el = scaledElements[0];
      const elementsContainer = document.getElementById("generator-container");
      renderElement(elementsContainer, el);
      setElementsRendered(true);
    }

    function renderDisplayComponents() {
      const elementsContainer = document.getElementById("generator-container");
      const renderedElements = new Set();
      scaledElements.forEach((el) => {
        if (renderedElements.has(el.trueId)) return;
        const offset = el.pageIndex * heatmapPageWidth;
        renderElement(elementsContainer, el, offset, el.elementId);
        renderedElements.add(el.trueId);
      });
      setElementsRendered((prev) => [...prev, i]);
    }

    if (!scaledElements || !scaledElements[0] || hasRendered) return;
    if (storyState === 2) renderGeneratingComponents();
    if (storyState === 3) renderSelectorComponents();
    if (storyState === 4) renderDisplayComponents();
    setHasRendered(true);

    if (savedScroll) {
      window.scrollTo({ top: savedScroll, behavior: "instant" });
      setSavedScroll(null);
    }
  }, [scaledElements]);

  const isFirst = i === 0;
  const isLast = i === pageCount - 1;
  const isSingle = pageCount === 1;

  const borderStyle = `${isFirst || isSingle ? "rounded-left" : ""} ${
    isLast || isSingle ? "rounded-right" : ""
  }`.trim();

  const hasRightBorder = isLast || isSingle;

  return (
    <div
      ref={itemRef}
      id={`heatmap${i}`}
      key={i}
      className={`${borderStyle}
        heatmap-item ${mergedPages ? "" : "mr-24"}`}
      style={{
        display: "block",
      }}
    >
      <div className={`${borderStyle}`}>
        <div
          className={`${
            hasRightBorder ? "border-right" : ""
          } story-page-header heatmap-item-page fs-16 fw-900 fc-black d-flex vhc`}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                <div>{`${page?.title || "No title"}`}</div>
                <div>{`${page?.url || "No URL"}`}</div>
              </Tooltip>
            }
          >
            <div className="story-page-url">{page?.title || "No title"}</div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>
                <div>{`${
                  page.traffic && page.totalTraffic
                    ? `${page.traffic} of ${page.totalTraffic} sessions`
                    : `${page.trafficPercentage}% of traffic`
                }`}</div>
              </Tooltip>
            }
          >
            <div>{page.trafficPercentage}% of traffic</div>
          </OverlayTrigger>
        </div>
      </div>

      <div
        id="generator-container"
        style={{
          position: "relative",
          width: `${heatmapPageWidth}px`,
          minHeight: "2px",
          height: `${canvasHeight}px`,
        }}
      >
        {imgSrcSized ? (
          <img
            className="no-select no-drag"
            src={imgSrcSized}
            ref={imgRef}
            width={`${heatmapPageWidth}px`}
            alt="Story Page"
          />
        ) : (
          <div
            style={{
              width: `${heatmapPageWidth}px`,
              height: "1000px",
              background: "white",
            }}
          ></div>
        )}
        {![2, 3].includes(storyState) && (
          <div
            style={{
              minHeight: tallestPageHeight ? `${tallestPageHeight}px` : "500px",
            }}
            className={`${
              i === 0 ? "" : "heatmap-overlay-line"
            } heatmap-overlay`}
          ></div>
        )}
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none",
            zIndex: 11,
          }}
        />
      </div>
    </div>
  );
};

export default StoryPage;
