import {
  deleteDocumentsByProjectId,
  startAnalysisMaestro,
  buildProjectPersonas,
  removeClusteringModelId,
  getAdminElementSummary,
  deleteNonLiveClicktrackingElements,
  getAdminProjectElementReport,
} from "../../helpers";
import CustomInput from "../CustomInput/CustomInput";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function AdminSpecialAnalysisContent() {
  const [pid, setPid] = useState("");
  const [insights, setInsights] = useState(false);
  const [elementize, setElementize] = useState(false);
  const [screenshot, setScreenshot] = useState(false);
  const [updateMRAID, setUpdateMRAID] = useState(false);
  const [specificUrl, setSpecificUrl] = useState("");

  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState([]);

  const [summaryReport, setSummaryReport] = useState([]);

  return (
    <div style={{ height: "100vh", flexGrow: 1 }}>
      <div className="mb-10">
        <h1>Admin controls</h1>
      </div>
      <hr></hr>

      <div className="mt-50 mb-50">
        <h2>Analysis controls</h2>
        <div className="d-flex">
          <div style={{ marginTop: "-8px" }}>
            <CustomInput
              placeholder="Project id"
              containerClassName="observation-text-input-container"
              inputClassNames="delete-width-input"
              className={pid === "" ? "active" : ""}
              valueState={pid}
              onChange={(e) => setPid(e.target.value)}
              value={pid}
            />
            <CustomInput
              placeholder="Specific urls to process"
              containerClassName="observation-text-input-container"
              inputClassNames="delete-width-input"
              className={specificUrl === "" ? "active" : ""}
              valueState={specificUrl}
              onChange={(e) => setSpecificUrl(e.target.value)}
              value={specificUrl}
            />
          </div>
          <div className="mr-16">
            <div class="mb-10">
              <button
                type="button"
                onClick={() => {
                  setInsights(!insights);
                }}
                className="behavior-toggle align-items-center fw-700 mr-10"
                style={{
                  backgroundColor: insights ? "#2A00FF" : "#707070",
                  float: "left",
                }}
              >
                <div
                  className={
                    insights
                      ? "behavior-toggle-circle-on"
                      : "behavior-toggle-circle-off"
                  }
                ></div>
              </button>
              Generate insights
            </div>
            <div class="mb-10">
              <button
                type="button"
                onClick={() => {
                  setElementize(!elementize);
                }}
                className="behavior-toggle align-items-center fw-700 mr-10"
                style={{
                  backgroundColor: elementize ? "#2A00FF" : "#707070",
                  float: "left",
                }}
              >
                <div
                  className={
                    elementize
                      ? "behavior-toggle-circle-on"
                      : "behavior-toggle-circle-off"
                  }
                ></div>
              </button>
              Generate Elements
            </div>
            <div class="mb-10">
              <button
                type="button"
                onClick={() => {
                  setScreenshot(!screenshot);
                }}
                className="behavior-toggle align-items-center fw-700 mr-10"
                style={{
                  backgroundColor: screenshot ? "#2A00FF" : "#707070",
                  float: "left",
                }}
              >
                <div
                  className={
                    screenshot
                      ? "behavior-toggle-circle-on"
                      : "behavior-toggle-circle-off"
                  }
                ></div>
              </button>
              Generate Screenshots
            </div>
            <div class="mb-10">
              <button
                type="button"
                onClick={() => {
                  setUpdateMRAID(!updateMRAID);
                }}
                className="behavior-toggle align-items-center fw-700 mr-10"
                style={{
                  backgroundColor: updateMRAID ? "#2A00FF" : "#707070",
                  float: "left",
                }}
              >
                <div
                  className={
                    updateMRAID
                      ? "behavior-toggle-circle-on"
                      : "behavior-toggle-circle-off"
                  }
                ></div>
              </button>
              Update MRAID
            </div>
          </div>
          <div>
            <button
              className="btn-primary"
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to start the analysis?")
                ) {
                  const data = {
                    projectId: pid,
                    skipAnalysis: !insights,
                    skipScreenshot: !screenshot,
                    skipElementize: !elementize,
                    skipMRAID: true,
                    specificUrl: specificUrl === "" ? false : specificUrl,
                  };
                  startAnalysisMaestro(data);
                }
              }}
            >
              Analyze
            </button>
            <button
              className="btn-primary"
              style={{ marginLeft: "10px" }}
              onClick={async () => {
                if (
                  window.confirm(
                    "Are you sure you want to build the persona for the project?",
                  )
                ) {
                  if (!pid) {
                    alert("Error: Project ID is required.");
                    return;
                  }

                  try {
                    const result = await buildProjectPersonas({
                      projectId: pid,
                    });
                    if (result) {
                      toast.success("Persona build in progress");
                    }
                  } catch (error) {
                    console.error("Error building personas: ", error);
                  }
                }
              }}
            >
              Build Persona
            </button>

            <div className="d-flex mt-16">
              <button
                className="btn-secondary mr-8"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete screenshots?",
                    )
                  ) {
                    const deleteScreenshotsPromise = deleteDocumentsByProjectId(
                      pid,
                      "projectScreenshots",
                    );

                    toast.promise(deleteScreenshotsPromise, {
                      pending: "Deleting screenshots...",
                      success: "Screenshots deleted successfully!",
                      error: "Error deleting screenshots",
                    });
                  }
                }}
              >
                Delete ALL screenshots
              </button>

              <button
                className="btn-secondary mr-8"
                onClick={async () => {
                  if (
                    window.confirm("Are you sure you want to delete elements?")
                  ) {
                    if (!pid) {
                      alert("Error: Project ID is required.");
                      return;
                    }
                    const deleteElementsPromise = (async () => {
                      await deleteDocumentsByProjectId(pid, "heatmapElements");
                      await deleteNonLiveClicktrackingElements(pid);
                    })();

                    toast.promise(deleteElementsPromise, {
                      pending: "Deleting elements...",
                      success: "Elements deleted successfully!",
                      error: "Error deleting elements",
                    });
                  }
                }}
              >
                Delete ALL elements
              </button>

              <button
                className="btn-secondary"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you want to delete elements?")
                  ) {
                    if (!pid) {
                      alert("Error: Project ID is required.");
                      return;
                    }
                    deleteDocumentsByProjectId(pid, "personas");
                    deleteDocumentsByProjectId(pid, "personaVisitors");
                    deleteDocumentsByProjectId(pid, "personaHistorical");
                    removeClusteringModelId(pid);
                  }
                }}
              >
                Delete persona data
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>

      <div className="mt-50 mb-50">
        <h2>Clicktracking summary report (expensive to query)</h2>
        <div className="d-flex">
          <div style={{ marginTop: "-8px" }}>
            <CustomInput
              placeholder="Project id"
              containerClassName="observation-text-input-container"
              inputClassNames="delete-width-input"
              className={pid === "" ? "active" : ""}
              valueState={pid}
              onChange={(e) => setPid(e.target.value)}
              value={pid}
            />
          </div>
          <div>
            <button
              onClick={async () => {
                setLoading(true);
                const summaryReport = await getAdminProjectElementReport(pid);

                let totalElementizeAndActivity = 0;
                let totalElementize = 0;
                let totalActivity = 0;

                const elementizeAndActivityMap = new Map();
                const elementizeMap = new Map();
                const activityMap = new Map();

                summaryReport.forEach((item) => {
                  if (item.isElementize && item.isActivity) {
                    totalElementizeAndActivity++;
                    elementizeAndActivityMap.set(
                      item.url,
                      (elementizeAndActivityMap.get(item.url) || 0) + 1,
                    );
                  }
                  if (item.isElementize) {
                    totalElementize++;
                    elementizeMap.set(
                      item.url,
                      (elementizeMap.get(item.url) || 0) + 1,
                    );
                  }
                  if (item.isActivity) {
                    totalActivity++;
                    activityMap.set(
                      item.url,
                      (activityMap.get(item.url) || 0) + 1,
                    );
                  }
                });

                const percentageCoverage =
                  (totalElementizeAndActivity / totalActivity) * 100;

                let adjustedTotalActivity = totalActivity;

                const activityKeys = Array.from(activityMap.keys());

                activityKeys.forEach((key) => {
                  const value = elementizeMap.get(key);
                  if (!value || value === 0) {
                    adjustedTotalActivity -= activityMap.get(key) || 0;
                  }
                });

                const adjustedPercentageCoverage =
                  (totalElementizeAndActivity / adjustedTotalActivity) * 100;

                let report = `
                  Percentage coverage (ALL ACTIVITY): ${percentageCoverage.toFixed(
                    2,
                  )}%
                  Percentage coverage (Pages that we process): ${adjustedPercentageCoverage.toFixed(
                    2,
                  )}%
                  Total number of elementize and activity at the same time: ${totalElementizeAndActivity}
                  Total number of elementize: ${totalElementize}
                  Total number of activity: ${totalActivity}
                `;

                report += "\n\nURL Summary:\n";
                const sortedUrls = Array.from(activityMap.keys()).sort(
                  (a, b) => {
                    const totalA =
                      activityMap.get(a) -
                      (elementizeAndActivityMap.get(a) || 0);
                    const totalB =
                      activityMap.get(b) -
                      (elementizeAndActivityMap.get(b) || 0);
                    return totalB - totalA;
                  },
                );

                sortedUrls.forEach((url) => {
                  const totalElementizeAndActivity =
                    elementizeAndActivityMap.get(url) || 0;
                  const totalElementize = elementizeMap.get(url) || 0;
                  const totalActivity = activityMap.get(url) || 0;
                  const urlPercentageCoverage =
                    (totalElementizeAndActivity / totalActivity) * 100;
                  if (totalActivity === 0) return;
                  report += `
                    URL: ${url}
                    Percentage coverage: ${urlPercentageCoverage.toFixed(2)}%
                    Total number of elementize and activity at the same time: ${totalElementizeAndActivity}
                    Total number of elementize: ${totalElementize}
                    Total number of activity: ${totalActivity}
                    Total number of misses: ${
                      totalActivity - totalElementizeAndActivity
                    }
                  `;
                });

                setSummaryReport(report);
                setLoading(false);
              }}
              disabled={!pid}
              className="btn-primary"
            >
              Generate project report
            </button>
          </div>
        </div>
        <div>
          {loading && <Spinner animation="border" />}
          <pre className="mt-32">{summaryReport}</pre>
        </div>
        <hr></hr>
      </div>

      <div className="mt-50 mb-50">
        <h2> Clicktracking deep inspection</h2>
        <div className="d-flex">
          <div style={{ marginTop: "-8px" }}>
            <CustomInput
              placeholder="Element URL"
              containerClassName="observation-text-input-container"
              inputClassNames="delete-width-input"
              className={url === "" ? "active" : ""}
              valueState={url}
              onChange={(e) => setUrl(e.target.value)}
              value={url}
            />
          </div>
          <div>
            <button
              onClick={async () => {
                setLoading(true);
                const elements = await getAdminElementSummary(url);
                setElements(elements);
                setLoading(false);
              }}
              disabled={!url}
              className="btn-primary"
            >
              Fetch elements
            </button>
          </div>
        </div>
        <div>
          {loading && <Spinner animation="border" />}
          <div className="mt-32">
            {elements.map((element, i) => (
              <button
                style={{
                  width: "max-content",
                  backgroundColor: element.isActivity
                    ? element.isElementize
                      ? "#90ee90" // light green
                      : "#add8e6" // light blue
                    : element.isElementize
                      ? "#ffffe0" // light yellow
                      : "initial",
                }}
                className="btn-octonary mr-8 mb-8"
                key={`${i}`}
                onClick={() => {
                  console.log(element);
                }}
              >
                {element.name}{" "}
                <i
                  onClick={() => window.open(element.url, "_blank")}
                  className="fa-regular fa-arrow-up-right-from-square"
                ></i>
              </button>
            ))}
          </div>
        </div>
      </div>
      <hr></hr>
    </div>
  );
}

export default function AdminSpecialAnalysis(props) {
  return (
    <>
      <AdminSpecialAnalysisContent />
    </>
  );
}
