import { useState } from "react";
import "./InsightImpact.css";
import {
  dateFirestoreToReact,
  formatCurrency,
  formatNumberSpaceSeparated,
  formatPercentagePlain,
  sumProperty,
} from "../../helpers";
import { useNavigate } from "react-router-dom";
import grayPfp from "../../assets/gray.PNG";
import RecordingModal from "../reusables/Recording/RecordingModal";

const InsightImpact = ({
  impact,
  impactGlobalSessions,
  selectedProject,
  impactResolver,
  createdAt,
  impactedSessions,
}) => {
  const navigate = useNavigate();

  const [recordingModalOpen, setRecordingModalOpen] = useState(false);

  if (!impactResolver || Number(impactGlobalSessions) === 0) {
    return;
  }

  const goalMap = impactResolver.goals;
  const personaMap = impactResolver.personas;
  const pfpMap = impactResolver.pfps;

  let winnings = (
    <button
      className="unstyled underline fc-blue"
      onClick={() => {
        navigate(
          `/manage-projects?pid=${selectedProject.value}&tab=Commercial value`,
        );
      }}
    >
      Set your client purchase value here
    </button>
  );

  if (selectedProject.purchaseValue && selectedProject.currency) {
    const totalPotentialGain =
      sumProperty(impact, "dropoffs") * selectedProject.purchaseValue;

    winnings = (
      <div>
        You could earn{" "}
        <span className="fw-700">
          up to{" "}
          {formatCurrency(totalPotentialGain, selectedProject.currency, "")} per
          week
        </span>{" "}
        by fixing this issue.
      </div>
    );
  }

  const goalConversions = [];

  const groupedImpact = new Map();
  impact.forEach((item) => {
    const goalId = item.goalId;
    if (!groupedImpact.has(goalId)) {
      groupedImpact.set(goalId, { conversions: 0, dropoffs: 0 });
    }
    const current = groupedImpact.get(goalId);
    groupedImpact.set(goalId, {
      conversions: Number(current.conversions) + Number(item.conversions),
      dropoffs: Number(current.dropoffs) + Number(item.dropoffs),
    });
  });

  groupedImpact.forEach((value, goalId) => {
    goalConversions.push({
      increase: formatPercentagePlain(value.dropoffs / impactGlobalSessions),
      name: goalMap.get(goalId) ?? "Unknown goal",
    });
  });

  const impactCards = impact;
  const invalidImpact = !impact || impact.every((c) => !c.dropoffs);

  if (
    !impactedSessions ||
    impactedSessions === 0 ||
    impact.length === 0 ||
    invalidImpact
  ) {
    return;
  }

  return (
    <div className="mb-32 mt-24 insight-impact-container">
      <div className="fc-black fw-900 fs-12 fvc mt-28 ml-24 fc-black2">
        <i className="fs-20 fa-regular fa-sack-dollar mr-8"></i>
        IMPACT & GAINS
      </div>
      <div className="ml-42 fs-16 fw-400 fc-black2 mt-25 mb-32">
        <div>
          This insight is concerning{" "}
          <span className="fw-700">{impactedSessions} visitors per week.</span>
        </div>
        {winnings}
        {goalConversions.map((stat, i) => (
          <div key={`increase-${i}`}>
            You could gain
            <span className="fw-700"> +{stat.increase} conversion rate </span>
            on {stat.name}.
          </div>
        ))}
      </div>
      <div className="insight-impact-persona-container">
        {impactCards.map((c, i) => {
          // Don't render the card if you can't optimize for this persona
          if (!c.dropoffs || c.dropoffs === 0) {
            return;
          }

          const pfp = (
            <div className="hc fw-700 mb-12 mt-32">
              {}
              <img
                style={{ width: "32px", height: "32px", borderRadius: "50%" }}
                alt="pfp"
                src={pfpMap?.get(c.personaId) ?? grayPfp}
              ></img>
            </div>
          );

          const name = (
            <div className="hc fw-700 mb-16">
              {personaMap?.get(c.personaId) ?? "Unknown Persona"}
            </div>
          );

          const sessions = (
            <div className="txt-center hc wrapword mb-10">
              <div>
                Concerns{" "}
                <span className="fw-700">
                  {" "}
                  {formatNumberSpaceSeparated(c.dropoffs)} sessions
                </span>
              </div>
            </div>
          );

          const earnings =
            selectedProject.purchaseValue && selectedProject.currency ? (
              <div className="txt-center hc wrapword mb-10">
                <div>
                  Up to
                  <span className="fw-700">
                    <> </>
                    {`+${formatCurrency(
                      c.dropoffs * selectedProject.purchaseValue,
                      selectedProject.currency,
                      "",
                    )} / week`}
                  </span>
                </div>
              </div>
            ) : null;

          const increase = (
            <div
              style={{ marginBottom: "146px" }}
              className="txt-center hc wrapword limit-2-lines"
            >
              <div>
                <span className="fw-700">
                  +{formatPercentagePlain(c.dropoffs / impactGlobalSessions)}{" "}
                  on{" "}
                </span>
                {goalMap.get(c.goalId) ?? "Unknown goal"}
              </div>
            </div>
          );

          const replay = c.replay ? (
            <div className="hc insight-impact-replay-btn">
              <button
                onClick={() => {
                  setRecordingModalOpen(true);
                }}
                className="btn-quaternary mb-24 insight-impact-btn fvc hc fw-700"
              >
                <i className="fa-regular fa-play mr-10 fs-20"></i>Play Recording
              </button>
            </div>
          ) : null;

          const heatmap = (
            <div className="hc insight-impact-heatmap-btn">
              <button
                onClick={() => {
                  const endDate = dateFirestoreToReact(createdAt);

                  const startDate = new Date(endDate);
                  startDate.setDate(startDate.getDate() - 2);

                  navigate("/funnels/view/", {
                    state: {
                      filters: {
                        personaIds: [c.personaId],
                        goalId: c.goalId,
                        startDate: startDate,
                        endDate: endDate,
                        days: 3,
                      },
                    },
                  });
                }}
                className="btn-quaternary mb-24 insight-impact-btn fvc hc fw-700"
              >
                <i className="fa-regular fa-fire-flame-curved mr-10 fs-20"></i>
                Show Heatmap
              </button>
            </div>
          );

          return (
            <div
              className="insight-impact-persona-card fw-500 fc-blue fs-14 fw-500"
              key={`card-${i}`}
            >
              {pfp}
              {name}
              {sessions}
              {earnings}
              {increase}
              {replay}
              {heatmap}
              {c.replay && recordingModalOpen && (
                <RecordingModal
                  isOpen={recordingModalOpen}
                  onRequestClose={() => {
                    setRecordingModalOpen(false);
                  }}
                  title={"View Impact Recording"}
                  replay={c.replay}
                  overlayClassName="modal-overlay-grey2"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InsightImpact;
