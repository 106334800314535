import { useState, useEffect, useRef, useMemo, useContext } from "react";
import Modal from "react-modal";
import "./InsightModal.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Spinner, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import {
  getUxErrorLabel,
  getUxErrorIcon,
  getLabel,
  updateInsightStatus,
  updateInsightsFastStatus,
  getInsightById,
  dateFirestoreToReact,
} from "../../helpers";
import { toast } from "react-toastify";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import MetricsChart from "../reusables/Chart/MetricsChart.tsx";
import { ChartRangeContext } from "../../contexts.js";
import { SelectedProjectContext } from "../../contexts";
import InsightImpact from "./InsightImpact.js";

const InsightModal = ({
  isOpen,
  onRequestClose,
  insights,
  initialInsight,
  onDeleteInsight,
  setInsights,
  setFilteredInsights,
  userRole,
  setSelectedInsight,
  isHeatmap,
  setIdsToMarkAsSeen,
  impactResolver,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [insightIdToDelete, setInsightIdToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [insightImgLoading, setInsightImgLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(isHeatmap ?? false);

  const selectedProject = useContext(SelectedProjectContext);
  const currentChart = 0;
  const numCharts = 1;
  const setCurrentChart = () => {};
  const chartInsights = [];
  const selectedChart = { metricType: "standard" };
  const rangeValue = 2;

  const leftRef = useRef(null);
  const rightRef = useRef(null);

  async function populateInsightData(id) {
    const insightData = await getInsightById(id);
    setSelectedInsight(insightData);
    setInsights([insightData]);
    setIsLoading(false);
  }

  useEffect(() => {
    if (isHeatmap && initialInsight?.id && insights?.length > 0) {
      populateInsightData(initialInsight.id);
    }
  }, [isHeatmap]);

  useEffect(() => {
    const index = insights.findIndex(
      (insight) => insight.id === initialInsight.id,
    );
    if (index !== -1) {
      setCurrentIndex(index);
    } else {
      setCurrentIndex(0);
    }
  }, [initialInsight, insights]);

  function processDescriptionGeneral(selectedInsight) {
    if (!selectedInsight || !selectedInsight.description) {
      return "";
    }

    let description = Array.isArray(selectedInsight.description)
      ? selectedInsight.description?.join("\n")
      : selectedInsight.description;

    if (selectedInsight?.title?.startsWith("[WCAG]")) {
      description = description.split("-!-")[0];
    }

    return description;
  }

  function processDescriptionWhole(selectedInsight) {
    if (!selectedInsight || !selectedInsight.description) {
      return "";
    }

    let description = Array.isArray(selectedInsight.description)
      ? selectedInsight.description.join("\n")
      : selectedInsight.description;

    return description;
  }

  function processRecommendationGeneral(selectedInsight) {
    if (!selectedInsight || !selectedInsight.recommendation) {
      return "";
    }

    let recommendation = Array.isArray(selectedInsight.recommendation)
      ? selectedInsight.recommendation.join("\n")
      : selectedInsight.recommendation;

    if (selectedInsight.title.startsWith("[WCAG]")) {
      recommendation = recommendation.split("-!-")[0];
    }

    return recommendation;
  }
  function processRecommendationWhole(selectedInsight) {
    if (!selectedInsight || !selectedInsight.recommendation) {
      return "";
    }

    let recommendation = Array.isArray(selectedInsight.recommendation)
      ? selectedInsight.recommendation.join("\n")
      : selectedInsight.recommendation;

    return recommendation;
  }

  const goToPreviousInsight = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const goToNextInsight = () => {
    if (currentIndex < insights.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const currentInsight = insights[currentIndex];

  useEffect(() => {
    if (isOpen && currentInsight && currentInsight?.screenshotUrl) {
      setInsightImgLoading(true);
      const img = new Image();
      img.onload = () => {
        setInsightImgLoading(false);
        setImageSrc(currentInsight.screenshotUrl);
      };
      img.onerror = () => {
        setInsightImgLoading(false);
        setImageSrc(null);
      };
      img.src = currentInsight.screenshotUrl;
    } else {
      setInsightImgLoading(false);
      setImageSrc(null);
    }
  }, [currentInsight, isOpen]);

  useEffect(() => {
    if (currentInsight.id && !currentInsight.seen && setIdsToMarkAsSeen) {
      setIdsToMarkAsSeen((prevIds) => [...prevIds, currentInsight.id]);
    }
  }, [currentInsight.id]);

  const screenshotSection = useMemo(
    () => (
      <div className="insight-screenshot-section">
        <div>
          {insightImgLoading && (
            <Spinner style={{ width: "50px", height: "50px" }} />
          )}

          {imageSrc && !insightImgLoading && (
            <img
              key={currentInsight?.id}
              alt="insight-screenshot"
              src={imageSrc}
              style={{
                maxWidth: "700px",
                border: "4.9px solid #000000",
                borderRadius: "9.8px",
                display: insightImgLoading ? "none" : "block",
              }}
            />
          )}
        </div>
      </div>
    ),
    [currentInsight?.id, insightImgLoading, currentInsight?.screenshotUrl],
  );

  const chartData = useMemo(() => {
    if (
      currentInsight &&
      currentInsight.ruleId === "aiMetrics" &&
      currentInsight.chartData &&
      currentInsight.chartData.values &&
      currentInsight.chartData.dates
    ) {
      let values = [...currentInsight.chartData.values];

      // Need to reverse values for older chart data
      const createdAt = new Date(
        dateFirestoreToReact(currentInsight.createdAt),
      );
      const cutoffDate = new Date("2024-12-15");

      if (createdAt < cutoffDate) {
        values.reverse();
      }

      return values.map((value, index) => {
        const date = new Date(currentInsight.chartData.dates[index]);
        date.setHours(date.getHours() + 6); // Add 6 hours to each date
        return {
          date: date,
          data: value,
        };
      });
    } else {
      return [];
    }
  }, [currentInsight]);

  useEffect(() => {
    if (currentIndex >= insights.length) {
      setCurrentIndex(insights.length - 1);
    }
  }, [insights, currentIndex]);

  const toggleInsightStatus = async (insight) => {
    const newStatus =
      insight.status === "complete" ? "in-progress" : "complete";
    const insightRef = doc(db, "insights", insight.id);

    // Update Firestore document
    await updateDoc(insightRef, {
      status: newStatus,
      completionDate: new Date(),
    });

    // Update the insights state
    setInsights((prevInsights) =>
      prevInsights.map((item) =>
        item.id === insight.id ? { ...item, status: newStatus } : item,
      ),
    );

    setFilteredInsights((prevInsights) =>
      prevInsights.map((item) =>
        item.id === insight.id ? { ...item, status: newStatus } : item,
      ),
    );

    // Show toast notification
    toast.success(`Insight marked as ${newStatus}!`);
  };

  const deleteInsight = async (insightId) => {
    try {
      const projectId = selectedProject?.value;
      if (!projectId) {
        toast.error("Invalid Project");
        return;
      }

      await updateInsightStatus(insightId, "deleted");

      await updateInsightsFastStatus(projectId, insightId);

      setInsights((prevInsights) =>
        prevInsights.map((insight) =>
          insight.id === insightId
            ? { ...insight, status: "deleted" }
            : insight,
        ),
      );

      setFilteredInsights((prevInsights) =>
        prevInsights.map((insight) =>
          insight.id === insightId
            ? { ...insight, status: "deleted" }
            : insight,
        ),
      );

      toast.success("Insight deleted!");
      onDeleteInsight(insightId);

      if (insights.length === 1) {
        onRequestClose();
      } else if (currentIndex >= insights.length - 1) {
        setCurrentIndex(currentIndex - 1);
      }
    } catch (error) {
      toast.error("Failed to delete insight");
      return;
    }
  };

  let actions = [
    {
      icon: "fa-regular fa-share",
      title: "Share the insight with your team",
      titleShort: "Share",
      description: "",
      buttonText: "Share",
      onClick: () => toast.warn("This feature is not available yet."),
    },
    {
      icon: "fa-regular fa-check",
      title: "Mark as solved",
      titleShort: "Solve",
      description:
        "As soon you mark an insight as solved, you can follow the metrics to see how it has impacted your product 📈",
      buttonText:
        currentInsight?.status == "complete"
          ? "Mark as unsolved"
          : "Mark as solved",
      onClick: () => toggleInsightStatus(currentInsight),
    },
    {
      icon: "fa-sharp fa-solid fa-vials",
      title: "Make an A/B test",
      titleShort: "A/B Test",
      description: "Test the recommendation before implementing it.",
      buttonText: "Start A/B Test",
      onClick: () => toast.warn("This feature is not available yet."),
    },
    {
      icon: "fa-brands fa-jira",
      title: "Create a Jira ticket",
      titleShort: "Jira",
      description: "",
      buttonText: "Create a ticket",
      onClick: () => toast.warn("This feature is not available yet."),
    },
    {
      icon: "fa-regular fa-pen-nib",
      title: "Ask advice to a UX/UI Expert",
      titleShort: "Help",
      description:
        "You have a question on an insight or need guidance? Ask our team directly.",
      buttonText: "Ask for advice",
      onClick: () => toast.warn("This feature is not available yet."),
    },
    {
      icon: "fa-regular fa-trash",
      title: "Delete or not relevant",
      titleShort: "Delete",
      description: "",
      buttonText: "Delete",
      onClick: () => {
        setInsightIdToDelete(currentInsight.id);
        setIsDeleteModalOpen(true);
      },
    },
  ];

  actions = actions.filter(
    (action) =>
      !["Start A/B Test", "Create a ticket", "Ask for advice"].includes(
        action.buttonText,
      ),
  );

  if (!userRole || userRole === "reader") {
    actions = actions.filter(
      (action) =>
        !["Delete", "Mark as solved", "Mark as unsolved"].includes(
          action.buttonText,
        ),
    );
  }

  const ActionItem = ({
    icon,
    title,
    description,
    buttonText,
    onClick,
    titleShort,
  }) => (
    <div className="action-item">
      <div className="action-header">
        <i className={`${icon} action-icon`}></i>
        <h4
          className="fw-bold hide-under-1380"
          style={{ margin: description ? "0 0 8px 0" : "0" }}
        >
          {title}
        </h4>
        <h4
          className="fw-bold d-none show-under-1380"
          style={{ margin: description ? "0 0 8px 0" : "0" }}
        >
          {titleShort}
        </h4>
      </div>
      {description && (
        <p
          className="modal-right-subtext hide-under-1380"
          style={{ marginBottom: "0px", maxWidth: "215px" }}
        >
          {description}
        </p>
      )}
      <button
        className="fw-700 btn-denary mb-2 action-button"
        onClick={onClick}
      >
        {buttonText}
      </button>
    </div>
  );

  const generateUrl = () => {
    if (
      currentInsight &&
      currentInsight.pageName &&
      selectedProject &&
      selectedProject.rootUrl
    ) {
      let pageName = currentInsight.pageName;

      // If pageName starts with www., http://, or https://, return pageName
      if (
        pageName.startsWith("www.") ||
        pageName.startsWith("http://") ||
        pageName.startsWith("https://")
      ) {
        return pageName;
      }

      let rootUrl = selectedProject.rootUrl.replace(/\/$/, "");
      const path = pageName ? "/" + pageName.replace(/^\//, "") : "";

      if (!rootUrl.startsWith("http://") && !rootUrl.startsWith("https://")) {
        rootUrl = "http://" + rootUrl;
      }

      return rootUrl + path;
    }
    return "#";
  };

  const goToPage = useMemo(
    () => (
      <div className="goToPage-wrapper">
        <div className="fs-16 fc-blue lh-25 w-100 d-flex align-items-center justify-content-start">
          <a
            href={generateUrl()}
            target="_blank"
            rel="noreferrer"
            className="mw-700 wrapword"
          >
            {/* <i
              className="fa-regular fa-arrow-up-right-from-square ms-1"
              style={{ marginRight: "10px" }}
            ></i> */}
            {generateUrl()}
          </a>
        </div>
      </div>
    ),
    [currentInsight?.id, generateUrl],
  );

  if (!currentInsight) return null;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Insight Details"
        className="insight-modal custom-modal"
        overlayClassName="insight-modal-overlay"
        appElement={document.getElementById("root")}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spinner style={{ width: "75px", height: "75px" }} />
          </div>
        ) : (
          <>
            <div className="insight-modal-content">
              <div className="modal-left" ref={leftRef}>
                <div className="modal-header">
                  <div className="align-items-center d-flex modal-header-top">
                    <div className="d-flex align-items-center">
                      <span className="insight-number">
                        Insight #{currentInsight.num}
                      </span>

                      {!isHeatmap && (
                        <>
                          <i
                            className="fa fa-angle-left icon-margin-right"
                            onClick={goToPreviousInsight}
                            style={{ cursor: "pointer" }}
                          ></i>
                          <i
                            className="fa fa-angle-right icon-margin-right"
                            onClick={goToNextInsight}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </>
                      )}
                    </div>
                  </div>

                  {currentInsight?.impactSeverity && (
                    <button className="high-impact-button">High Impact</button>
                  )}
                  <h4>{currentInsight.title}</h4>
                  {goToPage}

                  <p
                    style={{
                      width: "700px",
                      marginBottom: "24px",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {processDescriptionGeneral(currentInsight)}
                  </p>
                  {processDescriptionWhole(currentInsight)?.split("-!-")
                    ?.length > 1 && (
                    <div style={{ marginTop: "1em" }}>
                      {processDescriptionWhole(currentInsight)
                        .split("-!-")
                        .slice(1)
                        .map((point, index) => {
                          // Only render point if it has text
                          if (!point.trim()) {
                            return null;
                          }

                          return (
                            <div
                              key={index}
                              style={{
                                marginLeft: "2em",
                                marginTop: "0.5em",
                                position: "relative",
                              }}
                            >
                              <span
                                style={{
                                  position: "absolute",
                                  left: "-1em",
                                }}
                              >
                                •
                              </span>
                              {point}
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>

                {(currentInsight?.fixEase ||
                  currentInsight?.severity ||
                  currentInsight.uxErrorType?.length > 0) && (
                  <div
                    className="v6insights-ose-section"
                    style={{ marginBottom: "24px" }}
                  >
                    {currentInsight?.uxErrorType &&
                      currentInsight?.uxErrorType.length > 0 && (
                        <div
                          className={`observation-type ${
                            currentInsight.severity || currentInsight.fixEase
                              ? "has-border"
                              : ""
                          }`}
                        >
                          <div className="fs-14 fw-900 lh-245 text-color">
                            OBSERVATION TYPES
                          </div>
                          <div className="d-flex">
                            <div
                              className="v6insights-error-icons-modal"
                              style={{ marginLeft: "10px" }}
                            >
                              {currentInsight.uxErrorType?.map(
                                (error, index) => (
                                  <OverlayTrigger
                                    key={`top-${index}`}
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-top`}>
                                        {getUxErrorLabel(error.value)}
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className="vhc fvc"
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {getUxErrorIcon(error.value)}
                                    </div>
                                  </OverlayTrigger>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                    {currentInsight?.severity && (
                      <div className="severity-section">
                        <div className="fs-14 fw-900 lh-245 text-color">
                          SEVERITY
                        </div>
                        <div
                          className="fs-16 fw-500 fc-grey lh-2695 mt-11"
                          style={{ marginTop: "11px" }}
                        >
                          {getLabel(currentInsight.severity)}
                        </div>
                      </div>
                    )}

                    {currentInsight?.fixEase && (
                      <div className="fix-ease-section">
                        <div className="fs-14 fw-900 lh-245 text-color">
                          EASE TO FIX
                        </div>
                        <div
                          className="fs-16 fw-500 fc-grey lh-2695"
                          style={{ marginTop: "11px" }}
                        >
                          {getLabel(currentInsight.fixEase)}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {currentInsight.impact && currentInsight.impactedSessions && (
                  <InsightImpact
                    impact={currentInsight.impact}
                    impactGlobalSessions={currentInsight.impactGlobalSessions}
                    selectedProject={selectedProject}
                    impactResolver={impactResolver}
                    createdAt={currentInsight.createdAt}
                    impactedSessions={currentInsight.impactedSessions}
                  />
                )}

                {screenshotSection}

                {currentInsight?.ruleId === "aiMetrics" &&
                  chartData.length > 0 && (
                    <div className="chart-section">
                      <div className="fs-16 fw-900 lh-245 text-color">
                        Performance Metrics
                      </div>
                      <ChartRangeContext.Provider value={rangeValue}>
                        <MetricsChart
                          height={400}
                          currentChart={currentChart}
                          setCurrentChart={setCurrentChart}
                          numCharts={numCharts}
                          data={chartData}
                          chartInsights={chartInsights}
                          range={rangeValue}
                          selectedChart={selectedChart}
                          disableDataHover={true}
                        />
                      </ChartRangeContext.Provider>
                    </div>
                  )}

                {currentInsight?.element?.filter(
                  (insightDetail) => insightDetail.trim() !== "",
                ).length > 0 && (
                  <div className="insight-rule-card">
                    <div
                      className="d-flex align-items-center details-toggle"
                      onClick={() => setShowDetails(!showDetails)}
                    >
                      <i className="fa-regular fa-triangle-exclamation fs-20"></i>
                      <div
                        className="fs-12 fw-900 lh-245"
                        style={{ marginLeft: "10px" }}
                      >
                        DETAILS
                      </div>

                      <div
                        style={{ flexGrow: 1 }}
                        className="d-flex align-items-center justify-content-end chevron-icon"
                      >
                        {showDetails ? (
                          <FiChevronUp alt="chevron up" />
                        ) : (
                          <FiChevronDown alt="chevron down" />
                        )}
                      </div>
                    </div>

                    {showDetails && (
                      <div
                        className="mt-10"
                        style={{
                          marginTop: "10px",
                          width: "620px",
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                          display: showDetails ? "block" : "none",
                        }}
                      >
                        <p className="fc-grey fs-16 mb-2">
                          Copy the code directly to send it to the developers or
                          access the error directly on the website.
                        </p>
                        {currentInsight?.element
                          ?.filter(
                            (insightDetail) => insightDetail.trim() !== "",
                          )
                          .map((element, index) => (
                            <div
                              key={index}
                              className="code-snippet fs-16 bold mb-20"
                            >
                              <div>
                                {element
                                  ? element
                                      .replace(/</g, "&lt;")
                                      .replace(/>/g, "&gt;")
                                      .split(/(&lt;[\s\S]*?&gt;)/)
                                      .map((part, idx) =>
                                        /&lt;[\s\S]*?&gt;/.test(part) ? (
                                          <span
                                            key={idx}
                                            className="fc-green2 fs-16 "
                                          >
                                            {part
                                              .replace(/&lt;/g, "<")
                                              .replace(/&gt;/g, ">")}
                                          </span>
                                        ) : (
                                          <span
                                            className="fs-16 fc-white"
                                            key={idx}
                                          >
                                            {part}
                                          </span>
                                        ),
                                      )
                                  : "An error occurred fetching the code for this element"}
                              </div>
                              <div className="code-actions">
                                <i
                                  onClick={async () => {
                                    try {
                                      await navigator.clipboard.writeText(
                                        element,
                                      );
                                      toast.success("Copied to clipboard!");
                                    } catch (err) {
                                      toast.error(
                                        "Failed to copy text: " + err,
                                      );
                                    }
                                  }}
                                  className="fa-regular fs-20 fa-copy"
                                  style={{ marginLeft: "10px" }}
                                ></i>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                )}

                {currentInsight.recommendation &&
                  currentInsight.recommendation.length > 0 && (
                    <div
                      className="insight-rec-card recommendations-card"
                      style={{
                        marginTop: showDetails
                          ? "20px"
                          : currentInsight?.screenshotUrl
                            ? "37px"
                            : "20px",
                        paddingBottom: "0px",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <i className="fa-regular fa-lightbulb recommendations-icon"></i>
                        <div className="fw-900 fs-12">RECOMMENDATIONS</div>
                      </div>
                      <div
                        className="fs-16 fw-400 wrap recommendations-text"
                        style={
                          !currentInsight?.status ||
                          currentInsight?.status !== "complete"
                            ? { marginBottom: "28.94px" }
                            : {}
                        }
                      >
                        {processRecommendationGeneral(currentInsight)}

                        {processRecommendationWhole(currentInsight)?.split(
                          "-!-",
                        )?.length > 1 && (
                          <div style={{ marginTop: "1em" }}>
                            {processRecommendationWhole(currentInsight)
                              ?.split("-!-")
                              ?.slice(1)
                              .map((point, index) => {
                                // Only render point if it has text
                                if (!point.trim()) {
                                  return null;
                                }

                                return (
                                  <div
                                    key={index}
                                    style={{
                                      marginLeft: "2em",
                                      marginTop: "0.5em",
                                      position: "relative",
                                    }}
                                  >
                                    <span
                                      style={{
                                        position: "absolute",
                                        left: "-1em",
                                      }}
                                    >
                                      •
                                    </span>
                                    {point}
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                      {currentInsight?.status &&
                        currentInsight?.status === "complete" && (
                          <div
                            className="d-flex"
                            style={{
                              marginTop: "10px",
                              marginBottom: "28.94px",
                            }}
                          >
                            <div className="fs-16 fw-900 lh-245">
                              This insight has been completed.
                            </div>
                          </div>
                        )}
                    </div>
                  )}

                {currentInsight?.uxRule && (
                  <div
                    className="insight-rule-card not-die-stupid-card"
                    style={{ marginTop: "24px" }}
                  >
                    <div className="d-flex align-items-center">
                      <i className="fa-regular fa-lightbulb not-die-icon"></i>
                      <div className="fs-12 fw-900 lh-245">
                        TO NOT DIE STUPID
                      </div>
                    </div>
                    <div className="fs-16 fw-400 lh-2695 not-die-text">
                      {currentInsight.uxRule}
                    </div>
                  </div>
                )}
              </div>

              <div
                className="modal-right"
                ref={rightRef}
                style={{
                  backgroundColor: "var(--off-white-two)",
                  position: "relative",
                }}
              >
                <i
                  className="fa-solid fa-xmark close-icon"
                  onClick={onRequestClose}
                  style={{ cursor: "pointer", marginTop: "10px" }}
                ></i>
                <h4 style={{ marginTop: "65px", marginBottom: "50px" }}>
                  Actions
                </h4>
                {actions.map((action, index) => (
                  <div key={index}>
                    <ActionItem {...action} />
                    {index !== actions.length - 1 && <hr className="divider" />}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => {
          setIsDeleteModalOpen(false);
        }}
        contentLabel="Insight Delete Modal"
        className="insight-delete-modal modal-shape d-flex flex-column align-items-center justify-content-around"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgba(7, 21, 43, 0.7)",
            zIndex: 10,
          },
        }}
      >
        <h4 className="text-center">
          Are you sure you want to delete this insight?
        </h4>
        <div className="d-flex justify-content-center insight-delete-button-height">
          <Button
            type="button"
            className="btn-primary me-3"
            onClick={() => {
              deleteInsight(insightIdToDelete);
              setIsDeleteModalOpen(false);
            }}
          >
            Delete
          </Button>
          <Button
            type="button"
            className="btn-secondary"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default InsightModal;
