import { useEffect, useState, useRef } from "react";
import SankeyContainer from "../reusables/Sankey/SankeyContainer";
import { Spinner } from "react-bootstrap";
import {
  appendSegmentParamsToMatomoQuery,
  convertTimestampsToStringForDateMatomoQuery,
  CustomPaginator,
  errorLog,
  fetchPageInsightCounts,
  isValidMatomoProject,
} from "../../helpers";
import { getSankeyFromMatomo } from "../../helpers";

export default function Sankey(props) {
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("No data available");
  const [sankeyData, setSankeyData] = useState(null);
  const [max, setMax] = useState(0);
  const pageWidth = 395;
  const maxLinkWidth = 100;

  const [goal, setGoal] = useState(null);
  const [page, setPage] = useState(0);
  // const [pageHeights, setPageHeights] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageCoordinates, setPageCoordinates] = useState([]);

  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedLinks, setSelectedLinks] = useState([]);
  const [rsOptions, setRsOptions] = useState([]);
  const [lsOptions, setLsOptions] = useState([]);

  const [pageInsightCounts, setPageInsightCounts] = useState(null);
  const [zoom, setZoom] = useState(0.4);

  const abortControllerRef = useRef(null);
  const trackingNumberRef = useRef(0);
  const incrementTrackingNumber = () => {
    trackingNumberRef.current += 1;
    return trackingNumberRef.current;
  };

  const scrollToPage = (page) => {
    const elementId = `sankey`;
    const element = document.getElementById(elementId);
    if (element) {
      const scrollableParent = element.parentElement;
      const targetScrollLeft = page * pageWidth;
      scrollableParent.scrollTo({
        left: targetScrollLeft,
        behavior: "smooth",
      });
    }
  };

  function zoomIn() {
    if (zoom < 1.0) {
      setZoom(Math.round((zoom + 0.2) * 10) / 10);
    }
  }

  function zoomOut() {
    if (zoom > 0.2) {
      setZoom(Math.round((zoom - 0.2) * 10) / 10);
    }
  }

  //Change projects
  useEffect(() => {
    enableSidebar();

    setPageInsightCounts(null);
    fetchPageInsightCounts(props.selectedProject).then((data) => {
      setPageInsightCounts(data);
    });

    return () => {
      disableSidebar();
    };
  }, [props.selectedProject]);

  //Change filters
  useEffect(() => {
    if (
      !props.heatmapFunnelFilters.startDate ||
      !props.heatmapFunnelFilters.endDate ||
      !props.heatmapPrefsLoaded
    ) {
      return;
    }
    setLoading(true);
    abortRequest();
    clearSankeyData();
    if (!props.selectedProject) {
      handleError("Select a project to view data");
      setLoading(false);
      return;
    }

    setGoal({
      type: props.heatmapFunnelFilters.tag.type,
      name: props.heatmapFunnelFilters.tag.label,
      value: props.heatmapFunnelFilters.tag.value,
    });

    const trackingNumber = incrementTrackingNumber();
    calculateSankeyData(trackingNumber);
  }, [
    props.heatmapFunnelFilters,
    props.heatmapPrefsLoaded,
    props.selectedProject,
  ]);

  function handleError(message) {
    setErrorMsg(message);
    errorLog(message);
    clearSankeyData();
    setLoading(false);
    return;
  }

  function abortRequest() {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }

  function calculateSankeyData(trackingNumber) {
    function processLinksAndNodes(edges, nodes) {
      const maxEdges = 20;
      const adjacencyList = {};
      const visited = new Set();
      const stack = new Set();
      const toRemove = new Set();
      const linkedNodeIndices = new Set();

      edges.forEach((edge, index) => {
        const { source, target } = edge;
        if (!adjacencyList[source]) adjacencyList[source] = [];
        adjacencyList[source].push({ target, index });
      });

      function dfs(node) {
        if (stack.has(node)) return true;
        if (visited.has(node)) return false;

        visited.add(node);
        stack.add(node);

        if (adjacencyList[node]) {
          for (const { target, index } of adjacencyList[node]) {
            if (dfs(target)) {
              toRemove.add(index);
            } else {
              linkedNodeIndices.add(node);
              linkedNodeIndices.add(target);
            }
          }
        }

        stack.delete(node);
        return false;
      }

      edges.forEach((edge) => {
        if (!visited.has(edge.source)) {
          dfs(edge.source);
        }
      });

      // Remove circular links
      const edgesWithoutCircularLinks = edges.filter(
        (_, index) => !toRemove.has(index),
      );
      let filteredEdges = edgesWithoutCircularLinks
        .sort((a, b) => b.value - a.value)
        .slice(0, maxEdges);

      if (filteredEdges.length === 20) {
        filteredEdges = filteredEdges.filter((edge) => edge.value > 1);
      }

      // Filter out nodes with zero links
      const filteredNodes = nodes.filter((_, index) =>
        // linkedNodeIndices.has(index) ||
        filteredEdges.some(
          (edge) => edge.source === index || edge.target === index,
        ),
      );

      // Create a mapping from old indices to new indices
      const indexMapping = new Map();
      filteredNodes.forEach((_, newIndex) => {
        indexMapping.set(
          nodes.findIndex((node) => node === filteredNodes[newIndex]),
          newIndex,
        );
      });

      // Adjust the links to use the new indices
      const adjustedLinks = filteredEdges
        .filter(
          (link) =>
            indexMapping.has(link.source) && indexMapping.has(link.target),
        )
        .map((link) => ({
          source: indexMapping.get(link.source),
          target: indexMapping.get(link.target),
          value: link.value,
        }));

      return {
        nodes: filteredNodes,
        links: adjustedLinks,
        total: nodes.reduce((acc, node) => acc + node.hits, 0),
      };
    }
    if (props.selectedProject === null || !props.heatmapPrefsLoaded) {
      handleError("Loading..");
      return;
    }

    const validMatomo = isValidMatomoProject(props.selectedProject);

    if (!validMatomo) {
      handleError("This feature requires a valid analytics setup");
      return;
    }

    const dateRange = convertTimestampsToStringForDateMatomoQuery(
      props.heatmapFunnelFilters.startDate,
      props.heatmapFunnelFilters.endDate,
    );

    const paramsWithoutSegment = {
      siteId: props.selectedProject.matomoId,
      period: "range",
      date: dateRange,
      pathType: props.heatmapFunnelFilters.pathType,
      goal: {
        type: props.heatmapFunnelFilters.tag.type,
        value: props.heatmapFunnelFilters.tag.value,
        label: props.heatmapFunnelFilters.tag.label,
      },
      personas: props.heatmapFunnelFilters.personas,
    };

    const params = appendSegmentParamsToMatomoQuery(
      paramsWithoutSegment,
      props,
    );

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;
    const { signal } = controller;

    if (dateRange === "NaN-NaN-NaN,NaN-NaN-NaN") return;

    getSankeyFromMatomo({ params: params, signal: signal })
      .then((res) => {
        if (res.status !== 200) {
          setLoading(false);
          return;
        }

        if (res.status === 200) {
          const data = {
            // total: getSankeyTotal(),//res.sankey.total,
            ...processLinksAndNodes(res.sankey.links, res.sankey.nodes),
          };
          const nodesWithDropoff = data.nodes.map((node, index) => {
            const outgoingLinks = data.links.filter(
              (link) => link.source === index,
            );
            const totalOutgoingValue = outgoingLinks.reduce(
              (sum, link) => sum + link.value,
              0,
            );
            const dropoff = node.hits - totalOutgoingValue;
            return {
              ...node,
              dropoff: dropoff > 0 && node.name !== goal.name ? dropoff : 0,
              hits: node.hits,
            };
          });
          const { roundedData, maxValue } = data.links.reduce(
            (acc, link) => {
              const roundedValue = Math.round(link.value);
              acc.roundedData.links.push({ ...link, value: roundedValue });
              if (roundedValue > acc.maxValue) {
                acc.maxValue = roundedValue;
              }
              return acc;
            },
            {
              roundedData: { ...data, nodes: nodesWithDropoff, links: [] },
              maxValue: 0,
            },
          );

          // If user made another request, ignore this result
          if (trackingNumberRef.current !== trackingNumber) {
            return;
          }

          if (
            !signal.aborted &&
            roundedData.nodes.length > 0 &&
            roundedData.links.length > 0
          ) {
            setSankeyData(roundedData);
            setMax(maxValue);
            setLoading(false);
            return;
          }

          if (
            (!signal.aborted && roundedData.nodes.length === 0) ||
            roundedData.links.length === 0
          ) {
            handleError("Not enough data to build diagram");
            return;
          }

          handleError("Error fetching data");
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          return;
        }
      })
      .finally(() => {
        abortControllerRef.current = null;
      });
  }

  function clearSankeyData() {
    setSankeyData(null);
    setSelectedNodes([]);
    setSelectedLinks([]);
    setRsOptions([]);
    setLsOptions([]);
    setPageCoordinates([]);
    setTotalPages(0);
  }

  function enableSidebar() {
    props.setIsFunnel(true);
    props.setHideFunnelFilters(true);
    props.setHeatmapOrFunnel("sankey");
  }

  function disableSidebar() {
    props.setIsFunnel(false);
    props.setHideFunnelFilters(false);
  }

  return (
    <div>
      <style>{`.main-parent { padding-right: 0px !important}}`}</style>
      <div
        className="d-flex w-100 no-select mb-50"
        style={{
          justifyContent: "space-between",
        }}
      >
        <div>
          <div className="fw-700 fs-24 lh-324 fc-black2 mb-10">
            Funnel analysis
          </div>
          <div className="fw-500 fs-16 lh-20 fc-grey">
            <div>Analyse funnels that interest you.</div>
            <div>Click to highlight path. Hover to see details.</div>
          </div>
        </div>
        <div style={{ marginRight: "32px" }}>
          <div
            style={{
              justifySelf: "flex-end",
            }}
          >
            {CustomPaginator(page, scrollToPage, totalPages - 1)}
          </div>
          <div
            className="fs-16 fc-black mt-16 fvc"
            style={{
              height: "min-content",
              justifySelf: "flex-end",
            }}
          >
            <i className="fa-solid fa-magnifying-glass mr-8"></i>
            <span className="mr-8">{Math.round(zoom * 100)}% </span>
            <i onClick={zoomOut} className="fa-solid fa-minus mr-8"></i>
            <i onClick={zoomIn} className="fa-solid fa-plus"></i>
          </div>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            height: "200px",
            width: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner style={{ height: "70px", width: "70px" }} />
        </div>
      ) : !sankeyData ? (
        <h3 style={{ marginTop: "32px" }}>{errorMsg}</h3>
      ) : (
        <SankeyContainer
          setPage={setPage}
          sankeyData={sankeyData}
          goal={goal}
          maxLinkWidth={maxLinkWidth}
          // Dropoff
          max={max}
          // Pagination
          setPageCoordinates={setPageCoordinates}
          setTotalPages={setTotalPages}
          pageWidth={pageWidth}
          // setPageHeights={setPageHeights}

          //Selection
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
          selectedLinks={selectedLinks}
          setSelectedLinks={setSelectedLinks}
          rsOptions={rsOptions}
          setRsOptions={setRsOptions}
          lsOptions={lsOptions}
          setLsOptions={setLsOptions}
          //Sidebar
          hideFunnelFilters={props.hideFunnelFilters}
          //Hover
          pageInsightCounts={pageInsightCounts}
          //Zoom
          zoom={zoom}
          setZoom={setZoom}
        />
      )}
    </div>
  );
}
