import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { auth, db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";

export function Invite({
  setUserInfo = { setUserInfo },
  setSelectedProject = { setSelectedProject },
  userInfo = { userInfo },
}) {
  const pid = useParams().pid;
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");

  useEffect(() => {
    const shouldLogout = auth.currentUser && userInfo && userInfo.role;
    if (shouldLogout) {
      setUserInfo({});
      setSelectedProject({});
      auth.signOut().then(() => {
        navigate("/invited/" + pid + "?email=" + email);
      });
    } else {
      navigate("/invited/" + pid + "?email=" + email);
    }
  }, [auth]);

  return (
    <div className="fullscreen-center">
      <Spinner style={{ height: "70px", width: "70px" }} />
    </div>
  );
}
