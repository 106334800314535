import { useState, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import {
  getStoryPageChoices,
  CustomPaginator,
  convertTimestampsToStringForDateMatomoQuery,
  appendSegmentParamsToMatomoQuery,
  registerClickGoal,
  getGeneratedStory,
} from "../../helpers";
import { useDraggable } from "react-use-draggable-scroll";
import { BsXCircle } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import StoryGenerator from "./StoryGenerator";
import StorySelector from "./StorySelector";
import StoryPage from "./StoryPage";
import LazyLoad from "react-lazyload";
import config from "../../config";
import Modal from "react-modal";
import CustomInput from "../CustomInput/CustomInput";
import PopperTooltip from "../reusables/PopperTooltip/PopperTooltip";
import "./Story.css";
import StoryDisplay from "./StoryDisplay";
export default function Story({
  filters,
  selectedProject,
  storyState,
  setStoryState,
  setTagOptions,
  setDisabledFilters,
  hideFunnelFilters,
}) {
  const heatmapPageWidth = config.HEATMAP_PAGE_WIDTH;
  const storyPageWidth = heatmapPageWidth + config.STORY_PAGE_OFFSET;

  // Goal registration
  const [modalShow, setModalShow] = useState(false);
  const [goalName, setGoalName] = useState("");
  const [selectedElement, setSelectedElement] = useState(null);
  const [tooltipContent, setTooltipContent] = useState(<>Empty tooltip</>);
  const [tooltipCoords, setTooltipCoords] = useState({
    x: 0,
    y: 0,
    visible: false,
  });

  // Stage 2
  const [storyStartPage, setStoryStartPage] = useState(null);

  // Stage 3
  const [story, setStory] = useState(null);

  // Stage 4
  const [selectedStory, setSelectedStory] = useState(null);
  const [minHeight, setMinHeight] = useState(null);

  // Workflow consistency
  const trackingNumberRef = useRef(0);
  const incrementTrackingNumber = () => {
    trackingNumberRef.current += 1;
    return trackingNumberRef.current;
  };

  // View consistency
  const [savedScroll, setSavedScroll] = useState(null);

  // etc
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(null);
  const ref = useRef(null);
  const { events } = useDraggable(ref, {
    isMounted: true,
  });

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const scrollToPage = (page) => {
    const elementId = `heatmap${page}`;
    const element = document.getElementById(elementId);
    const container = document.getElementById("story-scroll-container");
    if (element && container) {
      const targetScrollLeft = element.offsetLeft - container.offsetLeft;
      container.scrollTo({
        left: storyState === 4 ? element.offsetLeft : targetScrollLeft,
        behavior: "smooth",
      });
    }
  };

  function resetTooltip() {
    setTooltipCoords({ x: 0, y: 0, visible: false });
  }

  function calculateCurrentPage(scrollLeft) {
    return Math.floor(
      (scrollLeft + config.HEATMAP_OFFSET) /
        (storyState === 1 ? storyPageWidth : heatmapPageWidth),
    );
  }

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current && ref.current.scrollLeft) {
        const currentPageIndex = calculateCurrentPage(ref.current.scrollLeft);
        setPage(currentPageIndex);
      }
    };

    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      resetTooltip();
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (event.target.tagName.toLowerCase() !== "button") {
        setMouseX(event.pageX);
        setMouseY(event.pageY);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      setDisabledFilters({
        disabled: [],
        hidden: [],
      });
    };
  }, []);

  function getDeviceType(filters) {
    return filters.phone
      ? "phone"
      : filters.desktop
        ? "desktop"
        : filters.tablet
          ? "tablet"
          : "error";
  }

  async function fetchPages() {
    const dateRange = convertTimestampsToStringForDateMatomoQuery(
      filters.startDate,
      filters.endDate,
    );

    const props = { heatmapFunnelFilters: filters };

    const paramsWithoutSegment = {
      date: dateRange,
      period: "range",
    };

    const params = appendSegmentParamsToMatomoQuery(
      paramsWithoutSegment,
      props,
    );

    const deviceType = getDeviceType(filters);

    const pages = await getStoryPageChoices({
      projectId: selectedProject.value,
      date: params.date,
      period: params.period,
      segment: params.segment,
      device: deviceType,
    });

    setPages(pages);
    setTotalPages(pages.length);
    setLoading(false);
  }

  async function generateStory() {
    const dateRange = convertTimestampsToStringForDateMatomoQuery(
      filters.startDate,
      filters.endDate,
    );

    const props = { heatmapFunnelFilters: filters };

    const paramsWithoutSegment = {
      date: dateRange,
      period: "range",
    };

    const params = appendSegmentParamsToMatomoQuery(
      paramsWithoutSegment,
      props,
    );

    const story = await getGeneratedStory({
      siteId: selectedProject.matomoId,
      projectId: selectedProject.value,
      elementId: storyStartPage.elements[0].id,
      date: params.date,
      segment: params.segment,
      personaIds: [],
      deviceType,
    });
    return story;
  }

  async function loadStoryData(trackingNumber) {
    const story = await generateStory();
    if (trackingNumberRef.current === trackingNumber) {
      setStory(story);
      setStoryState(3);
    }
  }

  useEffect(() => {
    if (!storyState) {
      setStory(null);
      return;
    }

    if (storyState === 1) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setStory(null);
      if (!pages || pages.length === 0) {
        fetchPages();
      }
      setDisabledFilters({
        disabled: ["Behaviors"],
        hidden: ["Goals", "Personas"],
      });
    }

    if (storyState === 2) {
      const trackingNumber = incrementTrackingNumber();
      setStory(null);
      setDisabledFilters({
        disabled: ["Behaviors", "Devices", "Segments", "Date"],
        hidden: ["Goals", "Personas"],
      });
      setTotalPages(0);
      setPage(0);
      loadStoryData(trackingNumber);
    }

    if (storyState === 4) {
      setStory(null);
      setDisabledFilters({
        disabled: ["Behaviors", "Devices", "Segments", "Date"],
        hidden: ["Goals", "Personas"],
      });
    }
  }, [storyState]);

  useEffect(() => {
    if (filters.startDate && filters.endDate && storyState === 1) {
      setLoading(true);
      resetTooltip();
      setTotalPages(0);
      fetchPages();
    }
  }, [
    filters.startDate,
    filters.endDate,
    filters.country,
    filters.phone,
    filters.desktop,
    filters.tablet,
    filters.unknownDevice,
    filters.duration,
    filters.localTime,
    filters.browser,
    filters.referrer,
    filters.deviceBrand,
    filters.resolution,
    filters.formUse,
    filters.city,
  ]);

  const deviceType = getDeviceType(filters);
  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={modalShow}
        onRequestClose={() => {
          setModalShow(false);
        }}
        contentLabel="Register a goal"
        className="delete-confirm-modal custom-modal modal-shape2"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgb(42, 0, 255, 0.7)",
            backdropFilter: "blur(8px)",
            zIndex: 1000,
          },
        }}
      >
        <div>
          <button
            className="d-flex justify-content-center align-items-center delete-confirm-x-btn"
            onClick={() => setModalShow(false)}
          >
            <BsXCircle alt="X button" size="32px" color="#0F0C22" />
          </button>
        </div>
        <div className="text-center mt-3">
          <h3 className="mb-4 mp-ellipsis-text">Name your goal.</h3>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <CustomInput
            placeholder="Goal Name"
            containerClassName="observation-text-input-container delete-width-input"
            inputClassNames="delete-width-input"
            className={goalName === "" ? "active" : ""}
            valueState={goalName}
            onChange={(e) => setGoalName(e.target.value)}
            value={goalName}
          />
        </div>
        <div className="row justify-content-center d-flex align-items-center">
          <button
            className="delete-confirm-btn btn-primary mb-5"
            onClick={async () => {
              await registerClickGoal(
                goalName,
                "click",
                selectedElement.id,
                selectedProject.value,
              ).then((res) => {
                if (res) {
                  setTagOptions((prev) => [
                    ...prev,
                    {
                      value: selectedElement.id,
                      label: goalName,
                      type: "click",
                    },
                  ]);
                  toast.success("Goal registered");
                } else {
                  toast.error("Error registering goal");
                }
                setModalShow(false);
              });
            }}
            disabled={!goalName}
          >
            <span style={{ color: "white" }}>Save</span>
          </button>
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          userSelect: "none",
        }}
      >
        <button
          onClick={() => {
            setStoryState(1);
            setTotalPages(pages.length);
            incrementTrackingNumber();
            setPage(0);
          }}
          className={`btn-quaternary fw-700 ${
            storyState === 1 ? "invisible" : ""
          }`}
        >
          Select another page
        </button>
        {CustomPaginator(page, scrollToPage, totalPages)}
      </div>

      <div
        ref={ref}
        id="story-scroll-container"
        {...events}
        className="no-select hide-scrollbar heatmap-container flex overflow-x-scroll"
        style={{
          paddingBottom: "100px",
          width: `calc(100% + ${config.HEATMAP_SCROLL_OFFSET}px)`,
          minHeight: `${minHeight ?? 1000}px`,
        }}
      >
        <PopperTooltip
          coordinates={tooltipCoords}
          tooltip={tooltipContent}
          containerRef={ref}
          popperClassName=""
        />
        {loading ? (
          <div className="mb-32">
            <Spinner style={{ height: "44px", width: "44px" }} />
          </div>
        ) : storyState === 1 ? (
          <>
            {pages && pages.length > 0 ? (
              <>
                {pages.map((page, i) => (
                  <LazyLoad
                    key={i}
                    scrollContainer="#story-scroll-container"
                    overflow={true}
                  >
                    <StoryPage
                      pageCount={1}
                      page={page}
                      i={i}
                      deviceType={deviceType}
                      containerRef={ref}
                      mouseX={mouseX}
                      mouseY={mouseY}
                      blockMouse={modalShow}
                      setModalShow={setModalShow}
                      setSelectedElement={setSelectedElement}
                      setTooltipCoords={setTooltipCoords}
                      setTooltipContent={setTooltipContent}
                      setGoalName={setGoalName}
                      setStoryState={setStoryState}
                      setStoryStartPage={setStoryStartPage}
                      storyState={storyState}
                      setSavedScroll={setSavedScroll}
                    />
                  </LazyLoad>
                ))}
              </>
            ) : (
              <h3
                style={{
                  marginRight: `-${heatmapPageWidth}px`,
                }}
              >
                No data found with your selected filters
              </h3>
            )}
          </>
        ) : storyState === 2 && storyStartPage ? (
          <StoryGenerator
            page={storyStartPage}
            deviceType={deviceType}
            mouseX={mouseX}
            mouseY={mouseY}
            storyState={storyState}
            setStoryState={setStoryState}
            setSelectedElement={setSelectedElement}
            setTooltipCoords={setTooltipCoords}
            setTooltipContent={setTooltipContent}
            setModalShow={setModalShow}
            savedScroll={savedScroll}
            setSavedScroll={setSavedScroll}
          />
        ) : storyState === 3 && story ? (
          <StorySelector
            page={storyStartPage}
            deviceType={deviceType}
            mouseX={mouseX}
            mouseY={mouseY}
            storyState={storyState}
            setStoryState={setStoryState}
            setSelectedElement={setSelectedElement}
            story={story}
            setSelectedStory={setSelectedStory}
            hideFunnelFilters={hideFunnelFilters}
            setMinHeight={setMinHeight}
          />
        ) : storyState === 4 && selectedStory ? (
          <StoryDisplay
            deviceType={deviceType}
            mouseX={mouseX}
            mouseY={mouseY}
            setStoryState={setStoryState}
            storyState={storyState}
            selectedStory={selectedStory}
            hideFunnelFilters={hideFunnelFilters}
            setTotalPages={setTotalPages}
          />
        ) : (
          <>Invalid</>
        )}
      </div>
    </>
  );
}
