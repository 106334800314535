import React, { useEffect, useRef } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { computePosition, flip } from "@floating-ui/dom";

import "./PopperTooltip.css";

const PopperTooltip = ({
  tooltip = "",
  coordinates = { x: 0, y: 0, visible: false },
  containerRef = null,
  popperClassName = "heatmap-popper",
  hideOnClick = true,
  hideOnScroll = true,
  hideOnMouseAway = true,
  mouseDistanceLimit = 100,
  type = "coordinate",
  hoveredId = null,
}) => {
  const tooltipRef = useRef(null);

  const {
    getTooltipProps,
    getArrowProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: "manual",
    visible: coordinates.visible,
  });

  useEffect(() => {
    function handleMouseMove(event) {
      if (tooltipRef.current) {
        const rect = tooltipRef.current.getBoundingClientRect();
        const distanceX = Math.max(
          rect.left - event.clientX,
          event.clientX - rect.right,
          0,
        );
        const distanceY = Math.max(
          rect.top - event.clientY,
          event.clientY - rect.bottom,
          0,
        );
        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

        if (distance > mouseDistanceLimit) {
          coordinates.visible = false;
        }
      }
    }

    if (hideOnMouseAway) {
      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (hideOnMouseAway) {
        document.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, [coordinates, hideOnMouseAway]);

  useEffect(() => {
    if (!containerRef) {
      return;
    }

    if (tooltipRef.current && containerRef.current) {
      if (type === "coordinate") {
        let { x, y } = coordinates;
        const ref = containerRef.current;
        const adjX = x - ref.scrollLeft + ref.offsetLeft;
        const adjY = y - ref.scrollTop + ref.offsetTop;

        tooltipRef.current.style.position = "absolute";
        tooltipRef.current.style.left = `${adjX}px`;
        tooltipRef.current.style.top = `${adjY}px`;
      }
    }

    function hideTooltip() {
      coordinates.visible = false;
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", hideTooltip);
        containerRef.current.removeEventListener("click", hideTooltip);
      }
    }

    if (containerRef.current && hideOnScroll) {
      containerRef.current.addEventListener("scroll", hideTooltip);
    }

    if (containerRef.current && hideOnClick) {
      containerRef.current.addEventListener("click", hideTooltip);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", hideTooltip);
        containerRef.current.removeEventListener("click", hideTooltip);
      }
    };
  }, [coordinates, containerRef, hideOnScroll, hideOnClick]);

  if (type === "id" && hoveredId) {
    const reference = document.getElementById(hoveredId);
    const floating = document.getElementById("sankey-tooltip");

    if (!reference) {
      return;
    }

    computePosition(reference, floating, {
      placement: "bottom-start",
      middleware: [flip()],
    })
      .then(({ x, y }) => {
        Object.assign(floating.style, {
          top: `${y}px`,
          left: `${x + 180}px`,
        });
      })
      .catch((e) => {});
  }

  return (
    <>
      <div ref={setTriggerRef} style={{ display: "none" }} />

      {visible && (
        <div
          id="sankey-tooltip"
          ref={(node) => {
            setTooltipRef(node);
            tooltipRef.current = node;
          }}
          {...getTooltipProps({
            className: `popper-tooltip-container ${popperClassName}`,
          })}
        >
          <div {...getArrowProps()} />
          {tooltip || "Tooltip content missing"}
        </div>
      )}
    </>
  );
};

export default PopperTooltip;
