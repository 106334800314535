import MainLayout from "../MainLayout";
import { auth, db } from "../../firebase";
import { useEffect, useState } from "react";
import { UserInfoContext } from "../../contexts";
import "./Authorizations.css";
import "../UxChecklist/UxChecklist.css";
import { FiArrowLeft, FiPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  CustomOverlay,
  getClientOrganizations,
  getMemberOrganizations,
  getOrganizationMembers,
  getOtherUserUid,
  getUserInformation,
  handleNestedStateChange,
  handleRemoveNestedState,
  mirrorMembershipInOrganization,
  sendInviteEmail,
  updateOrDeleteOrgMember,
} from "../../helpers";
import {
  Spinner,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal as BootModal,
} from "react-bootstrap";
import {
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import grayPfp from "../../assets/small-icon.png";
import { BsXCircle } from "react-icons/bs";
import CustomInput from "../CustomInput/CustomInput";
import Modal from "react-modal";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

function AuthorizationsContent({
  setSavingToDb,
  savingToDb,
  setNewUserModalOpen,
  setSelectedOrganization,
  userInfo,
  organizations,
  setOrganizations,
  isLoading,
  setIsLoading,
}) {
  const [loadingNewOrganizations, setLoadingNewOrganizations] = useState(false);
  const [openRevokeAccess, setOpenRevokeAccess] = useState(false);
  const [revokeAccessData, setRevokeAccessData] = useState(null);

  function handleOrgStateChange(value, field, i) {
    setOrganizations((prevList) =>
      prevList.map((states, j) => {
        if (i === j) {
          return {
            ...states,
            [field]: value,
          };
        }
        return states;
      }),
    );
  }

  const handleUserRolechange = async (orgMemberId, role, i, j, orgId) => {
    function complete(result, action) {
      setLoadingNewOrganizations(false);
      setSavingToDb(false);
      if (result) {
        toast.success(`User ${action}!`);
        return;
      }
      toast.error(`Error, not ${action}.`);
      return;
    }

    try {
      const result = await updateOrDeleteOrgMember(orgMemberId, role, orgId);
      if (result) {
        if (role === "Revoke access") {
          handleRemoveNestedState("workspace", i, j, setOrganizations);
          return complete(result, "revoked");
        } else {
          handleNestedStateChange(
            role,
            "workspace",
            "role",
            i,
            j,
            setOrganizations,
          );
          return complete(result, "role updated");
        }
      }
    } catch (e) {
      if (role === "Revoke access") {
        return complete(false, "revoked");
      }
      return complete(false, "updated");
    }
  };

  const roleOptions = [
    {
      label: "Owner",
      value: "owner",
      description: "",
      separator: false,
      isSelected: false,
      icon: false,
    },
    {
      label: "Editor",
      value: "editor",
      description: "Editor can add metrics to the dashboard, consult reports.",
      separator: false,
      isSelected: false,
      icon: false,
    },
    {
      label: "Reader",
      value: "reader",
      description: "Reader can just read reports and see analytics.",
      separator: false,
      isSelected: false,
      icon: false,
    },

    {
      label: "Revoke access",
      value: "Revoke access",
      description: "",
      separator: true,
      isSelected: false,
      icon: <i className="fa-regular fa-book-skull"></i>,
    },
  ];

  return (
    <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
      <div className="profile-main-content">
        <div style={{ marginLeft: "50.36px" }}>
          <div style={{ marginTop: "35.5px" }}>
            <Link to={"/"}>
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                }}
                className="fs-14 fw-700 fc-black"
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </Link>
            <div
              style={{ height: "24px", marginTop: "61.55px" }}
              className="fs-24 fw-700 lh-243 "
            >
              Authorizations
            </div>
          </div>

          <div
            style={{
              height: "24px",
              marginTop: "68.78px",
            }}
            className="fs-18 fw-700"
          >
            Invite people to join your organizations
          </div>
          <div
            style={{
              maxWidth: "651.64px",
              marginTop: "12px",
            }}
          >
            <div className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023">
              <p>
                Every person you invite can create an account and access the
                projects in that organization. You can manage people and access
                here.
              </p>
            </div>
          </div>

          {isLoading || !organizations ? (
            <>
              <Spinner
                style={{
                  marginTop: "25px",
                  width: "75px",
                  height: "75px",
                }}
              />
            </>
          ) : organizations?.length === 0 ? (
            <h2 className="mt-4">You are not part of any organizations</h2>
          ) : (
            <div style={{ marginTop: "50px" }}>
              {organizations?.map((p, i) => (
                <div key={i}>
                  <div
                    className="row"
                    style={{
                      margin: "0",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      className="fw-700 fs-18 lh-243"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "70%",
                        flexWrap: "nowrap",
                        overflow: "auto",
                        padding: "0px",
                        height: "38px",
                      }}
                    >
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>{p.name}</Tooltip>}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            height: "38px",
                          }}
                        >
                          {p.name}
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      {p?.workspace?.map((u, j) => (
                        <div key={j} className="darker-on-hover">
                          {u.role !== "Revoke access" && (
                            <>
                              <div
                                style={{
                                  height: "81px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: "16px",
                                  paddingBottom: "16px",
                                  paddingLeft: "20.52px",
                                }}
                              >
                                <div
                                  className="fw-500 fs-18 lh-24"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <img
                                    src={u.pfp ?? grayPfp}
                                    alt="profile picture"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "100%",
                                      marginRight: "18.29px",
                                      flex: "0 0 40px",
                                    }}
                                  />
                                  <div
                                    className="col fw-700 charlimit-no-width mr-8"
                                    style={{
                                      width: "30%",
                                      minWidth: "150px",
                                      maxWidth: "200px",
                                    }}
                                  >
                                    <CustomOverlay
                                      text={u.name ?? u.firstName ?? "No name"}
                                    ></CustomOverlay>
                                  </div>
                                  <div
                                    className="fc-grey charlimit-no-width mr-8"
                                    style={{
                                      width: "30%",
                                      minWidth: "150px",
                                      maxWidth: "300px",
                                    }}
                                  >
                                    <CustomOverlay
                                      text={u.email}
                                    ></CustomOverlay>
                                  </div>
                                  <div
                                    className="fc-grey charlimit-no-width"
                                    style={{
                                      width: "30%",
                                      minWidth: "150px",
                                      maxWidth: "200px",
                                    }}
                                  >
                                    <CustomOverlay
                                      text={u.jobTitle}
                                    ></CustomOverlay>
                                  </div>
                                  {/* roles */}
                                  <div
                                    className="align-items-right fw-700"
                                    style={{
                                      flex: "1 0 auto",
                                      width: "30%",
                                      marginRight: "50.12px",
                                      height: "44px",
                                      marginLeft: "auto",
                                      width: "130px",
                                      maxWidth: "130px",
                                    }}
                                  >
                                    {savingToDb || loadingNewOrganizations ? (
                                      <button
                                        style={{
                                          width: "130px",
                                          height: "48px",
                                          color: "#707070",
                                          backgroundColor: "#f7f7f7",
                                        }}
                                      >
                                        <Spinner
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <div
                                        onClick={() => {
                                          if (
                                            u?.you ||
                                            u?.mainClient ||
                                            !p.canEdit ||
                                            u?.uid ===
                                              "dL6KmhOS9lfmoDd1CeLG5lz9Kyv2"
                                          ) {
                                            const message = !p.canEdit
                                              ? "Only owners can manage roles"
                                              : u?.you
                                                ? "You cannot change your own role"
                                                : u?.uid ===
                                                    "dL6KmhOS9lfmoDd1CeLG5lz9Kyv2"
                                                  ? "You cannot change the role of the helper"
                                                  : "You cannot change the main client role";

                                            toast.warning(message);
                                          }
                                        }}
                                      >
                                        {p?.canEdit &&
                                        u?.uid !==
                                          "dL6KmhOS9lfmoDd1CeLG5lz9Kyv2" &&
                                        !u?.you &&
                                        !u?.mainClient ? (
                                          <CustomDropdown
                                            className="role-dropdown fs-16"
                                            options={roleOptions?.map((o) => ({
                                              ...o,
                                              isSelected:
                                                o.value === u.role ||
                                                o.label === u.role,
                                              icon:
                                                o.value === u.role ||
                                                o.label === u.role ? (
                                                  <i className="fa-regular fa-check"></i>
                                                ) : (
                                                  o.icon
                                                ),
                                            }))}
                                            customOptions
                                            onChange={async (option) => {
                                              setLoadingNewOrganizations(true);
                                              setSavingToDb(true);
                                              if (
                                                option.value === "Revoke access"
                                              ) {
                                                setOpenRevokeAccess(true);
                                                setRevokeAccessData({
                                                  orgName: p.name,
                                                  userName:
                                                    u?.firstName ??
                                                    u?.name ??
                                                    "",

                                                  value: option.value,
                                                  email: u.email,
                                                  orgIndex: i,
                                                  userIndex: j,
                                                  orgMemberId: u.orgMemberId,
                                                  orgId: p.id,
                                                });
                                              } else {
                                                handleUserRolechange(
                                                  u.orgMemberId,
                                                  option.value,
                                                  i,
                                                  j,
                                                  p.id,
                                                );
                                              }
                                            }}
                                            value={u.role}
                                            placeholder={
                                              u?.role?.charAt(0).toUpperCase() +
                                              u?.role?.slice(1)
                                            }
                                            styles={{
                                              menu: (provided, state) => ({
                                                ...provided,
                                                padding: 0,
                                                width: "300px",
                                                minHeight: "330px",
                                                overflow: "hidden",
                                                marginTop: "20px",
                                                transform: "translateX(-170px)",
                                              }),
                                              menuList: (provided, state) => ({
                                                minHeight: "330px",
                                              }),
                                              option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor:
                                                  state.isSelected
                                                    ? "var(--off-white-two)"
                                                    : "inherit",
                                                fontWeight: state.isSelected
                                                  ? "700"
                                                  : "500",
                                                borderRadius: 0,
                                                padding: "16px 24px",
                                              }),
                                            }}
                                            unstyled
                                            menuOpenState={
                                              !p.canEdit ||
                                              u.you ||
                                              u?.mainClient
                                                ? false
                                                : u.roleOpen
                                            }
                                            setMenuOpenState={(res) => {
                                              handleOrgStateChange(
                                                res,
                                                "roleOpen",
                                                i,
                                              );
                                            }}
                                          />
                                        ) : (
                                          <div className="role-dropdown-disabled fs-16">
                                            {u.role.charAt(0).toUpperCase() +
                                              u.role.slice(1)}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {j < p?.workspace.length - 1 && (
                                <hr
                                  style={{ margin: "0px", width: "95%" }}
                                ></hr>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  {p.canEdit && (
                    <button
                      onClick={() => {
                        setNewUserModalOpen(true);
                        setSelectedOrganization(p);
                      }}
                      style={{
                        marginTop: "20.2px",
                        height: "44px",
                        width: "160.5px",
                      }}
                      className="btn-primary d-flex align-items-center"
                      disabled={savingToDb || isLoading}
                    >
                      {savingToDb || isLoading ? (
                        <Spinner style={{ width: "25px", height: "25px" }} />
                      ) : (
                        <div className="mp-btn-fix">
                          <FiPlus
                            style={{
                              width: "17.5px",
                              height: "20px",
                              marginRight: "12px",
                            }}
                          />
                          Add a new user
                        </div>
                      )}
                    </button>
                  )}

                  <hr
                    className="row"
                    style={{
                      width: "95%",
                      marginTop: p?.canEdit ? "50.81px" : "20px",
                      marginBottom: "32px",
                    }}
                  ></hr>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <BootModal
        show={openRevokeAccess}
        onHide={() => {
          setOpenRevokeAccess(false);
          setLoadingNewOrganizations(false);
          setRevokeAccessData((prevState) => {
            const { orgName, userName } = prevState;
            return { orgName, userName };
          });
          setIsLoading(false);
          setSavingToDb(false);
        }}
      >
        <BootModal.Header closeButton>
          <BootModal.Title>{`Are you sure you want to remove ${revokeAccessData?.userName} from ${revokeAccessData?.orgName}`}</BootModal.Title>
        </BootModal.Header>

        <BootModal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              handleUserRolechange(
                revokeAccessData.orgMemberId,
                revokeAccessData.value,
                revokeAccessData.orgIndex,
                revokeAccessData.userIndex,
                revokeAccessData.orgId,
              );
              setOpenRevokeAccess(false);
            }}
          >
            Yes
          </Button>
        </BootModal.Footer>
      </BootModal>
    </div>
  );
}

export default function Authorizations() {
  const [userInfo, setUserInfo] = useState(null);
  const [hasUserInfo, setHasUserInfo] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [savingToDb, setSavingToDb] = useState(false);

  const [newUserModalOpen, setNewUserModalOpen] = useState(false);

  const [isGoogleAccount, setIsGoogleAccount] = useState(false);
  const [projects, setProjects] = useState(null);

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [role, setRole] = useState("reader");
  const [selectedRole, setSelectedRole] = useState({
    label: "Reader",
    value: "reader",
  });
  const [isRoleMenuOpen, setRoleIsMenuOpen] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const roleOptions = [
    { label: "Owner", value: "owner" },
    { label: "Reader", value: "reader" },
    { label: "Editor", value: "editor" },
    { label: "Revoke access", value: "Revoke access" },
  ];

  useEffect(() => {
    setName("");
    setEmail("");
    setJobTitle("");
    setSelectedRole({ label: "Reader", value: "reader" });
  }, [newUserModalOpen]);

  async function getOrganizations(info) {
    if (!info) {
      setIsLoading(false);
      return null;
    }

    const orgs =
      info.role === "client"
        ? await getClientOrganizations(info.uid)
        : await getMemberOrganizations(info.uid);

    if (!orgs || orgs.length === 0) {
      setIsLoading(false);
      return null;
    }

    const addUserData = async (organizations) => {
      const orgsWithUsers = await Promise.all(
        organizations.map(async (o) => {
          let myRole = "reader";
          const workspace = await getOrganizationMembers(o.id);

          const updatedWorkspace = workspace.map((user) => {
            if (info.uid === user?.uid) {
              myRole = user.role;
              return {
                ...user,
                you: true,
                name: (user.name ?? user.firstName) + " (You)",
              };
            }
            return user;
          });

          const clientUser = await getOtherUserUid(o.clientId);
          if (clientUser) {
            const name =
              info.uid === o.clientId
                ? (clientUser.name ?? clientUser.firstName) + " (You)"
                : (clientUser.name ?? clientUser.firstName);
            updatedWorkspace.unshift({
              ...clientUser,
              mainClient: true,
              role: "owner",
              name: name,
            });
          }
          const canEdit =
            !["reader", "editor"].includes(myRole) || info.role === "client";
          return {
            ...o,
            workspace: updatedWorkspace,
            myRole: myRole,
            canEdit: canEdit,
          };
        }),
      );
      return orgsWithUsers;
    };

    addUserData(orgs).then((updatedOrgs) => {
      setOrganizations(updatedOrgs);
      setIsLoading(false);
      return;
    });
  }

  useEffect(() => {
    const fetchUserInformation = async () => {
      if (user) {
        setHasUserInfo(true);
        getUserInformation().then((info) => {
          setUserInfo(info);
          setIsGoogleAccount(info?.isGoogleAccount);
          getOrganizations(info);
        });
      }
    };
    if (!hasUserInfo) fetchUserInformation();
  }, [user]);

  useEffect(() => {
    if (!loading && !user) {
      navigate("/");
    }
  }, [loading, userInfo, user]);

  const handleEmailChange = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmail(email);
    setValidEmail(emailPattern.test(email));
  };

  async function writeOrganizationMember(
    email,
    name,
    jobTitle,
    role,
    organizationId,
  ) {
    try {
      const docRef = doc(collection(db, "organizationMembers"));
      await setDoc(docRef, {
        email,
        name,
        jobTitle,
        role,
        organizationId,
      });
      await mirrorMembershipInOrganization(organizationId, docRef.id, "add");
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  const saveNewUser = async () => {
    // Check if user tries to add themself
    if (email === userInfo.email) {
      toast.error("You cannot invite yourself!");
      return;
    }

    // Check if email is already in the organization
    if (selectedOrganization && selectedOrganization.workspace) {
      const userExists = selectedOrganization.workspace.find(
        (user) => user.email === email.trim(),
      );
      if (userExists) {
        toast.error("User already exists in this organization!");
        return;
      }
    }

    const writeSuccess = await writeOrganizationMember(
      email,
      name,
      jobTitle,
      selectedRole.value,
      selectedOrganization.id,
    );

    setNewUserModalOpen(false);
    if (writeSuccess) {
      setIsLoading(true);
      getOrganizations(userInfo).then((orgs) => {
        setOrganizations(orgs);
      });
      await sendInviteEmail(email, [
        {
          name: "url",
          content: `https://www.luxifer.app/invited/${
            selectedOrganization.id
          }?email=${encodeURIComponent(email)}`,
        },
        {
          name: "role",
          content: selectedRole.value,
        },
        {
          name: "name",
          content: name,
        },
        {
          name: "organization",
          content: selectedOrganization.name,
        },
        {
          name: "sender",
          content: userInfo.firstName,
        },
      ]).then(() => {
        toast.success(name + " added successfully");
        setIsLoading(false);
      });
    } else {
      toast.error("User could not be added");
      setIsLoading(false);
    }
  };

  /**
   * If the page is loading, or user info is loading, the Spinning wheel will be shown.
   */
  if (loading || (user && !userInfo?.role)) {
    return (
      <div className="fullscreen-center">
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (user) {
    if (userInfo?.paymentPlan === "start") {
      return (
        <UserInfoContext.Provider value={userInfo}>
          <ToastContainer />
          <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
            <div className="profile-main-content">
              <div style={{ marginLeft: "50.36px" }}>
                <div style={{ marginTop: "35.5px" }}>
                  <Link to={"/"}>
                    <div
                      style={{
                        width: "158px",
                        height: "24px",
                        textAlign: "center",
                      }}
                      className="fs-14 fw-700 fc-black"
                    >
                      <FiArrowLeft
                        color="#000"
                        size="24px"
                        style={{ marginRight: "10px", marginBottom: "2px" }}
                      />
                      Back to Dashboard
                    </div>
                  </Link>

                  <div
                    style={{ height: "24px", marginTop: "61.55px" }}
                    className="fs-24 fw-700 lh-243 "
                  >
                    Authorizations
                  </div>
                  <div
                    style={{
                      height: "24px",
                      marginTop: "68.78px",
                    }}
                    className="fs-18 fw-700"
                  >
                    Users on "Start" payment plan cannot add users to their
                    workspace.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </UserInfoContext.Provider>
      );
    }
    return (
      <>
        <AuthorizationsContent
          userInfo={userInfo}
          user={user}
          setUserInfo={setUserInfo}
          setSavingToDb={setSavingToDb}
          savingToDb={savingToDb}
          isGoogleAccount={isGoogleAccount}
          setProjects={setProjects}
          setNewUserModalOpen={setNewUserModalOpen}
          setSelectedOrganization={setSelectedOrganization}
          organizations={organizations}
          setOrganizations={setOrganizations}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        >
          <ToastContainer />
        </AuthorizationsContent>
        <Modal
          isOpen={newUserModalOpen}
          contentLabel="Add new user modal"
          className="add-user-modal custom-modal modal-shape2"
          appElement={document.getElementById("root")}
          style={{
            overlay: {
              background: "rgb(42, 0, 255, 0.7)",
              backdropFilter: "blur(8px)",
              zIndex: 10,
            },
          }}
        >
          <div>
            <button
              className="d-flex justify-content-center align-items-center delete-confirm-x-btn"
              onClick={() => setNewUserModalOpen(false)}
            >
              <BsXCircle alt="X button" size="32px" color="#0F0C22" />
            </button>
          </div>
          <div className="text-center mt-3">
            <h3 className="mb-4 mp-ellipsis-text">Add a new LUXIFER Member</h3>
            <h5 className="mb-4">Complete the information below</h5>
          </div>

          <div className="d-flex justify-content-center align-items-center mb-2">
            <CustomInput
              placeholder="Name, Firstname, Surname"
              containerClassName="observation-text-input-container delete-width-input"
              inputClassNames="delete-width-input"
              className={name === "" ? "active" : ""}
              valueState={name}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-2">
            <CustomInput
              placeholder="Professional email address"
              containerClassName="observation-text-input-container delete-width-input"
              inputClassNames="delete-width-input"
              className={email === "" ? "active" : ""}
              valueState={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              value={email}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <CustomInput
              placeholder="Job Title (optional)"
              containerClassName="observation-text-input-container delete-width-input"
              inputClassNames="delete-width-input"
              className={jobTitle === "" ? "active" : ""}
              valueState={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              value={jobTitle}
            />
          </div>
          <div style={{ width: "100%" }}>
            <div
              className="mx-auto justify-content-center align-items-center"
              style={{ width: "367px" }}
            >
              <CustomDropdown
                options={roleOptions.slice(0, -1)}
                onChange={(option) => setSelectedRole(option)}
                value={selectedRole}
                placeholder="Role"
                unstyled
                menuOpenState={isRoleMenuOpen}
                setMenuOpenState={setRoleIsMenuOpen}
              />
            </div>
          </div>

          <div className="row justify-content-center mb-5 d-flex align-items-center">
            <button
              className="new-user-confirm-btn btn-primary"
              onClick={async () =>
                await saveNewUser().then(() => {
                  setIsLoading(false);
                })
              }
              disabled={!validEmail || !name}
            >
              <span style={{ color: "white" }}>Confirm</span>
            </button>
          </div>
        </Modal>
      </>
    );
  }
}
