import { auth, db } from "../../firebase";
import { useEffect, useState } from "react";
import "./ManageNotifications.css";
import "../UxChecklist/UxChecklist.css";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUserInformation, getUserProjects, capitalize } from "../../helpers";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  doc,
  updateDoc,
  deleteField,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  addDoc,
} from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function NotificationsContent({ userInfo, projects, isLoading }) {
  const [projectStates, setProjectStates] = useState([]);
  const [directMessage, setDirectMessage] = useState(true);
  const [dailyAIMetrics, setDailyAIMetrics] = useState(true);

  useEffect(() => {
    if (projects) {
      fetchNotificationPreferences(projects);
    }
  }, [projects]);

  useEffect(() => {
    if (userInfo) {
      setDirectMessage(!userInfo.disabledMessages);
      setDailyAIMetrics(!userInfo.disabledAIMetrics);
    }
  }, [userInfo]);

  const handleTopLevelNotificationToggle = async (value, attribute, setter) => {
    setter(value);

    try {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const user = await getDoc(userDocRef);

      if (user.exists()) {
        await updateDoc(userDocRef, {
          [attribute]: !value,
        });
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const fetchNotificationPreferences = async (projects) => {
    try {
      const userId = auth.currentUser.uid;

      const fetchPromises = projects.map(async (project) => {
        const projectId = project.value;
        const queries = query(
          collection(db, "notificationPrefs"),
          where("userId", "==", userId),
          where("projectId", "==", projectId),
        );
        const querySnapshot = await getDocs(queries);

        let projectPrefs = {
          newInsights: true,
          informationNewsletter: true,
          notificationsAlert: true,
        };

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            projectPrefs = {
              newInsights: data.disabledNewInsights !== true,
              informationNewsletter:
                data.disabledInformationNewsletter !== true,
              notificationsAlert: data.disabledNotificationsAlert !== true,
            };
          });
        } else {
          await addDoc(collection(db, "notificationPrefs"), {
            userId: userId,
            projectId: projectId,
          });
        }
        return { projectId, ...projectPrefs };
      });

      const fetchedPreferences = await Promise.all(fetchPromises);

      setProjectStates(
        projects.map((project) => {
          const fetchedPref = fetchedPreferences.find(
            (fp) => fp.projectId === project.value,
          );
          return {
            ...project,
            newInsights: fetchedPref ? fetchedPref.newInsights : true,
            informationNewsletter: fetchedPref
              ? fetchedPref.informationNewsletter
              : true,
            notificationsAlert: fetchedPref
              ? fetchedPref.notificationsAlert
              : true,
            isOpen: false,
          };
        }),
      );
    } catch (error) {
      return;
    }
  };

  const updateNotificationPreferences = async (projectId, field, value) => {
    try {
      const userId = auth.currentUser.uid;
      const queries = query(
        collection(db, "notificationPrefs"),
        where("userId", "==", userId),
        where("projectId", "==", projectId),
      );
      const querySnapshot = await getDocs(queries);

      if (!querySnapshot.empty) {
        const notificationDocRef = querySnapshot.docs[0].ref;

        let updateObject = {};

        if (
          [
            "newInsights",
            "informationNewsletter",
            "notificationsAlert",
          ].includes(field)
        ) {
          if (!value) {
            updateObject[`disabled${capitalize(field)}`] = true;
          } else {
            updateObject[`disabled${capitalize(field)}`] = deleteField();
          }

          await updateDoc(notificationDocRef, updateObject);
        }
      }
    } catch (error) {
      return;
    }
  };

  // Entire project section
  function handleProjectStateChange(value, field, i) {
    const updatedProject = projectStates[i];
    updateNotificationPreferences(updatedProject.value, field, value);

    setProjectStates((prevList) =>
      prevList.map((states, j) => {
        if (i === j) {
          return {
            ...states,
            [field]: value,
          };
        }
        return states;
      }),
    );
  }

  return (
    <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
      <div className="profile-main-content">
        <div style={{ marginLeft: "50.36px" }}>
          <div style={{ marginTop: "35.5px" }}>
            <Link to={"/"}>
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                }}
                className="fs-14 fw-700 fc-black"
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </Link>
            <div
              style={{ height: "24px", marginTop: "61.55px" }}
              className="fs-24 fw-700 lh-243 "
            >
              Gestions des notifications
            </div>
          </div>

          <div
            style={{
              height: "24px",
              marginTop: "68.78px",
            }}
            className="fs-18 fw-700"
          >
            Weekly summary
          </div>
          <div
            style={{
              maxWidth: "550px",
              marginTop: "12px",
            }}
          >
            <div className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023">
              <p> Receive weekly summary of events on your project.</p>
            </div>
          </div>

          {isLoading ? (
            <Spinner
              className="mt-4"
              style={{ height: "70px", width: "70px" }}
            />
          ) : (
            <>
              <div
                className="row"
                style={{ marginTop: "50px", alignItems: "center" }}
              >
                <div style={{ flex: 1 }}>
                  <div className="fs-18 fw-700">Direct message</div>
                  <div
                    style={{
                      maxWidth: "550px",
                      marginTop: "8px",
                    }}
                  >
                    <div className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023">
                      <p>Notifications about direct messages from Admin.</p>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    handleTopLevelNotificationToggle(
                      !directMessage,
                      "disabledMessages",
                      setDirectMessage,
                    )
                  }
                  className={`notification-toggle align-items-center fw-700 ${
                    directMessage ? "notification-on" : "notification-off"
                  }`}
                  style={{ height: "24px", width: "36px" }}
                >
                  <div
                    className={
                      directMessage
                        ? "notification-toggle-circle-on"
                        : "notification-toggle-circle-off"
                    }
                  ></div>
                </button>
              </div>
              <div
                className="row"
                style={{ marginTop: "50px", alignItems: "center" }}
              >
                <div style={{ flex: 1 }}>
                  <div className="fs-18 fw-700">Daily AI Metrics</div>
                  <div
                    style={{
                      maxWidth: "550px",
                      marginTop: "8px",
                    }}
                  >
                    <div className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023">
                      <p>Emails & notifications for metric alerts</p>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    handleTopLevelNotificationToggle(
                      !dailyAIMetrics,
                      "disabledAIMetrics",
                      setDailyAIMetrics,
                    )
                  }
                  className={`notification-toggle align-items-center fw-700 ${
                    dailyAIMetrics ? "notification-on" : "notification-off"
                  }`}
                  style={{ height: "24px", width: "36px" }}
                >
                  <div
                    className={
                      dailyAIMetrics
                        ? "notification-toggle-circle-on"
                        : "notification-toggle-circle-off"
                    }
                  ></div>
                </button>
              </div>
              <div style={{ marginTop: "68px" }}>
                {projectStates?.map((p, i) => (
                  <div key={i}>
                    <div
                      className="row"
                      style={{
                        margin: "0",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <img
                        src={p.image}
                        className="img-keep-ratio"
                        style={{
                          width: "38px",
                          height: "38px",
                          objectFit: "contain",
                          display: "block",
                          maxWidth: "100%",
                          padding: "0",
                          marginRight: "20.29px",
                        }}
                      />

                      <div
                        className="col fw-700 fs-18 lh-243"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          maxWidth: "70%",
                          flexWrap: "nowrap",
                          overflow: "auto",
                          padding: "0px",
                        }}
                      >
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-top`}>{p.name}</Tooltip>
                          }
                        >
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {p.name}
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          onClick={() =>
                            handleProjectStateChange(!p.isOpen, "isOpen", i)
                          }
                          style={{
                            marginRight: "50.12px",
                            height: "44px",
                            width: "71px",
                            float: "right",
                          }}
                          className="btn-secondary align-items-center fw-700"
                        >
                          <div className="mp-btn-fix">
                            {p?.isOpen ? "Close" : "Open"}
                          </div>
                        </button>
                      </div>
                    </div>

                    {/* Expanded Section */}
                    {p?.isOpen && (
                      <div>
                        {/* Notification Items */}
                        {[
                          {
                            title: "New insights",
                            description:
                              "Email & notifications when LUXIFER detects new insights.",
                            field: "newInsights",
                          },
                          {
                            title: "Information newsletter",
                            description:
                              "Information about new features, important information about the product.",
                            field: "informationNewsletter",
                          },
                          {
                            title: "Notifications",
                            description:
                              "New notifications about activity (new metrics, new insights, alerts,...).",
                            field: "notificationsAlert",
                          },
                        ].map((notification, index) => (
                          <div
                            className="row"
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginTop: "40px",
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <div className="fs-18 fw-700">
                                {notification.title}
                              </div>
                              <div
                                style={{
                                  maxWidth: "550px",
                                  marginTop: "8px",
                                }}
                              >
                                <div className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023">
                                  <p>{notification.description}</p>
                                </div>
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                handleProjectStateChange(
                                  !p[notification.field],
                                  notification.field,
                                  i,
                                )
                              }
                              className={`notification-toggle align-items-center fw-700 ${
                                p[notification.field]
                                  ? "notification-on"
                                  : "notification-off"
                              }`}
                              style={{ height: "24px", width: "36px" }}
                            >
                              <div
                                className={
                                  p[notification.field]
                                    ? "notification-toggle-circle-on"
                                    : "notification-toggle-circle-off"
                                }
                              ></div>
                            </button>
                          </div>
                        ))}
                      </div>
                    )}

                    <hr
                      className="row"
                      style={{
                        width: "95%",
                        marginTop: "32px",
                        marginBottom: "32px",
                      }}
                    ></hr>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* Direct Message Section */}
        </div>
      </div>
    </div>
  );
}

export default function ManageNotifications() {
  const [userInfo, setUserInfo] = useState(null);
  const [hasUserInfo, setHasUserInfo] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [savingToDb, setSavingToDb] = useState(false);

  const [deleteProjectConfirmModalIsOpen, setDeleteProjectConfirmModalIsOpen] =
    useState(false);

  const [isGoogleAccount, setIsGoogleAccount] = useState(false);
  const [projects, setProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserInformation = async () => {
      if (user) {
        setHasUserInfo(true);
        getUserInformation().then((info) => {
          setUserInfo(info);
          setIsGoogleAccount(info?.isGoogleAccount);
          getUserProjects(info).then((projects) => {
            const updatedProjects = projects.map((project) => ({
              ...project,
            }));
            setProjects(updatedProjects);

            setIsLoading(false);
          });
        });
      }
    };
    if (!hasUserInfo) fetchUserInformation();
  }, [user]);

  useEffect(() => {
    if (
      !loading &&
      (!user || (user && userInfo && ["designer"].includes(userInfo.role)))
    ) {
      navigate("/");
    }
  }, [loading, userInfo, user]);

  /**
   * If the page is loading, or user info is loading, the Spinning wheel will be shown.
   */
  if (loading || (user && !userInfo?.role)) {
    return (
      <div className="fullscreen-center">
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (user) {
    return (
      <NotificationsContent
        userInfo={userInfo}
        user={user}
        setUserInfo={setUserInfo}
        setSavingToDb={setSavingToDb}
        savingToDb={savingToDb}
        isGoogleAccount={isGoogleAccount}
        projects={projects}
        setProjects={setProjects}
        isLoading={isLoading}
      >
        <ToastContainer />
      </NotificationsContent>
    );
  }
}
