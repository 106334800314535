import { useDraggable } from "react-use-draggable-scroll";
import { useContext, useEffect, useRef, useState } from "react";
import { CustomPaginator, getHeatmapData, getScreenWidth } from "../../helpers";
import h337 from "heatmap.js";
import { Spinner } from "react-bootstrap";
import insightFlame from "../../assets/insight-flame.png";
import movePoint from "../../assets/move-point.png";
import heatmapBar from "../../assets/heatmap-bar.png";
import { useNavigate } from "react-router-dom";
import { SelectedProjectContext, UserInfoContext } from "../../contexts";
import InsightModal from "../InsightsV6/InsightModal";
import RecordingModal from "../reusables/Recording/RecordingModal";
import PopperTooltip from "../reusables/PopperTooltip/PopperTooltip";
import config from "../../config";

export default function HeatMap({
  funnel,
  funnelLoaded,
  totalPages,
  imgSrcs,
  setPathModalOpen,
  pid,
  filters,
  MRAID,
  deviceType,
}) {
  const [page, setPage] = useState(0);
  const [heatmapData, setHeatmapData] = useState(null);
  const [heatmapClouds, setHeatmapClouds] = useState([]);
  const [heatmapDataTemp, setHeatmapDataTemp] = useState([]);
  const latestTimestampRef = useRef(0);
  const latestTimestampRefImages = useRef(0);
  const userInfo = useContext(UserInfoContext);
  const selectedProject = useContext(SelectedProjectContext);

  const [hoverContext, setHoverContext] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const [mousePaths, setMousePaths] = useState([]);

  const [hoverScrolls, setHoverScrolls] = useState([]);
  const [hoverClicks, setHoverClicks] = useState([]);
  const [hoverHesitations, setHoverHesitations] = useState([]);
  const [hoverFrustrations, setHoverFrustrations] = useState([]);
  const [hoverElements, setHoverElements] = useState([]);
  const [hoverInsights, setHoverInsights] = useState([]);

  // Non anchored
  const [tooltip, setTooltip] = useState(null);

  // Anchored
  const [tooltipContent, setTooltipContent] = useState("empty");
  const [coordinates, setCoordinates] = useState({
    x: 0,
    y: 0,
    visible: false,
  });

  const [insightNav, setInsightNav] = useState(null);
  const [imgSrcsSized, setImgSrcsSized] = useState([]);
  const navigate = useNavigate();

  const ref = useRef(null);
  const trackerRef = useRef(null);
  const { events } = useDraggable(ref, {
    isMounted: true,
  });

  const pageWidth = config.HEATMAP_PAGE_WIDTH;

  const [loadedImage, setLoadedImage] = useState(null);
  const [loadedImage2, setLoadedImage2] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal for insights
  const [selectedInsight, setSelectedInsight] = useState(null);
  const [modalInsights, setModalInsights] = useState([]);

  const [recordingModalOpen, setRecordingModalOpen] = useState(false);
  const [hoveredReplay, setHoveredReplay] = useState(null);

  function resetTooltip() {
    setCoordinates({ x: 0, y: 0, visible: false });
  }

  useEffect(() => {
    const img = new Image();
    img.src = insightFlame;
    img.onload = () => setLoadedImage(img);

    const img2 = new Image();
    img2.src = movePoint;
    img2.onload = () => setLoadedImage2(img2);
  }, []);

  function calculateCurrentPage(scrollLeft) {
    return Math.floor((scrollLeft + config.HEATMAP_OFFSET) / pageWidth);
  }

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current && ref.current.scrollLeft) {
        const currentPageIndex = calculateCurrentPage(ref.current.scrollLeft);
        setPage(currentPageIndex);
      }
    };

    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const currentTimestamp = Date.now();

    const loadImage = async (src) => {
      if (!src) {
        return "";
      }
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = src;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const cropWidth = getScreenWidth(deviceType);
          const cropHeight = img.naturalHeight;
          canvas.width = cropWidth;
          canvas.height = cropHeight;

          ctx.drawImage(
            img,
            0,
            0,
            cropWidth,
            cropHeight,
            0,
            0,
            cropWidth,
            cropHeight,
          );

          const croppedImageSrc = canvas.toDataURL();
          resolve(croppedImageSrc);
        };

        img.onerror = (e) => {
          reject(e);
        };
      });
    };

    Promise.all(imgSrcs.map((src) => loadImage(src)))
      .then((croppedImages) => {
        if (isMounted && currentTimestamp > latestTimestampRefImages.current) {
          latestTimestampRefImages.current = currentTimestamp;
          setImgSrcsSized(croppedImages);
        }
      })
      .catch((e) => {
        console.error("Error loading images:", e);
      });

    return () => {
      isMounted = false;
    };
  }, [imgSrcs, deviceType]);

  useEffect(() => {
    setImagesLoaded(false);
    setImgSrcsSized([]);
  }, [imgSrcs]);

  useEffect(() => {
    if (imgSrcsSized.length === imgSrcs.length && imgSrcsSized.length > 0) {
      setImagesLoaded(true);
    }
  }, [imgSrcsSized]);

  async function getAndSetHeatmapData(pid, urls, MRAID, funnel) {
    if (!pid || !urls || urls.length === 0 || funnel.length === 0) {
      return null;
    }

    const currentTimestamp = Date.now();
    // Insights and elements
    const data = await getHeatmapData(
      pid,
      urls,
      [
        filters.desktop ? "desktop" : null,
        filters.phone ? "smartphone" : null,
        filters.tablet ? "tablet" : null,
      ].filter(Boolean),
      MRAID,
    );
    const activity = funnel.map((el) => el.activity);
    const replay = funnel.map((el) => el.replay);
    const personaId = funnel.map((el) => el.personaId);
    const combined = data.map((item, index) => ({
      ...item,
      ...activity[index],
      ...replay[index],
      ...personaId[index],
    }));
    setHeatmapDataTemp({ data: combined, timestamp: currentTimestamp });
  }

  useEffect(() => {
    if (
      heatmapDataTemp &&
      heatmapDataTemp.timestamp > latestTimestampRef.current
    ) {
      latestTimestampRef.current = heatmapDataTemp.timestamp;
      setHeatmapData(heatmapDataTemp.data);
    }
  }, [heatmapDataTemp]);

  useEffect(() => {
    if (
      funnelLoaded &&
      funnel.length > 0 &&
      pid &&
      filters.startDate &&
      filters.endDate &&
      imagesLoaded
    ) {
      const urls = [...funnel.map((el) => encodeURIComponent(el.url))];
      getAndSetHeatmapData(pid, urls, MRAID, funnel);
    }
  }, [MRAID, funnel, pid, funnelLoaded, imagesLoaded]);

  function createOrClearCanvas(element, className, id) {
    const width = element.offsetWidth;
    const height = element.offsetHeight;
    let canvas = element.querySelector(`.${className}`);
    if (!canvas) {
      canvas = document.createElement("canvas");
      canvas.className = className;
      canvas.width = width;
      canvas.height = height;
      canvas.style.position = "absolute";
      canvas.style.top = "0";
      canvas.style.left = "0";
      if (id) {
        canvas.id = id;
      }

      element.appendChild(canvas);
    } else {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    return canvas;
  }

  function clearCanvas(ctx) {
    if (ctx) ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  function clearAllCanvases() {
    // Clear all canvases for the hover square
    for (let i = 0; i < hoverElements.length; i++) {
      const element = document.querySelector(`#heatmap-page-${i}`);
      if (element) {
        createOrClearCanvas(element, "elements-canvas");
      }
    }
  }

  function getTooltipHtmlBlack(count, total, type) {
    const percentage = ((count / total) * 100)
      .toFixed(2)
      .toString()
      .replace(".", ",");
    return `<div>
              <div>${count}</div>
              <div>${type}${count === 1 ? "" : "s"}</div>
              <div>${percentage}% of all ${type}s</div>
            </div>`;
  }

  function getTooltipHtmlHex(count, total, type, tier, replay = false) {
    const percentage = ((count / total) * 100)
      .toFixed(2)
      .toString()
      .replace(".", ",");
    const tierText =
      tier === 0 ? "Low" : tier === 1 ? "Low" : tier === 2 ? "Medium" : "High";
    const headerText = type === "click" ? "Clicks" : `${tierText} ${type}`;
    return (
      <div className="heatmap-tooltip-3">
        <div>
          <div>{headerText}</div>
          <div>
            {count} {type}
            {count === 1 ? "" : "s"} ({percentage}% of all)
          </div>
        </div>
        {replay && (
          <>
            <button
              onClick={() => {
                setHoveredReplay(replay ?? null);
                setRecordingModalOpen(true);
                resetTooltip();
              }}
              className="mt-14 btn-quaternary mb-8 btn-heatmap-recording fvc"
            >
              <i className="fa-regular fa-play mr-10"></i>Play the recording
            </button>
          </>
        )}
      </div>
    );
  }

  function pointInZone(pointX, pointY, zone) {
    return (
      pointX >= zone.x &&
      pointX <= zone.x + zone.w &&
      pointY >= zone.y &&
      pointY <= zone.y + zone.h
    );
  }

  function getPersonaName(personaId) {
    const persona = filters.personaData.find(
      (persona) => persona.id === personaId,
    );
    if (!persona) {
      return `most users`;
    }
    return `"${persona.name}"`;
  }

  // Main hover canvas
  useEffect(() => {
    function checkMousePathHovers() {
      let result = false;
      if (mousePaths && hoverContext) {
        hoverContext.lineWidth = 30;
        Object.entries(mousePaths).forEach(([name, path]) => {
          const parts = name.split("|");
          const hasName = parts[0] === "1" ? true : false;
          const number = `mouseover-canvas-${parts[1]}`;
          const personaId = parts[2];
          const replay = parts[3];
          if (
            !number ||
            !personaId ||
            !replay ||
            number !== hoverContext.canvas.id ||
            !hoverContext.isPointInStroke(path, mouseX, mouseY)
          ) {
            return;
          }

          const personaName = hasName
            ? getPersonaName(personaId)
            : "most users";
          setTooltip({
            x: mouseX - 10 + parts[1] * pageWidth,
            y: mouseY - 10,
            show: true,
            html: (
              <div className="heatmap-tooltip-3">
                <div>
                  <div
                    style={{ maxWidth: "175px", width: "max-content" }}
                    className="wrapword"
                  >
                    This is the main path followed by {personaName}.
                  </div>
                </div>
                {replay && replay !== "null" && (
                  <>
                    <button
                      onClick={() => {
                        setHoveredReplay(replay ?? null);
                        setRecordingModalOpen(true);
                        resetTooltip();
                      }}
                      className="mt-14 btn-quaternary mb-8 btn-heatmap-recording fvc"
                    >
                      <i className="fa-regular fa-play mr-10"></i>Play the
                      recording
                    </button>
                  </>
                )}
              </div>
            ),
            type: "mousepath",
            button: replay ? true : false,
          });
          result = true;
        });
      }
      return result;
    }

    function checkElementHovers(ctx, measurables) {
      // elements hover
      ctx.strokeStyle = "white";
      ctx.lineWidth = 2;

      const hoverableItems = hoverElements[j].filter((item) =>
        pointInZone(mouseX, mouseY, item),
      );

      if (hoverableItems.length > 0) {
        const focus = hoverableItems.reduce((smallest, item) => {
          const smallestArea = smallest.w * smallest.h;
          const itemArea = item.w * item.h;
          return itemArea < smallestArea ? item : smallest;
        });

        ctx.strokeRect(focus.x, focus.y, focus.w, focus.h);

        const total = measurables[j].reduce((acc, e) => acc + e.value, 0);

        const clicksInFocus = measurables[j].filter((e) =>
          pointInZone(e.x, e.y, focus),
        );

        const totalInFocus = clicksInFocus.reduce((acc, e) => acc + e.value, 0);

        const getMostCommonReplay = (clicks) => {
          const replayCount = clicks.reduce((acc, click) => {
            if (click.replay !== null) {
              acc[click.replay] = (acc[click.replay] || 0) + 1;
            }
            return acc;
          }, {});

          const keys = Object.keys(replayCount);
          if (keys.length === 0) {
            return null;
          }

          return keys.reduce((a, b) =>
            replayCount[a] > replayCount[b] ? a : b,
          );
        };
        const clickReplay = getMostCommonReplay(clicksInFocus);

        for (let i = 0; i < clicksInFocus.length; i++) {
          if (pointInZone(mouseX, mouseY, focus)) {
            isHovering = true;
            setTooltip({
              x: focus.x + j * pageWidth + focus.w - 20,
              y: focus.y + focus.h - 20,
              show: true,
              html: getTooltipHtmlHex(
                totalInFocus,
                total,
                "click",
                0,
                clickReplay,
              ),
              type: "clicks",
              button: clickReplay ? true : false,
            });

            break;
          }
        }
      }
    }

    if (!hoverContext || !hoverContext.canvas.id) {
      clearAllCanvases();
      return;
    }
    const j = parseInt(hoverContext.canvas.id.split("-")[2]);
    const ctx = hoverContext;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    let isHovering = false;

    clearAllCanvases();

    if (filters.heatmap && hoverElements[j]) {
      let measurables, text, type;
      const element = document.querySelector(`#heatmap-page-${j}`);

      if (filters.heatmap) {
        measurables = hoverClicks;
        text = "click";
        type = "heatmap";
      }

      if (!element || !measurables || !text || !type) {
        return;
      }

      const canvas = createOrClearCanvas(element, "elements-canvas");
      const ctx = canvas.getContext("2d");
      if (checkMousePathHovers()) {
        isHovering = true;
      } else {
        checkElementHovers(ctx, measurables);
      }
    }

    if (filters.frustrations && hoverFrustrations[j]) {
      const frustrationClicks = hoverFrustrations[j];
      if (frustrationClicks && frustrationClicks.length > 0) {
        const totalFrustrations = frustrationClicks.reduce(
          (acc, click) => acc + click.value,
          0,
        );
        for (let i = 0; i < hoverFrustrations[j].length; i++) {
          const click = hoverFrustrations[j][i];
          if (
            mouseY &&
            mouseX &&
            Math.abs(mouseY - click.y) <= 8 &&
            Math.abs(mouseX - click.x) <= 8
          ) {
            isHovering = true;
            setTooltip({
              x: click.x + j * pageWidth,
              y: click.y,
              show: true,
              html: getTooltipHtmlHex(
                click.value,
                totalFrustrations,
                "frustration",
                click.tier,
                click.replay,
              ),
              type: "frustrations",
              button: click.replay ? true : false,
            });
            break;
          }
        }
      }
    }

    if (filters.hesitations && hoverHesitations[j]) {
      const totalHesitations = hoverHesitations[j]?.reduce(
        (acc, hes) => acc + hes.value,
        0,
      );

      for (let i = 0; i < hoverHesitations[j].length; i++) {
        const hes = hoverHesitations[j][i];
        if (
          mouseY &&
          mouseX &&
          Math.abs(mouseY - hes.y) <= 8 &&
          Math.abs(mouseX - hes.x) <= 8
        ) {
          isHovering = true;
          setTooltip({
            x: hes.x + j * pageWidth,
            y: hes.y,
            show: true,
            html: getTooltipHtmlHex(
              hes.value,
              totalHesitations,
              "hesitation",
              hes.tier,
              hes.replay,
            ),
            type: "hesitations",
            button: hes.replay ? true : false,
          });
          break;
        }
      }
    }

    if (filters.insights && hoverInsights[j]) {
      for (let i = 0; i < hoverInsights[j].length; i++) {
        const insight = hoverInsights[j][i];
        if (
          mouseY &&
          mouseX &&
          Math.abs(mouseY - insight.y) <= 10 &&
          Math.abs(mouseX - insight.x) <= 10
        ) {
          isHovering = true;
          setInsightNav(insight.id);
          setModalInsights([{ id: insight.id }]);
          setTooltip({
            x: insight.x + j * pageWidth,
            y: insight.y,
            show: true,
            html: (
              <div className="heatmap-tooltip-2">
                <div>
                  <div>Insight #{insight.num}</div>
                  <div
                    onClick={() => {
                      setInsightNav(insight.id);
                      setIsModalOpen(true);
                      resetTooltip();
                    }}
                  >
                    {insight.title}
                  </div>
                  <div>+1,07% potential gain on “Goal”. </div>
                </div>
              </div>
            ),
            type: "insights",
          });
          break;
        }
      }
    }

    if (!isHovering) {
      if (filters.scroll && hoverScrolls[j] && hoverScrolls[j].length > 0) {
        const segmentHeight = 100;
        const totalSegments = Math.ceil(ctx.canvas.height / segmentHeight);

        const segmentPercentages = Array.from(
          { length: totalSegments },
          (_, i) => {
            const segmentTop = i * segmentHeight;
            const percentage = (
              (hoverScrolls[j].filter(
                (scroll) => scroll > segmentTop / ctx.canvas.height,
              ).length /
                hoverScrolls[j].length) *
              100
            ).toFixed(2);
            return parseFloat(percentage);
          },
        );

        const segmentIndex = Math.floor(mouseY / segmentHeight);
        const segmentTop = segmentIndex * segmentHeight;
        const percentageTop = segmentPercentages[segmentIndex];
        const percentageBottom =
          segmentPercentages[segmentIndex + 1] || percentageTop;

        const interpolationFactor = (mouseY - segmentTop) / segmentHeight;
        const interpolatedPercentage = (
          percentageTop +
          interpolationFactor * (percentageBottom - percentageTop)
        ).toFixed(2);

        if (interpolatedPercentage && ref.current) {
          setTooltip({
            show: true,
            text: `${interpolatedPercentage}% of users scroll this far`,
            type: "scroll",
            x:
              mouseX +
              pageWidth * j +
              pageWidth / 2 +
              160 -
              ref.current.scrollLeft,
            y: mouseY + 515,
          });
        }
      } else {
        setTooltip(null);
      }

      if (hoverContext && filters.scroll) {
        ctx.beginPath();
        ctx.moveTo(0, mouseY);
        ctx.lineTo(ctx.canvas.width, mouseY);
        ctx.strokeStyle = "#2A00FF";
        ctx.lineWidth = 1;
        ctx.stroke();

        ctx.fillStyle = "rgba(42, 0, 255, 0.1)";
        ctx.fillRect(0, mouseY, ctx.canvas.width, ctx.canvas.height - mouseY);
      }
    }
  }, [
    mouseX,
    mouseY,
    hoverContext,
    filters.scroll,
    filters.clicks,
    filters.frustrations,
    filters.hesitations,
    filters.insights,
    hoverScrolls,
    hoverClicks,
    hoverHesitations,
    hoverInsights,
  ]);

  function createMouseoverCanvas(element, canvas) {
    const ctx = canvas.getContext("2d");

    function handleMouseEnter() {
      setHoverContext(ctx);
      element.addEventListener("mousemove", updateMousePosition);
    }

    element.addEventListener("mouseenter", handleMouseEnter);
    if (element.matches(":hover")) {
      handleMouseEnter();
    }

    element.addEventListener("mouseleave", () => {
      element.removeEventListener("mousemove", updateMousePosition);
      setTooltip(null);
      clearCanvas(ctx);
      setHoverContext(null);
    });

    function updateMousePosition(e) {
      const rect = element.getBoundingClientRect();
      setMouseX(e.clientX - rect.left + element.scrollLeft);
      setMouseY(e.clientY - rect.top + element.scrollTop);
    }
  }

  // function distance(click1, click2) {
  //   return Math.sqrt(
  //     Math.pow(click1.x - click2.x, 2) + Math.pow(click1.y - click2.y, 2),
  //   );
  // }

  // function clusterClicks(clicks, threshold) {
  //   const clusters = [];
  //   const visited = new Array(clicks.length).fill(false);

  //   for (let i = 0; i < clicks.length; i++) {
  //     if (visited[i]) continue;

  //     const cluster = [];
  //     const stack = [i];

  //     while (stack.length > 0) {
  //       const index = stack.pop();
  //       if (visited[index]) continue;

  //       visited[index] = true;
  //       cluster.push(clicks[index]);

  //       for (let j = 0; j < clicks.length; j++) {
  //         if (!visited[j] && distance(clicks[index], clicks[j]) <= threshold) {
  //           stack.push(j);
  //         }
  //       }
  //     }

  //     clusters.push(cluster);
  //   }

  //   return clusters;
  // }

  // function averageCoordinates(cluster) {
  //   const sum = cluster.reduce(
  //     (acc, click) => {
  //       acc.x += click.x;
  //       acc.y += click.y;
  //       acc.frustrationCount += click.frustration ? 1 : 0;
  //       return acc;
  //     },
  //     { x: 0, y: 0, frustrationCount: 0 },
  //   );

  //   return {
  //     x: sum.x / cluster.length,
  //     y: sum.y / cluster.length,
  //     value: cluster.length,
  //     frustration: sum.frustrationCount,
  //   };
  // }

  function adjustScrolls(scrolls, height) {
    const min = 350 / height;
    const maxScroll = Math.max(...scrolls);
    const minScroll = Math.min(...scrolls);
    const range = maxScroll - minScroll;

    const normalizedScrolls =
      range === 0
        ? scrolls.map(() => 0)
        : scrolls.map((scroll) => (scroll - minScroll) / range);

    const adjustedScrolls = normalizedScrolls.map(
      (value) => min + value * (maxScroll - min),
    );

    return adjustedScrolls;
  }

  const processHeatmapData = (heatmapData, index, element) => {
    const width = filters.desktop
      ? 1920
      : filters.phone
        ? 360
        : filters.tablet
          ? 1024
          : null;

    const ratio = pageWidth / width;
    const elementHeight = element.offsetHeight;
    const elementWidth = element.offsetWidth;

    const processCoordinates = (data, hasFrustration = false) => {
      return data.map((e) => {
        if (Array.isArray(e)) {
          return e.map((subE) => ({
            x: Math.floor((subE.x / subE.w) * elementWidth),
            y: Math.floor((subE.y / subE.h) * elementHeight),
            replay: subE.replay,
            personaId: subE.personaId,
            value: 1,
            ...(hasFrustration && { frustration: true }),
          }));
        } else {
          return {
            x: Math.floor((e.x / e.w) * elementWidth),
            y: Math.floor((e.y / e.h) * elementHeight),
            replay: e.replay,
            personaId: e.personaId,
            value: 1,
            ...(hasFrustration && { frustration: true }),
          };
        }
      });
    };
    const processSquares = (data) => {
      return data.map((e) => ({
        x: Math.floor(e.x * ratio),
        y: Math.floor(e.y * ratio),
        w: Math.floor(e.w * ratio),
        h: Math.floor(e.h * ratio),
      }));
    };

    function dtwDistance(path1, path2) {
      const n = path1.length;
      const m = path2.length;
      const dtw = Array.from({ length: n + 1 }, () =>
        Array(m + 1).fill(Infinity),
      );
      dtw[0][0] = 0;

      for (let i = 1; i <= n; i++) {
        for (let j = 1; j <= m; j++) {
          const cost = Math.sqrt(
            Math.pow(path1[i - 1].x - path2[j - 1].x, 2) +
              Math.pow(path1[i - 1].y - path2[j - 1].y, 2),
          );
          dtw[i][j] =
            cost + Math.min(dtw[i - 1][j], dtw[i][j - 1], dtw[i - 1][j - 1]);
        }
      }

      return dtw[n][m];
    }

    function getBestMovesArray(movesArrays) {
      if (movesArrays.length === 0) {
        return [];
      }

      const candidates = processCoordinates(movesArrays);

      if (candidates.length === 1) {
        return candidates[0];
      }

      const averageDistances = candidates.map((candidate, index) => {
        let totalDistance = 0;
        for (let i = 0; i < candidates.length; i++) {
          if (i !== index) {
            totalDistance += dtwDistance(candidate, candidates[i]);
          }
        }

        const modifier = candidate.length < 4 ? 10000 : 0;
        return (totalDistance + modifier) / (candidates.length - 1);
      });

      const minAverageDistance = Math.min(...averageDistances);
      const bestIndex = averageDistances.indexOf(minAverageDistance);
      return candidates[bestIndex];
    }

    const moves = getBestMovesArray(heatmapData[index].moves);
    const personaMoves = [];
    const personaMovesMap = new Map();

    heatmapData[index].moves.forEach((move) => {
      if (!move || move.length === 0) {
        return;
      }
      if (!personaMovesMap.has(move[0].personaId)) {
        personaMovesMap.set(move[0].personaId, []);
      }
      personaMovesMap.get(move[0].personaId).push(move);
    });

    personaMovesMap.forEach((movesArray, personaId) => {
      const bestMovesArray = getBestMovesArray(movesArray);
      personaMoves.push({ personaId, moves: bestMovesArray });
    });

    const clicks = heatmapData[index].clicks
      ? processCoordinates(heatmapData[index].clicks)
      : [];
    const frustrations = heatmapData[index].frustrations
      ? processCoordinates(heatmapData[index].frustrations, true)
      : [];
    const hesitations = heatmapData[index].hesitations
      ? processCoordinates(heatmapData[index].hesitations)
      : [];
    const elements = heatmapData[index].elements
      ? processSquares(heatmapData[index].elements)
      : [];

    const scrolls = heatmapData[index].scrolls
      ? adjustScrolls(
          heatmapData[index].scrolls
            .map((scroll) => scroll / 100)
            .sort((a, b) => a - b),
          elementHeight,
        )
      : adjustScrolls([0], elementHeight);

    const insights = heatmapData[index].insights
      ? heatmapData[index].insights.flatMap((e) =>
          e.bounds
            ? e.bounds.map((b) => ({
                x: Math.floor((b.x / b.width) * elementWidth),
                y: Math.floor(b.y * ratio),
                title: e.title,
                num: e.num,
                id: e.trueId,
              }))
            : [],
        )
      : [];

    return {
      clicks,
      moves,
      insights,
      hesitations,
      scrolls,
      elements,
      frustrations,
      personaMoves,
    };
  };

  const drawPoints = (array, ctx, color) => {
    array.forEach((item) => {
      ctx.beginPath();
      ctx.arc(item.x, item.y, 5, 0, 2 * Math.PI, false);
      ctx.fillStyle = color;
      ctx.fill();
    });
  };

  function renderHeatmapData(heatmapData, filters) {
    if (!heatmapData || !filters) {
      return;
    }

    let heatmapCloudsLocal = [];
    heatmapClouds.forEach((hc) => {
      hc.setData({ max: 0, data: [] });
    });
    const hoverScrolls = [];
    const hoverClicks = [];
    const totalClicks = [];
    const hoverHesitations = [];
    const hoverInsights = [];
    const frustrationClicks = [];
    const hoverElements = [];
    for (let i = 0; i < heatmapData.length; i++) {
      const element = document.querySelector(`#heatmap-page-${i}`);
      if (!element) {
        continue;
      }
      const {
        clicks,
        moves,
        insights,
        hesitations,
        scrolls,
        elements,
        frustrations,
        personaMoves,
      } = processHeatmapData(heatmapData, i, element);

      // const frustrationClusters = clusterClicks(frustrations, 20);
      // const avgFrustrations = frustrationClusters.map((cluster) =>
      //   averageCoordinates(cluster),
      // );
      // const hesClusters = clusterClicks(hesitations, 20);
      // const avgHesitations = hesClusters.map((cluster) =>
      //   averageCoordinates(cluster),
      // );

      hoverClicks.push(clicks);
      hoverScrolls.push(scrolls);
      totalClicks.push(clicks.length);
      // frustrationClicks.push(avgFrustrations);
      // hoverHesitations.push(avgHesitations);
      hoverInsights.push(insights);
      hoverElements.push(elements);

      const vc2 = createOrClearCanvas(
        element,
        "mouseover-canvas",
        `mouseover-canvas-${i}`,
      );

      if (vc2) {
        createMouseoverCanvas(element, vc2);
      }

      if (filters.scroll) {
        const vc = createOrClearCanvas(element, "heatmap-canvas");
        const verticalCtx = vc.getContext("2d");
        const colors2 = [
          "rgba(255, 0, 0, 0.2)",
          "rgba(255, 0, 0, 0.2)",
          "rgba(255, 0, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(255, 255, 0, 0.2)",
          "rgba(0, 0, 255, 0.2)",
          "rgba(0, 0, 255, 0.2)",
          "rgba(0, 0, 255, 0.2)",
        ];

        function getProportion(arr, s) {
          return arr.filter((v) => v < s).length / arr.length;
        }

        const colorMap = [];

        for (let i = 0; i < 11; i++) {
          const proportion = Math.round(getProportion(scrolls, i / 10) * 10);
          if (colors2[proportion])
            colorMap.push({ pos: i / 10, color: colors2[proportion] });
        }

        const gradient = verticalCtx.createLinearGradient(0, 0, 0, vc.height);
        colorMap.forEach((c) => {
          gradient.addColorStop(c.pos, c.color);
        });
        verticalCtx.fillStyle = gradient;
        verticalCtx.fillRect(0, 0, vc.width, vc.height);
      } else {
        createOrClearCanvas(element, "heatmap-canvas");
      }

      const data = clicks;

      // Draw heatmap
      if (filters.heatmap) {
        const heatmapInstance = h337.create({
          container: element,
          maxOpacity: 0.6,
          minOpacity: 0,
        });
        heatmapCloudsLocal[i] = heatmapInstance;
        heatmapInstance.setData({
          max: Math.max(data.length / 100, 3),
          data: data,
        });
        setHeatmapClouds(heatmapCloudsLocal);
      } else {
        heatmapClouds.forEach((hc) => {
          hc.setData({ max: 0, data: [] });
        });
        setHeatmapClouds([]);
        heatmapCloudsLocal = [];
      }
      function getEuclideanDistance(point1, point2) {
        return Math.sqrt(
          Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2),
        );
      }

      function getPerpendicularDistance(point, lineStart, lineEnd) {
        const area = Math.abs(
          0.5 *
            (lineStart.x * lineEnd.y +
              lineEnd.x * point.y +
              point.x * lineStart.y -
              lineEnd.x * lineStart.y -
              point.x * lineEnd.y -
              lineStart.x * point.y),
        );
        const bottom = getEuclideanDistance(lineStart, lineEnd);
        const height = (area / bottom) * 2;
        return height;
      }

      function douglasPeucker(points, epsilon) {
        if (points.length < 5) {
          return points;
        }

        let dmax = 0;
        let index = 0;
        const end = points.length - 1;

        for (let i = 1; i < end; i++) {
          const d = getPerpendicularDistance(points[i], points[0], points[end]);
          if (d > dmax) {
            index = i;
            dmax = d;
          }
        }

        if (dmax > epsilon) {
          const recResults1 = douglasPeucker(
            points.slice(0, index + 1),
            epsilon,
          );
          const recResults2 = douglasPeucker(points.slice(index), epsilon);

          return recResults1
            .slice(0, recResults1.length - 1)
            .concat(recResults2);
        } else {
          return [points[0], points[end]];
        }
      }

      function drawPath(ctx, path) {
        ctx.beginPath();
        ctx.moveTo(path[0].x, path[0].y);

        for (let i = 1; i < path.length; i++) {
          ctx.lineTo(path[i].x, path[i].y);
        }

        ctx.stroke();
      }

      function drawCurvedPath(ctx, path, hasName = 0, lineWidth = 3.5) {
        if (path.length < 2) return;
        ctx.lineWidth = lineWidth;
        const collider = new Path2D();
        collider.moveTo(path[0].x, path[0].y);
        for (let j = 1; j < path.length - 1; j++) {
          const midPointX = (path[j].x + path[j + 1].x) / 2;
          const midPointY = (path[j].y + path[j + 1].y) / 2;
          collider.quadraticCurveTo(path[j].x, path[j].y, midPointX, midPointY);
        }
        collider.lineTo(path[path.length - 1].x, path[path.length - 1].y);
        ctx.beginPath();
        ctx.stroke(collider);
        setMousePaths((prevPaths) => ({
          ...prevPaths,
          [`${hasName}|${i}|${path[0].personaId}|${path[0].replay}`]: collider,
        }));
      }

      function getPersonaColor(personaId, personaData) {
        if (!personaId || !personaData) {
          return "transparent";
        }

        const persona = personaData.find((persona) => persona.id === personaId);
        return persona ? persona.color : "transparent";
      }

      // Draw real mousepath
      if (filters.heatmap && moves) {
        const vc = createOrClearCanvas(element, "mousepath-canvas");
        const ctx = vc.getContext("2d");
        if (moves.length > 0) {
          const epsilon = 36; // Adjust epsilon for more or less simplification
          const path = douglasPeucker(moves, epsilon);
          const last = path.length - 1;
          ctx.lineJoin = "round";
          ctx.lineCap = "round";

          ctx.strokeStyle = "white";
          drawCurvedPath(ctx, path, 0, 3.5);

          // Start point arrow
          const nextPoint = path[1];
          const isNextPointOnLeft = nextPoint && nextPoint.x < path[0].x;
          ctx.save();
          ctx.translate(path[0].x, path[0].y);
          if (isNextPointOnLeft) {
            ctx.scale(-1, 1); // Mirror horizontally
          }
          ctx.drawImage(loadedImage2, -46, -17, 28, 23);
          ctx.restore();

          // Draw start circle
          ctx.beginPath();
          ctx.arc(path[0].x, path[0].y, 6.5, 0, 2 * Math.PI);
          ctx.fillStyle = "white";
          ctx.fill();
          // Draw end circle
          ctx.beginPath();
          ctx.arc(path[last].x, path[last].y, 6.5, 0, 2 * Math.PI);
          ctx.fillStyle = "white";
          ctx.fill();

          personaMoves.forEach((persona) => {
            const color = getPersonaColor(
              persona.personaId,
              filters.personaData,
            );
            ctx.strokeStyle = color;
            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.arc(
              persona.moves[0].x,
              persona.moves[0].y,
              2.5,
              0,
              2 * Math.PI,
            );
            ctx.fill();
            drawCurvedPath(ctx, douglasPeucker(persona.moves, epsilon), 1, 3.5);
          });
        }
      } else {
        createOrClearCanvas(element, "mousepath-canvas");
      }

      if (filters.insights) {
        const canvas = createOrClearCanvas(element, "insights-canvas");
        const ctx = canvas.getContext("2d");

        const width = loadedImage.width;
        const height = loadedImage.height;
        const drawnLocations = new Set();

        insights.forEach((insight) => {
          const locationKey = `${insight.x},${insight.y}`;
          if (!drawnLocations.has(locationKey)) {
            ctx.drawImage(
              loadedImage,
              insight.x - width / 2,
              insight.y - height / 2 - 3,
              width,
              height,
            );
            ctx.font = "700 14px Satoshi";
            ctx.fillStyle = "black";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(Math.min(insight.num, 999), insight.x, insight.y);
            drawnLocations.add(locationKey);
          }
        });
      } else {
        createOrClearCanvas(element, "insights-canvas");
      }
      function isPointInHexagon(x, y, hexX, hexY, size) {
        const hexWidth = Math.sqrt(3) * size;
        const hexHeight = 2 * size;
        const dx = Math.abs(x - hexX);
        const dy = Math.abs(y - hexY);

        if (dx > hexWidth / 2 || dy > hexHeight / 2) {
          return false;
        }

        return (
          ((hexHeight / 2) * hexWidth) / 2 -
            (hexHeight / 2) * dx -
            (hexWidth / 2) * dy >=
          0
        );
      }

      function createHexagonPath(ctx, size) {
        const hexWidth = Math.sqrt(3) * size;
        const hexHeight = 2 * size;
        ctx.beginPath();
        ctx.moveTo(hexWidth / 2, 0);
        ctx.lineTo(hexWidth, hexHeight / 4);
        ctx.lineTo(hexWidth, (3 * hexHeight) / 4);
        ctx.lineTo(hexWidth / 2, hexHeight);
        ctx.lineTo(0, (3 * hexHeight) / 4);
        ctx.lineTo(0, hexHeight / 4);
        ctx.closePath();
      }
      function countPointsInHexagon(activity, hexX, hexY, size) {
        return activity.filter((point) =>
          isPointInHexagon(point.x, point.y, hexX, hexY, size + 3),
        ).length;
      }

      function getLastRecordingInHexagon(activity, hexX, hexY, size) {
        const candidates = activity.filter((point) =>
          isPointInHexagon(point.x, point.y, hexX, hexY, size + 3),
        );

        if (candidates.length === 0) {
          return null;
        }

        const scoreMap = new Map();
        let highestScore = -1;
        let bestCandidate = null;

        candidates.forEach((candidate) => {
          const replay = candidate.replay;
          const newScore = (scoreMap.get(replay) || 0) + 1;
          scoreMap.set(replay, newScore);

          if (newScore > highestScore) {
            highestScore = newScore;
            bestCandidate = replay;
          }
        });

        return bestCandidate;
      }

      function calculateMaxPointsInHexagon(activity, width, height, size, gap) {
        const hexWidth = Math.sqrt(3) * size;
        const hexHeight = 2 * size;
        const vertDist = (hexHeight * 3) / 4 + gap;
        const horizDist = hexWidth + gap;
        let maxPoints = 0;

        for (let row = 0; row * vertDist < height; row++) {
          for (let col = 0; col * horizDist < width; col++) {
            const offsetX =
              col * horizDist + (row % 2 === 1 ? horizDist / 2 : 0);
            const offsetY = row * vertDist;

            if (offsetX < width && offsetY < height) {
              const pointCount = countPointsInHexagon(
                activity,
                offsetX,
                offsetY,
                size,
              );
              if (pointCount > maxPoints) {
                maxPoints = pointCount;
              }
            }
          }
        }

        return maxPoints;
      }

      function drawHexagonGrid(ctx, width, height, size, gap, activity) {
        const hoverHexagons = [];
        const hexWidth = Math.sqrt(3) * size;
        const hexHeight = 2 * size;
        const vertDist = (hexHeight * 3) / 4 + gap;
        const horizDist = hexWidth + gap;
        const maxPoints = calculateMaxPointsInHexagon(
          activity,
          width,
          height,
          size,
          gap,
        );

        for (let row = 0; row * vertDist < height; row++) {
          for (let col = 0; col * horizDist < width; col++) {
            const offsetX =
              col * horizDist + (row % 2 === 1 ? horizDist / 2 : 0);
            const offsetY = row * vertDist;

            if (offsetX < width && offsetY < height) {
              let score = 0;
              const pointCount = countPointsInHexagon(
                activity,
                offsetX,
                offsetY,
                size,
              );
              if (activity.length > 0) {
                score = pointCount / maxPoints;
              }

              const colors = [
                "rgba(42, 0, 255, 0.8)",
                "rgba(32, 144, 140, 0.8)",
                "rgba(255, 211, 97, 0.7)",
                "rgba(235, 13, 53, 0.5)",
              ];
              const clampedScore = Math.max(0, Math.min(1, score));
              const index = Math.floor(clampedScore * (colors.length - 1));

              ctx.fillStyle =
                score === 0 ? "rgba(15, 12, 34, 0.3)" : colors[index];

              ctx.save();
              ctx.translate(offsetX, offsetY);
              createHexagonPath(ctx, size);
              ctx.fill();
              ctx.restore();
              if (score > 0) {
                hoverHexagons.push({
                  x: offsetX + 4,
                  y: offsetY + 4,
                  value: pointCount,
                  tier: index,
                  replay: getLastRecordingInHexagon(
                    activity,
                    offsetX,
                    offsetY,
                    size,
                  ),
                });
              }
            }
          }
        }
        return hoverHexagons;
      }

      if (filters.frustrations) {
        const canvas = createOrClearCanvas(element, "frustrations-canvas");
        const ctx = canvas.getContext("2d");
        const hex = drawHexagonGrid(
          ctx,
          canvas.width,
          canvas.height,
          6,
          1.5,
          frustrations,
        );

        frustrationClicks.push(hex);
      } else {
        createOrClearCanvas(element, "frustrations-canvas");
      }

      if (filters.hesitations) {
        const canvas = createOrClearCanvas(element, "hesitations-canvas");
        const ctx = canvas.getContext("2d");
        const hex = drawHexagonGrid(
          ctx,
          canvas.width,
          canvas.height,
          6,
          1.5,
          hesitations,
        );
        hoverHesitations.push(hex);
      } else {
        createOrClearCanvas(element, "hesitations-canvas");
      }
    }

    setHoverScrolls(hoverScrolls);
    setHoverClicks(hoverClicks);
    setHoverHesitations(hoverHesitations);
    setHoverInsights(hoverInsights);
    setHoverFrustrations(frustrationClicks);
    setHoverElements(hoverElements);
  }

  useEffect(() => {
    // Insight flame and mousemove arrow
    if (loadedImage && loadedImage2 && imagesLoaded) {
      renderHeatmapData(heatmapData, filters, imagesLoaded);
    }
    resetTooltip();
  }, [heatmapData, filters, imagesLoaded, loadedImage, loadedImage2]);

  const scrollToPage = (page) => {
    const elementId = `heatmap${page}`;
    const element = document.getElementById(elementId);
    if (element) {
      const scrollableParent = element.parentElement;
      const targetScrollLeft = element.offsetLeft - scrollableParent.offsetLeft;
      scrollableParent.scrollTo({
        left: targetScrollLeft,
        behavior: "smooth",
      });
    }
  };

  // Main tooltip rendering
  useEffect(() => {
    const nonAnchored = ["scroll"];
    const anchored = [
      "clicks",
      "heatmap",
      "frustrations",
      "hesitations",
      "insights",
      "mousepath",
    ];

    if (!tooltip || !tooltip?.show) {
      return;
    }

    if (nonAnchored.some((type) => tooltip.type === type && filters[type])) {
      const tooltipElement = document.createElement("div");
      tooltipElement.style.zIndex = "1000";
      tooltipElement.style.position = "absolute";
      tooltipElement.style.pointerEvents = "none";
      tooltipElement.className = `heatmap-tooltip-${
        tooltip.type === "insights"
          ? 2
          : tooltip.type === "frustrations" || tooltip.type === "hesitations"
            ? 3
            : 1
      } ${
        tooltip.type === "scroll"
          ? "v4-tooltip-follow-blue"
          : "v4-tooltip-follow-dark"
      }`;
      if (tooltip.text) {
        tooltipElement.innerText = tooltip.text;
      } else if (tooltip.html) {
        tooltipElement.innerHTML = tooltip.html;
      }

      if (tooltip.button) {
        const button = document.createElement("button");
        button.className =
          "mt-14 btn-quaternary mb-8 btn-heatmap-recording fvc";
        button.innerHTML =
          '<i class="fa-regular fa-play mr-10"></i>Play the recording';
        tooltipElement.appendChild(button);
      }

      document.body.appendChild(tooltipElement);

      if (tooltip.type === "scroll") {
        tooltipElement.style.left = `${tooltip.x}px`;
        tooltipElement.style.top = `${tooltip.y}px`;
        tooltipElement.style.display = "block";
      }

      return () => {
        document.body.removeChild(tooltipElement);
      };
    }

    if (
      anchored.some(
        (type) =>
          tooltip.type === type &&
          filters[type === "clicks" || type === "mousepath" ? "heatmap" : type],
      )
    ) {
      setTooltipContent(tooltip.html);
      setCoordinates({ x: tooltip.x, y: tooltip.y + 104, visible: true });
    }
  }, [
    tooltip?.show,
    tooltip?.type,
    tooltip?.text,
    tooltip?.html,
    tooltip?.x,
    tooltip?.y,
    filters.scroll,
    filters.clicks,
    filters.frustrations,
    filters.hesitations,
    filters.insights,
  ]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          userSelect: "none",
        }}
      >
        <button
          onClick={() => setPathModalOpen(true)}
          className="btn-quaternary fw-700"
        >
          Change the path
        </button>
        {CustomPaginator(page, scrollToPage, totalPages)}
      </div>

      {insightNav && isModalOpen && (
        <InsightModal
          isOpen={isModalOpen}
          onRequestClose={() => {
            setInsightNav(null);
            setIsModalOpen(false);
          }}
          insights={modalInsights}
          initialInsight={{ id: insightNav }}
          onDeleteInsight={() => {}}
          markInsightAsSeen={() => {}}
          setInsights={setModalInsights}
          setFilteredInsights={() => {}}
          userRole={userInfo.role}
          isHeatmap={true}
          setSelectedInsight={setSelectedInsight}
          selectedInsight={selectedInsight}
        />
      )}

      {hoveredReplay && recordingModalOpen && (
        <RecordingModal
          isOpen={recordingModalOpen}
          onRequestClose={() => {
            setHoveredReplay(null);
            setRecordingModalOpen(false);
          }}
          title={"View Heatmap Activity"}
          replay={hoveredReplay}
        />
      )}

      <div ref={trackerRef} id="mouseMoveTracker">
        <PopperTooltip
          coordinates={coordinates}
          tooltip={tooltipContent}
          containerRef={ref}
        />
        <div
          ref={ref}
          style={{
            width: `calc(100% + ${config.HEATMAP_SCROLL_OFFSET}px)`,
            userSelect: "none",
            paddingRight: `calc(100% - ${
              config.HEATMAP_PAGE_WIDTH - config.HEATMAP_SCROLL_OFFSET
            }px)`,
          }}
          {...events}
          className="hide-scrollbar heatmap-container flex overflow-x-scroll scrollbar-hide"
        >
          {!imagesLoaded ? (
            <Spinner style={{ height: "70px", width: "70px" }} />
          ) : funnelLoaded && imagesLoaded ? (
            funnel.map((item, i) => {
              return (
                <div
                  id={"heatmap" + i}
                  key={i}
                  className={`${
                    i === 0
                      ? "rounded-left"
                      : i === funnel.length - 1
                        ? "rounded-right"
                        : ""
                  } heatmap-item`}
                >
                  <div
                    className={`${
                      i === 0
                        ? "rounded-left"
                        : i === funnel.length - 1
                          ? "rounded-right"
                          : ""
                    }`}
                  >
                    <div
                      className={`heatmap-item-page ${
                        i === funnel.length - 1 ? "border-right" : ""
                      }`}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="step-page-container">
                          <div className="fs-16 fw-500 lh224 fc-black">
                            STEP {i + 1}
                          </div>
                          <div className="fs-18 fw-900 lh252 fc-black word-wrapper">
                            {item.page}
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              marginRight:
                                i < funnel.length - 1 ? "24px" : "0px",
                              borderRight:
                                i < funnel.length - 1
                                  ? "1px solid #E0E0E0"
                                  : "none",
                            }}
                          >
                            <div
                              style={{ minWidth: "86px" }}
                              className="fs-16 fw-500 lh224 fc-grey"
                            >
                              Sessions
                            </div>
                            <div className="fs-18 fw-900 lh252 fc-black word-wrapper">
                              {item.sessions}
                            </div>
                          </div>
                          {i < funnel.length - 1 && (
                            <div>
                              <div
                                style={{ minWidth: "55px" }}
                                className="fs-16 fw-500 lh224 fc-grey"
                              >
                                Dropoff
                              </div>
                              <div className="fs-18 fw-900 lh252 fc-black word-wrapper">
                                {Math.round(item.dropoff * 10000) / 100}%
                              </div>
                            </div>
                          )}
                          {/* {i === 0 && (
                            <button className="btn-quaternary heatmap-play-btn">
                              <FiPlay size="25px"></FiPlay>
                            </button>
                          )}

                          <button
                            style={{
                              marginLeft: i === 0 ? "0px" : "32px",
                            }}
                            className="btn-quaternary fw-500 fs-14 heatmap-goto-insights"
                          >
                            Go to insights
                          </button> */}
                        </div>
                      </div>
                    </div>
                    {!imgSrcsSized[i] && imgSrcs[i] && imagesLoaded ? (
                      <div className="no-heatmap fs-16 fw-500">
                        <div>{item.url === "Error" ? "" : item.url}</div>
                        <div>
                          {item.url === "Error"
                            ? "This page has no activity"
                            : "This page has no screenshot. The ability to add them manually is coming soon!"}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          id={`heatmap-page-${i}`}
                          style={{
                            position: "relative",
                            width: `${pageWidth}px`,
                            minHeight: "2px",
                          }}
                        >
                          <img
                            src={imgSrcsSized[i]}
                            alt="heatmap page"
                            style={{
                              width: "100%",
                              pointerEvents: "none",
                            }}
                          />
                          <div
                            className={`heatmap-overlay${
                              filters.frustrations || filters.hesitations
                                ? "-darker"
                                : ""
                            } ${i === 0 ? "" : "heatmap-overlay-line"}`}
                          ></div>

                          {(filters.frustrations || filters.hesitations) && (
                            <div className="heatmap-bar">
                              <img
                                style={{
                                  height: "19px",
                                  position: "absolute",
                                }}
                                src={heatmapBar}
                              ></img>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <Spinner style={{ height: "70px", width: "70px" }} />
          )}
        </div>
      </div>
    </>
  );
}
