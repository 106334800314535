import { useEffect, useRef } from "react";

export const InsightsTable = ({
  widths,
  headers,
  rows,
  minWidths,
  openModal,
  digits,
}) => {
  const tableRef = useRef(null);

  const syncColumnWidths = () => {
    const table = tableRef.current;
    if (!table) return;

    const theadThs = table.querySelectorAll("thead th");
    const tbodyRows = table.querySelectorAll("tbody tr");

    // Get widths from <th> elements
    const columnWidths = Array.from(theadThs).map(
      (th) => th.getBoundingClientRect().width,
    );

    // Apply the widths to all <td> elements
    tbodyRows.forEach((row) => {
      Array.from(row.children).forEach((td, index) => {
        td.style.width = `${columnWidths[index]}px`;
      });
    });
  };

  useEffect(() => {
    // Sync widths initially
    syncColumnWidths();

    // Re-sync widths on resize and scroll
    const handleResizeOrScroll = () => syncColumnWidths();
    window.addEventListener("resize", handleResizeOrScroll);
    window.addEventListener("scroll", handleResizeOrScroll);

    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      window.removeEventListener("scroll", handleResizeOrScroll);
    };
  }, [rows]);

  return (
    <table
      ref={tableRef}
      className="insights-table w-100"
      style={{ tableLayout: "fixed", minWidth: "1000px" }}
    >
      <thead>
        <tr className="d-flex align-items-center insights-header">
          {headers.map((header, j) => (
            <th
              onClick={() => header.sort && header.sort()}
              style={{
                width: "100%",
                maxWidth:
                  widths[j] === "dynamic" ? `${digits}` : `${widths[j]}`,
                minWidth:
                  minWidths[j] === "dynamic" ? `${digits}` : `${minWidths[j]}`,
              }}
              key={`${j}-header`}
              className="d-flex align-items-center flex-row"
            >
              {header.icon}
              <div
                className={header.className ?? "fs-16 fc-grey ml-10"}
                style={{ fontWeight: "400" }}
              >
                {header.title}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr
            onClick={() => openModal(row)}
            key={i}
            className="d-flex insights-row"
          >
            {headers.map((header, j) => (
              <td
                key={`${j}-row`}
                onClick={j === 0 ? (e) => e.stopPropagation() : undefined}
                style={{
                  minHeight: "35px",
                  paddingTop: header.paddingTop ?? "25px",
                  minWidth:
                    minWidths[j] === "dynamic"
                      ? `${digits}`
                      : `${minWidths[j]}`,
                }}
              >
                {header.render ? header.render(row, i) : row[header.field]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <hr style={{ margin: "0px" }}></hr>
    </table>
  );
};
