const config = {
  DEBUG_MODE: false,
  HEATMAP_PAGE_WIDTH: 650,
  HEATMAP_SCROLL_OFFSET: 80,
  HEATMAP_OFFSET: 200,
  STORY_PAGE_OFFSET: 24,
  HIGH_IMPACT: 0.7,
  MEDIUM_IMPACT: 0.3,
  LOW_IMPACT: 0,
  GENERATOR_OFFSET_Y: 0,
  GENERATOR_OFFSET_X: 0,
  STORY_PAGE_HEADER_HEIGHT: 98,
  STORY_MAX_ELEMENTS_PER_PAGE: 5,
};

export default config;
