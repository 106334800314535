import StoryPage from "./StoryPage";
import { useEffect, useRef, useState } from "react";
import config from "../../config";

const StoryGenerator = ({
  page,
  deviceType,
  mouseX,
  mouseY,
  setModalShow,
  setSelectedElement,
  setTooltipContent,
  setTooltipCoords,
  setStoryState,
  storyState,
  savedScroll,
  setSavedScroll,
}) => {
  const canvasRef = useRef(null);
  const [canvasHeight, setCanvasHeight] = useState(0);

  useEffect(() => {
    if (!canvasHeight || !canvasRef || !canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx || !canvas) return;
    canvas.width = window.innerWidth;
    canvas.height = canvasHeight;

    ctx.clearRect(config.HEATMAP_PAGE_WIDTH, 0, canvas.width, canvasHeight);
    ctx.fillStyle = "rgba(15, 12, 34, 0.65)";
    ctx.fillRect(0, 0, canvas.width, canvasHeight);
  }, [canvasHeight]);

  return (
    <div
      style={{
        position: "relative",
        width: "calc(100% + 80px)",
        minWidth: "1300px",
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          top: config.STORY_PAGE_HEADER_HEIGHT,
          left: 0,
          width: "100%",
          height: `${canvasHeight + 1}px`,
          pointerEvents: "none",
          zIndex: 10,
        }}
      />
      <StoryPage
        pageCount={1}
        i={0}
        page={page}
        deviceType={deviceType}
        mouseX={mouseX}
        mouseY={mouseY}
        blockMouse={true}
        storyState={storyState}
        setStoryState={setStoryState}
        setSelectedElement={setSelectedElement}
        setTooltipCoords={setTooltipCoords}
        setTooltipContent={setTooltipContent}
        setModalShow={setModalShow}
        canvasHeight={canvasHeight}
        setCanvasHeight={setCanvasHeight}
        savedScroll={savedScroll}
        setSavedScroll={setSavedScroll}
      />
    </div>
  );
};

export default StoryGenerator;
