import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Sankey,
  sankeyCenter,
  sankeyRight,
  sankeyLeft,
  sankeyJustify,
  SankeyNode,
} from "@visx/sankey";
import { Group } from "@visx/group";
import { BarRounded, LinkHorizontal } from "@visx/shape";
import { useTooltip, TooltipWithBounds } from "@visx/tooltip";
import "./Sankey.css";
import sankeyGoal from "../../../assets/sankey-goal.png";

type NodeDatum = { name: string };
type LinkDatum = {};

const nodeAlignments = {
  sankeyCenter,
  sankeyJustify,
  sankeyLeft,
  sankeyRight,
} as const;

const defaultMargin = { top: 10, left: 10, right: 10, bottom: 10 };

interface Goal {
  name: string;
  type: string;
  value: string;
}

export type Props = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data: any;
  goal: Goal;
  max: number;
  maxLinkWidth: number;
  setTotalPages: (pages: number) => void;
  selectedNodes: number[];
  setSelectedNodes: (nodes: number[]) => void;
  selectedLinks: number[];
  setSelectedLinks: (links: number[]) => void;
  rsOptions: number[];
  setRsOptions: (RsOptions: number[]) => void;
  lsOptions: number[];
  setLsOptions: (LsOptions: number[]) => void;
  setFunnel: (urls: any) => void;
  //Hover
  setHoveredId: (id: string) => void;
  setHoveredUrl: (url: string) => void;
};

export default function SankeyDiagram({
  width,
  height,
  margin = defaultMargin,
  data,
  goal,
  max,
  maxLinkWidth,
  setTotalPages,
  selectedNodes,
  setSelectedNodes,
  selectedLinks,
  setSelectedLinks,
  rsOptions,
  setRsOptions,
  lsOptions,
  setLsOptions,
  setFunnel,
  setHoveredId,
  setHoveredUrl,
}: Props) {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const background = "#ffffff";
  const color = "#707070";
  const sankeyPadding = window.innerWidth - 500;

  const [nodeAlignment, setTileMethod] =
    useState<keyof typeof nodeAlignments>("sankeyRight");
  const [nodePadding, setNodePadding] = useState(270);
  const [nodeWidth, setNodeWidth] = useState(170);

  const adjustNodeHeights = (
    nodes: SankeyNode<NodeDatum, LinkDatum>[],
  ): void => {
    if (!nodes || nodes.length === 0) return;
    nodes.forEach((node) => {
      if (node.y0 !== undefined) {
        node.y1 = node.y0 + 170;
      }
    });
  };

  const adjustLinkWidths = (
    links: SankeyLink<NodeDatum, LinkDatum>[],
  ): void => {
    if (!links || links.length === 0) return;
    links.forEach((link) => {
      if (link.value !== undefined) {
        link.y0 = link.source.y0 + 85;
        link.y1 = link.target.y1 - 85;
      }
    });
  };

  const calculateNodeSelection = (
    node: SankeyNode<NodeDatum, LinkDatum>,
    direction: string,
  ): {
    nodes: number[];
    links: number[];
    rsOptions: number[];
    lsOptions: number[];
  } => {
    const RS = traverse(node, "forwards");
    const LS = traverse(node, "backwards");
    if (direction === "forwards" || direction === "backwards") {
      const reset = direction === "forwards" ? RS : LS;
      reset.nodes = [];
      reset.links = [];
      reset.candidates = [];
    }
    return {
      nodes: Array.from(new Set([...LS.nodes, ...RS.nodes, node.index])),
      links: Array.from(new Set([...LS.links, ...RS.links])),
      rsOptions: Array.from(new Set([...RS.candidates])),
      lsOptions: Array.from(new Set([...LS.candidates])),
    };
  };

  const calculateLinkSelection = (
    link: SankeyLink<NodeDatum, LinkDatum>,
  ): {
    nodes: number[];
    links: number[];
    rsOptions: number[];
    lsOptions: number[];
  } => {
    const RS = traverse(link.source, "forwards");
    const LS = traverse(link.target, "backwards");
    return {
      nodes: Array.from(new Set([...LS.nodes, ...RS.nodes])),
      links: Array.from(new Set([...LS.links, ...RS.links, link.index])),
      rsOptions: Array.from(new Set([...RS.candidates])),
      lsOptions: Array.from(new Set([...LS.candidates])),
    };
  };

  const traverse = (
    node: SankeyNode<NodeDatum, LinkDatum>,
    direction: "forwards" | "backwards",
    visited: { nodes: number[]; links: number[]; candidates: number[] } = {
      nodes: [],
      links: [],
      candidates: [],
    },
    goal: Goal,
  ): { nodes: number[]; links: number[] } => {
    visited.nodes.push(node.index);

    const links =
      direction === "forwards" ? node.targetLinks : node.sourceLinks;

    if (!links) {
      return visited;
    }

    if (links.length === 0) {
      return visited;
    }

    if (links.length > 1) {
      if (node.name !== goal.name) {
        visited.candidates.push(...links.map((link) => link.index));
      }
      return visited;
    }

    visited.links.push(links[0].index);

    const nextNode =
      direction === "forwards" ? links[0].source : links[0].target;
    return traverse(nextNode, direction, visited);
  };

  function formatFunnel(nodesToSelect: any[], nodes: any[]): any[] {
    return nodesToSelect.map((j) => {
      return {
        pageName: nodes[j].name,
        url: nodes[j].url,
        depth: nodes[j].depth,
        type: "pageName",
      };
    });
  }

  const LinkHorizontalComponent = ({ links, createPath, nodes }) => {
    return (
      <>
        {links.map((link, i) => (
          <LinkHorizontal
            key={i}
            onClick={() => {
              const rs = rsOptions.includes(link.index);
              const ls = lsOptions.includes(link.index);
              const selection = calculateLinkSelection(link);
              if (rs) {
                setRsOptions(selection.rsOptions);
              }

              if (ls) {
                setLsOptions(selection.lsOptions);
              }

              if (rs || ls) {
                setSelectedLinks((prev) => [
                  ...prev,
                  ...selection.links,
                  link.index,
                ]);
                setSelectedNodes((prev) => {
                  const nodesToSelect = Array.from(
                    new Set([...prev, ...selection.nodes]),
                  );
                  setFunnel(formatFunnel(nodesToSelect, nodes));
                  return nodesToSelect;
                });
                return;
              }

              setSelectedLinks(selection.links);
              setSelectedNodes(selection.nodes);
              setFunnel(formatFunnel(selection.nodes, nodes));
              setRsOptions(selection.rsOptions);
              setLsOptions(selection.lsOptions);
            }}
            className={`visx-sankey-link ${
              selectedLinks.includes(link.index) ? "sankey-selected" : ""
            }
              ${
                [...rsOptions, ...lsOptions].includes(link.index)
                  ? "sankey-candidate"
                  : ""
              }
              `}
            data={link}
            path={createPath}
            fill="transparent"
            stroke={color}
            strokeWidth={Math.max(2, (link.value / max) * maxLinkWidth)}
            strokeOpacity={
              (rsOptions.length > 1 && rsOptions.includes(i)) ||
              (lsOptions.length > 1 && lsOptions.includes(i)) ||
              (selectedLinks.length > 1 && selectedLinks.includes(i)) ||
              (rsOptions.length === 0 &&
                lsOptions.length === 0 &&
                selectedLinks.length === 0)
                ? 1
                : 0.2
            }
            // onPointerMove={(event) => {
            //   const coords = localPoint(
            //     (event.target as SVGElement).ownerSVGElement,
            //     event,
            //   );
            //   showTooltip({
            //     tooltipData: `${
            //       (link.source as SankeyNode<NodeDatum, LinkDatum>).name
            //     } > ${(link.target as SankeyNode<NodeDatum, LinkDatum>).name} = ${
            //       link.value
            //     }`,
            //     tooltipTop: (coords?.y ?? 0) + 10,
            //     tooltipLeft: (coords?.x ?? 0) + 10,
            //   });
            // }}
            // onMouseOut={hideTooltip}
          />
        ))}
      </>
    );
  };

  const NodeComponent = ({ nodes, goal, data }) => {
    function isOpaque(candidates, selectedNodes, node) {
      return (
        (candidates.length === 0 && selectedNodes.length === 0) ||
        node.sourceLinks.some((link) => candidates.includes(link.index)) ||
        node.targetLinks.some((link) => candidates.includes(link.index)) ||
        selectedNodes.includes(node.index) ||
        node.name === goal.name
      );
    }
    return (
      <>
        {nodes.map(({ y0, y1, x0, x1, name, value, dropoff, hits }, i) => {
          // value represents the throughput
          // hits represents the number of users that actually visited
          // use hits to compare to dropoff
          const candidate = isOpaque(
            [...rsOptions, ...lsOptions],
            selectedNodes,
            nodes[i],
          );
          return (
            <g key={i}>
              <BarRounded
                className="visx-sankey-node"
                width={169}
                height={199}
                x={x0}
                y={y0}
                radius={8}
                all
                fill={"#ffffff"}
              />
              <foreignObject
                x={x0}
                y={y0}
                width={170}
                height={200}
                onPointerMove={() => {
                  setHoveredId(`node-${i}`);
                  setHoveredUrl(nodes[i].url);
                }}
                id={`node-${i}`}
              >
                <div
                  onClick={() => {
                    const rs = [
                      ...nodes[i].sourceLinks,
                      ...nodes[i].targetLinks,
                    ].filter((link) => rsOptions.includes(link.index));

                    const ls = [
                      ...nodes[i].sourceLinks,
                      ...nodes[i].targetLinks,
                    ].filter((link) => lsOptions.includes(link.index));

                    const selection = calculateNodeSelection(nodes[i], "");
                    let link = null;

                    if (rs.length === 1) {
                      link = rs[0].index;
                      setRsOptions(selection.rsOptions);
                    }

                    if (ls.length === 1) {
                      link = ls[0].index;
                      setLsOptions(selection.lsOptions);
                    }

                    // Autocompleted path
                    if (ls.length == 1 || rs.length == 1) {
                      setSelectedLinks((p) => [...p, ...selection.links, link]);
                      setSelectedNodes((prev) => {
                        const nodesToSelect = Array.from(
                          new Set([
                            ...prev,
                            nodes[i].index,
                            ...selection.nodes,
                          ]),
                        );
                        setFunnel(formatFunnel(nodesToSelect, nodes));
                        return nodesToSelect;
                      });
                      return;
                    }

                    // Adding a single node
                    setSelectedLinks(selection.links);
                    setSelectedNodes(selection.nodes);
                    setFunnel(formatFunnel(selection.nodes, nodes));
                    setRsOptions(selection.rsOptions);
                    setLsOptions(selection.lsOptions);
                  }}
                  className={`manrope no-select ${
                    name === goal.name ? "sankey-goal" : "sankey-node"
                  } ${selectedNodes.includes(i) ? "sankey-selected" : ""} 
                   ${candidate ? "" : "sankey-translucent"}`}
                  style={{
                    padding: "20px",
                    borderRadius: "8px",
                    minHeight: "170px",
                  }}
                >
                  {name === goal.name ? (
                    <img
                      src={sankeyGoal}
                      className="sankey-goal-img"
                      alt="Goal icon"
                    />
                  ) : (
                    <i className="fs-20 mb-20 fa-light fa-page"></i>
                  )}

                  <div className="fs-14 fw-500 limit-2-lines wrapword">
                    {name}
                  </div>
                  <div className="fs-14 fw-700">
                    <span className="fs-18">{hits} </span>
                    views
                  </div>
                  {name === goal.name ? (
                    <div className="fs-14 fw-700 sankey-percent-goal">
                      {((hits / data.total) * 100).toFixed(1)}% conversion
                    </div>
                  ) : (
                    <div className="fs-14 fw-700 sankey-percent">
                      {((hits / data.total) * 100).toFixed(1)}% of total
                    </div>
                  )}

                  {nodes[i].sourceLinks.length > 0 &&
                    dropoff > 0 &&
                    name !== goal.name && (
                      <div className="fs-14 fw-700 fc-red2">
                        <i className="fs-20 fa-regular fa-arrow-turn-left mr-10"></i>
                        {Math.round((dropoff / hits) * 100)}% dropoff
                      </div>
                    )}
                </div>
              </foreignObject>
            </g>
          );
        })}
      </>
    );
  };

  const [xMax, setXMax] = useState(500);
  const [yMax, setYMax] = useState(500);

  if (width < 10) return null;
  return (
    <div>
      <style>{`
        .visx-sankey-demo-container {
          background: ${background};
          padding: ${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px;
          border-radius: 5px;
          position: relative;
        }
      `}</style>
      <div className="visx-sankey-demo-container">
        <svg width={xMax + sankeyPadding} height={yMax + sankeyPadding}>
          <Sankey<NodeDatum, LinkDatum>
            root={data}
            nodeWidth={nodeWidth}
            size={[xMax, yMax]}
            nodePadding={nodePadding}
            nodeAlign={nodeAlignments[nodeAlignment]}
          >
            {({ graph, createPath }) => {
              if (graph.nodes && graph.links) {
                if (xMax === 500 || yMax === 500) {
                  const w = new Set();
                  const h = new Set();
                  graph.nodes.forEach((node) => {
                    w.add(node.x0);
                    h.add(node.y0);
                  });
                  setTotalPages(w.size);
                  setXMax(
                    w.size * 150 +
                      (w.size - 1) * 250 -
                      margin.left -
                      margin.right,
                  );
                  setYMax(h.size * 150 - margin.top - margin.bottom);
                }
                adjustNodeHeights(graph.nodes);
                adjustLinkWidths(graph.links);
              }

              return (
                <>
                  <Group>
                    <LinkHorizontalComponent
                      links={graph.links}
                      createPath={createPath}
                      nodes={graph.nodes}
                    />
                  </Group>
                  <Group>
                    <NodeComponent
                      nodes={graph.nodes}
                      goal={goal}
                      data={data}
                    />
                  </Group>
                </>
              );
            }}
          </Sankey>
        </svg>
        {tooltipOpen && (
          <TooltipWithBounds
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
          >
            {tooltipData}
          </TooltipWithBounds>
        )}
      </div>
    </div>
  );
}
