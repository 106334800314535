import "./Personas.css";
import { Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import {
  fetchSingleFlagIcon,
  formatCurrency,
  formatPercentagePlain,
  getAggregateImpactOfProjectId,
  getProjectGoals,
  sumProperty,
} from "../../helpers";
import trendUp from "../../assets/trend-up.png";
import trendDown from "../../assets/trend-down.png";

export function PersonaSummary({ selectedProject, selectedPersona, loading }) {
  const [keyNumbers, setKeyNumbers] = useState([]);
  const [impactLoading, setImpactLoading] = useState(true);
  const [processingLoading, setProcessingLoading] = useState(true);

  const [impact, setImpact] = useState(null);
  const [goals, setGoals] = useState(null);
  const [goalCards, setGoalCards] = useState(null);

  const [showAllGoalCards, setShowAllGoalCards] = useState(false);

  async function processKeyNumbers() {
    if (impactLoading) return;

    const country = selectedPersona["country_most_common_<lambda>"] ?? null;
    let flagIcon = "";

    if (country) {
      flagIcon = await fetchSingleFlagIcon(country);
    }

    const countryHtml = flagIcon ? (
      <div>
        <img
          src={`https://flagcdn.com/16x12/${flagIcon}.png`}
          width="15"
          height="10"
          style={{ marginBottom: "6px" }}
          alt={country}
        />{" "}
        <span>{country}</span>
      </div>
    ) : (
      <span>{country}</span>
    );

    let revenue = "Not set";
    let potentialGain = "Not set";
    let revenuePercentOfTotal = "";

    let personaConversions = 0;
    let personaDropoffs = 0;
    let globalConversions = 0;

    let personaCount = 0;
    let globalCount = 0;
    let personaCountOfTotal = "";

    let goalCards = [];
    let personaImpact = [];

    const purchaseValue = selectedProject.purchaseValue ?? null;
    if (impact && impact.length > 0) {
      personaImpact = impact.filter((i) => i.personaId === selectedPersona.id);

      personaConversions = sumProperty(personaImpact, "conversions");
      personaDropoffs = sumProperty(personaImpact, "dropoffs");
      globalConversions = sumProperty(impact, "conversions");

      personaCount = personaImpact[0]?.personaCount ?? 0;
      globalCount = impact[0].globalSessions;

      personaCountOfTotal = globalCount
        ? `${formatPercentagePlain(personaCount / globalCount)} of total`
        : "0% of total";
    }

    if (selectedProject.currency && purchaseValue) {
      revenue = `+${formatCurrency(
        purchaseValue * personaConversions,
        selectedProject.currency,
      )}`;

      potentialGain = `+${formatCurrency(
        purchaseValue * personaDropoffs,
        selectedProject.currency,
      )}`;

      revenuePercentOfTotal = globalConversions
        ? `${formatPercentagePlain(
            personaConversions / globalConversions,
          )} of total`
        : "0% of total";
    }

    // Goal cards
    goals.forEach((goal) => {
      const conversions =
        impact.find(
          (item) =>
            item.goalId === goal.id && item.personaId === selectedPersona.id,
        )?.conversions ?? 0;

      const conversionRate = personaConversions
        ? formatPercentagePlain(conversions / personaCount)
        : "No impact";

      const revenue = purchaseValue
        ? formatCurrency(conversions * purchaseValue, selectedProject.currency)
        : "Not set";

      goalCards.push({
        name: goal.label,
        conversionRate: conversionRate,
        revenue: revenue,
      });
    });

    setKeyNumbers([
      {
        title: "Sessions",
        data: personaCount === 0 ? "Not calculated" : personaCount,
        ofTotal: personaCountOfTotal,
      },
      {
        title: "Revenue",
        data: revenue,
        ofTotal: revenuePercentOfTotal,
      },
      {
        title: "Potential gain",
        data: potentialGain,
      },
      {
        title: "Main country",
        data: countryHtml,
      },
    ]);

    setGoalCards(goalCards);

    setProcessingLoading(false);
  }

  async function buildImpactData() {
    const [goals, impact] = await Promise.all([
      getProjectGoals(selectedProject.value),
      getAggregateImpactOfProjectId(selectedProject.value),
    ]);

    setGoals(goals);
    const goalIdToName = {};
    goals.forEach((goal) => {
      goalIdToName[goal.id] = goal.label;
    });

    const mostRecentDate = impact.reduce((latest, item) => {
      const itemDate = new Date(item.timestamp);
      return itemDate > latest ? itemDate : latest;
    }, new Date(0));

    const filteredImpact = impact.filter(
      (item) => new Date(item.timestamp).getTime() === mostRecentDate.getTime(),
    );

    setImpact(filteredImpact);

    setImpactLoading(false);
  }

  useEffect(() => {
    if (!selectedPersona || impactLoading) return;
    processKeyNumbers();
  }, [selectedPersona, impactLoading]);

  useEffect(() => {
    setImpactLoading(true);
    setProcessingLoading(true);
    buildImpactData();
  }, [selectedProject]);

  if (loading || impactLoading || (processingLoading && selectedPersona)) {
    return (
      <div className="hc mt-100">
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (selectedPersona) {
    return (
      <div className="hc">
        <div className="persona-summary-container">
          <div className="persona-summary fw-500 fs-16 lh-20 fc-black mt-50 mb-100">
            <div className="fc-grey fs-14 fw-500">Persona summary</div>
            <div className="mt-16 persona-summary-text fw-400 wrapword">
              {selectedPersona.gpt_summary_1 ? (
                <>
                  {selectedPersona.gpt_summary_1
                    .split(". ")
                    .map((sentence, index) => (
                      <div className="mb-1r" key={index}>
                        {sentence.trim()}
                        {sentence.trim().endsWith(".") ? "" : "."}
                      </div>
                    ))}
                  {selectedPersona.gpt_summary_2 && (
                    <>
                      <div className="mb-1r">
                        The most visited pages by this persona are:
                      </div>
                      <ul>
                        {selectedPersona.gpt_summary_2.map((item, index) => (
                          <li key={index} style={{ marginBottom: "10px" }}>
                            {index === selectedPersona.gpt_summary_2.length - 1
                              ? "and the "
                              : "The "}
                            <a
                              className="persona_summary_pageLink"
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              data-url={item.url}
                            >
                              {item.label}
                            </a>
                            , with {item.views} views
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {selectedPersona.gpt_summary_3 && (
                    <div className="mb-1r">
                      {selectedPersona.gpt_summary_3
                        .split(". ")
                        .map((sentence, index) => (
                          <div className="mb-1r" key={index}>
                            {sentence.trim()}
                            {sentence.trim().endsWith(".") ? "" : "."}
                          </div>
                        ))}
                    </div>
                  )}
                </>
              ) : (
                selectedPersona.gpt_summary
                  .split(". ")
                  .map((sentence, index) => (
                    <div className="mb-1r" key={index}>
                      {sentence.trim()}
                      {sentence.trim().endsWith(".") ? "" : "."}
                    </div>
                  ))
              )}
            </div>
          </div>

          <div className="mb-100">
            <div className="fw-700 fs-20 fc-black mb-20">
              Key Weekly Numbers
            </div>
            <div className="key-number-container">
              {keyNumbers.map((keyNumber, index) => (
                <div key={index} className="key-number-box">
                  <div className="key-number-title fs-16 lh-224 fc-black fw-500">
                    {keyNumber.title}
                  </div>
                  <div className="key-number-data fs-18 lh-252 fc-black2 fw-900">
                    {keyNumber.data}
                  </div>
                  <div className="key-number-of-total fs-14 lh-196 fc-grey fw-500 mt-5">
                    {keyNumber.ofTotal}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-100">
            <div className="fw-700 fs-20 fc-black mb-20">
              Goals {`(${goals.length})`}
            </div>
            <div>
              {goalCards
                .slice(0, showAllGoalCards ? goalCards.length : 2)
                .map((card, index) => (
                  <div key={index} className="personas-goal-card">
                    <div className="fs-16 lh-224 fc-black fw-500">
                      {card.name}
                    </div>
                    <div className="d-flex">
                      <div className="fs-16 lh-224 fc-black fw-500">
                        {card.conversionRate}
                      </div>
                      <div className="fs-16 lh-224 fc-grey fw-500 ml-20">
                        {card.revenue}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div>
              {goalCards.length > 2 && (
                <button
                  className="btn-link fw-700 fs-16 mt-14"
                  onClick={() => setShowAllGoalCards(!showAllGoalCards)}
                >
                  {showAllGoalCards
                    ? "Display less"
                    : `Display ${goalCards.length - 2} more`}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="fs-20 vhc mt-100">No persona selected</div>;
  }
}
