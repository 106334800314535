import "./TopNavbar.css";
import { Navbar, Nav, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Sidebar } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useRef } from "react";
import Select, { components } from "react-select";
import { NavLink } from "react-router-dom";
import arrowLeft from "../../assets/arrow-left.svg";
import arrowRight from "../../assets/arrow-right-black.svg";
import grayPfp from "../../assets/gray.PNG";
import luxiferLogo from "../../assets/luxifer-logo.svg";
import luxiferLogoSmall from "../../assets/small-icon-hires.png";
import luxiferLogoWhite from "../../assets/white-logo.png";
import voixLogo from "../../assets/voix-logo.png";
import dashboardLogo from "../../assets/dashboard.svg";
import notificationsLogo from "../../assets/notifications.svg";
import dashboardLogoInactive from "../../assets/dashboard-inactive.svg";
import notificationsLogoInactive from "../../assets/notifications-inactive.svg";
import todoLogoActive from "../../assets/blue-house.png";
import todoLogoInactive from "../../assets/black-house.png";
import lightbulbBlue from "../../assets/lightbulb-blue.png";
import lightbulbBlack from "../../assets/lightbulb-black.png";
import cursorBlue from "../../assets/cursor-blue.png";
import cursorBlack from "../../assets/cursor-black.png";
import logoutBtnWhite from "../../assets/power-off-light2.png";
import newProject from "../../assets/new-project.png";
import {
  UserInfoContext,
  IsMobileContext,
  ProjectsContext,
  SelectedProjectContext,
} from "../../contexts";
import projectsLogo from "../../assets/projects.svg";
import projectsLogoInactive from "../../assets/projects-inactive.svg";
import { auth } from "../../firebase";
import { firebaseLogout } from "../../helpers";
import FunnelAnalysisSidebarContent from "../FunnelAnalysisSidebarContent/FunnelAnalysisSidebarContent";
import NotificationPopup from "../NotificationPopup/NotificationPopup";

function TopNavbar({
  setUserInfo,
  setSelectedProject,
  activeMenuItem,
  sidebarCollapsed,
  setSidebarCollapsed,
  hideSidebar,
  profileView,
  setUserChannels,
  setAdminPage,
  setHeatmapFunnelFilters,
  heatmapFunnelFilters,
  heatmapOrFunnel,
  heatmapPrefsLoaded,
  setHeatmapPrefsLoaded,
  storyState,
  setStoryState,
  tagOptions,
  setTagOptions,
  disabledFilters,
  ...props
}) {
  const navbarExpand = "sm";
  const selectedPage = window.location.pathname;
  const navigate = useNavigate();
  let userInfo = useContext(UserInfoContext);
  if (props.isEmptyBar) {
    userInfo = null;
  }

  const isMobileView = useContext(IsMobileContext);
  const projects = useContext(ProjectsContext);
  const selectedProject = useContext(SelectedProjectContext);
  /*  const [menuCollapsed, setMenuCollapsed] = useState(true);*/
  const [projectsOpen, setProjectsOpen] = useState(false);
  const [projectImageLoading, setProjectImageLoading] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [hideSidebarText, setHideSidebarText] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [projectDropdownOptions, setProjectDropdownOptions] = useState([]);
  const selectRef = useRef();
  const projectListRef = useRef(null);
  const profileListRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      projectListRef.current &&
      !projectListRef.current.contains(event.target)
    ) {
      setProjectsOpen(false);
    }
  };

  const handleProfileClickOutside = (event) => {
    if (
      profileListRef.current &&
      !profileListRef.current.contains(event.target) &&
      !event.target.closest(".profile-container")
    ) {
      setAccountDropdownOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setHideSidebarText(windowWidth < 900);
  }, [windowWidth]);

  useEffect(() => {
    if (selectedProject && projects) {
      setProjectDropdownOptions(
        projects.filter((project) => project !== selectedProject),
      );
    }
  }, [selectedProject, projects]);

  useEffect(() => {
    if (projectsOpen && selectRef.current) {
      selectRef.current.focus();
    }
  }, [projectsOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleProfileClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleProfileClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      userInfo &&
      ["client", "owner", "reader", "editor"].includes(userInfo.role) &&
      projects &&
      projects.length > 0
    ) {
      const img = new Image();
      img.onload = () => setProjectImageLoading(false);
      img.onerror = () => {
        console.error("Error loading image");
        setProjectImageLoading(false);
      };
      img.src = projects[0].imageLink;
    }

    if (userInfo && projects && projects.length === 0) {
      setProjectImageLoading(false);
    }
  }, [projects]);

  const accountDropdownOptions = [
    { value: "profile", label: "Profile", icon: "fa-regular fa-solid fa-gear" },
    { value: "logout", label: "Logout", icon: "fa-regular fa-power-off" },
  ];

  const sidebarItems = [
    {
      icon: (
        <i
          className="fa-light fa-mask fa-xl"
          style={{
            marginLeft: "36px",
            marginRight: "16.5px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Account & Profile",
      link: "/profile",
      blockedRoles: [],
    },
    {
      icon: (
        <i
          className="fa-light fa-hippo  fa-xl"
          style={{
            marginLeft: "35px",
            marginRight: "15px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Manage projects",
      link: "/manage-projects",
      blockedRoles: [],
    },
    {
      icon: (
        <i
          className="fa-light fa-bell  fa-xl"
          style={{
            marginLeft: "38.5px",
            marginRight: "19px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Notifications",
      link: "/notifications",
      blockedRoles: [],
    },
    {
      icon: (
        <i
          className="fa-light fa-lock  fa-xl"
          style={{
            marginLeft: "38.5px",
            marginRight: "19px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Authorizations",
      link: "/authorizations",
      blockedRoles: [],
    },
    {
      activeImg: (
        <img
          src={cursorBlue}
          alt="cursor"
          style={{
            width: "20px",
            height: "22px",
            marginLeft: "38x",
            marginRight: "16px",
          }}
        />
      ),
      inactiveImg: (
        <img
          src={cursorBlack}
          alt="cursor"
          style={{
            width: "20px",
            height: "22px",
            marginLeft: "38px",
            marginRight: "16px",
          }}
        />
      ),
      text: "Preferences",
      link: "/preferences",
      blockedRoles: [],
    },
    {
      icon: (
        <i
          className="fa-light fa-file-invoice  fa-xl"
          style={{
            marginLeft: "38.5px",
            marginRight: "19px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Payment Plan",
      link: "/plan-and-billings",
      blockedRoles: ["owner", "editor", "reader"],
    },
    {
      icon: (
        <i
          className="fa-light fa-book-section  fa-xl"
          style={{
            marginLeft: "38.5px",
            marginRight: "19px",
            fontSize: "20px",
          }}
        ></i>
      ),
      text: "Legal",
      link: "/legal",
      blockedRoles: [],
    },
  ];

  const menuItems = [
    {
      name: "dashboard",
      label: "Dashboard",
      description:
        "Get a quick overview of your product's performance and key metrics.",
      icon: dashboardLogo,
      iconInactive: dashboardLogoInactive,
      link: "/",
      isMobile: false,
      userRoles: ["client", "owner", "reader", "editor"],
      marginLeft: "8px",
      marginRight: "15px",
      hover: "Go to your Dashboard",
    },
    {
      name: "todo",
      label: "Todo",
      icon: todoLogoActive,
      iconInactive: todoLogoInactive,
      link: "/",
      isMobile: false,
      userRoles: ["designer"],
      marginLeft: "5px",
      marginRight: "20px",
    },
    {
      name: "insights",
      label: "Insights",
      description:
        "Discover critical UX issues and opportunities for optimization.",
      icon: lightbulbBlue,
      iconInactive: lightbulbBlack,
      mobileIconActive: projectsLogo,
      mobileIconInactive: projectsLogoInactive,
      link:
        userInfo?.role &&
        ["client", "owner", "reader", "editor"].includes(userInfo.role)
          ? "/insights/view"
          : "",
      isMobile: false,
      userRoles: ["client", "designer", "owner", "reader", "editor"],
      marginLeft: "14px",
      marginRight: "21px",
    },
    {
      name: "funnel and heatmap",
      label: "Funnel & Heatmap",
      description: "Analyze user journeys and engagement across key pages.",
      icon: <i className="fs-24 fa-light fa-fire-flame-curved fa-2xl"></i>,
      link: "/funnels/view/",
      isMobile: false,
      marginLeft: "14px",
      marginRight: "21px",
      userRoles: ["client", "owner", "reader", "editor"],
      isIcon: true,
    },
    {
      name: "personas",
      label: "Personas",
      description:
        "Understand your user groups and their behaviors on your site.",
      icon: <i className="fs-24 fa-light fa-head-side-brain fa-2xl"></i>,
      link: "/personas/summary/",
      isMobile: false,
      marginLeft: "11px",
      marginRight: "21px",
      userRoles: ["client", "owner", "reader", "editor"],
      isIcon: true,
    },
    {
      name: "sankey",
      label: "Global Experience Map",
      description:
        "Visualize the complete user experience across your digital product.",
      icon: <i className="fs-24 fa-light fa-diagram-sankey fa-2xl"></i>,
      link: "/sankey/view/",
      isMobile: false,
      marginLeft: "8px",
      marginRight: "23px",
      userRoles: ["client", "owner", "reader", "editor"],
      isIcon: true,
    },
    {
      name: "some_path",
      label: "Some Path",
      icon: voixLogo,
      iconInactive: voixLogo,
      link: "/some_path/",
      isMobile: true,
      userRoles: ["client", "designer", "owner", "reader", "editor"],
      hover: "Some path",
    },
    {
      name: "notifications",
      label: "Notifications",
      icon: notificationsLogo,
      iconInactive: notificationsLogoInactive,
      link: "/notifications/",
      isMobile: true,
      userRoles: ["client", "designer", "owner", "reader", "editor"],
      hover: "Go to your notifications",
    },
    {
      name: "approveAnalysis",
      label: "Approve client analysis requests",
      icon: <i className="fa-sharp fa-light fa-universal-access fs-28"></i>,
      link: "/",
      isMobile: false,
      userRoles: ["admin"],
      marginLeft: "8px",
      marginRight: "20px",
      hover: "Access & Plans",
      isIcon: true,
    },
    {
      name: "insights",
      label: "Insights",
      icon: lightbulbBlue,
      iconInactive: lightbulbBlack,
      link: "/manage-insights",
      isMobile: false,
      userRoles: ["admin"],
      marginLeft: "14px",
      marginRight: "21px",
      hover: "Manage Insights",
    },
    {
      name: "messages",
      label: "Messages",
      icon: <i className="fa-sharp fa-light fa-mailbox fs-28"></i>,
      link: "/messages",
      isMobile: false,
      userRoles: ["admin"],
      marginLeft: "8px",
      marginRight: "21px",
      hover: "Messages",
      isIcon: true,
    },
    // {
    //   name: "managePaymentPlans",
    //   label: "Manage Payment Plans",
    //   icon: <i className="fa-sharp fa-light fa-mailbox fs-28"></i>,
    //   link: "/under-construction",
    //   isMobile: false,
    //   userRoles: ["admin"],
    //   marginLeft: "8px",
    //   marginRight: "20px",
    //   hover: "Messages (Under construction)",
    //   isIcon: true,
    // },
  ];

  const selectBackground = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--selected-background)"
        : "inherit",
      margin: "4px 0px",
    }),
  };

  const CustomOption = ({ innerProps, data }) => (
    <div {...innerProps} style={{ paddingLeft: "10px" }}>
      <img
        src={data.image}
        alt={data.label}
        className="project-dropdown-image img-keep-ratio"
      />
      <div
        className="fw-700 fs-16 lh-216 fc-black"
        style={{
          marginTop: "15px",
          maxWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {data.label}
      </div>
    </div>
  );

  const CustomProfileOption = ({ innerProps, data }) => (
    <div {...innerProps} style={{ paddingLeft: "10px" }}>
      {data.icon ? (
        <i
          className={`${data.icon} project-dropdown-image profile-option-icon img-keep-ratio`}
        ></i>
      ) : data.image ? (
        <img
          src={data.image}
          alt={data.label}
          className="project-dropdown-image img-keep-ratio"
        />
      ) : null}
      <div
        className="fw-700 fs-16 lh-216 fc-black"
        style={{
          marginTop: "11px",
          maxWidth: "150px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {data.label}
      </div>
    </div>
  );

  const onSelectAccountOption = (option) => {
    if (option.value === "profile") {
      navigate("/profile");
    } else if (option.value === "logout") {
      firebaseLogout(userInfo.uid);
      setUserInfo({});
      setSelectedProject({});
      setUserChannels([]);
      if (auth) {
        auth.signOut();
      }
      navigate("/");
    }
    setAccountDropdownOpen(false);
  };

  const onSelect = (project) => {
    if (project.value === "create-new-project") {
      navigate("/create-project");
      return;
    }

    if (project) {
      setSelectedProject(project);
      if (project.myRole) {
        setUserInfo((prev) => ({ ...prev, role: project.myRole }));
      }
    }
  };

  const menuItemsFiltered = menuItems.filter((item) =>
    item.userRoles.includes(userInfo?.role ?? "client"),
  );

  const CustomNoOptionsMessage = (props) => {
    return null;
  };

  const getIsOnline = (parties) => {
    if (parties && userInfo?.uid && props.onlineChats) {
      const partnerId = parties.filter((party) => party !== userInfo.uid)[0];

      return props.onlineChats.includes(partnerId) ? true : false;
    }
    return false;
  };

  if (!isMobileView && !profileView) {
    if (userInfo && Object.keys(userInfo).length > 0) {
      return (
        <>
          {props.isFunnel && (
            <Button
              className="d-flex justify-content-center align-items-center arrow-button"
              style={{
                left: !props.hideFunnelFilters ? "0px" : "292px",
                position: !props.hideFunnelFilters ? "fixed" : "absolute",
                zIndex: 0,
              }}
              onClick={() => {
                props.setHideFunnelFilters(true);
              }}
            >
              <img
                src={!props.hideFunnelFilters ? arrowLeft : arrowRight}
                key={!props.hideFunnelFilters ? arrowLeft : arrowRight}
              />
            </Button>
          )}

          <Navbar
            bg="white"
            expand={navbarExpand}
            fixed="top"
            className="navbar-absolute"
          >
            <Navbar.Brand>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className="v4-tooltip mt-35 topbar-tooltip fw-700">
                    LUXIFER home
                  </Tooltip>
                }
              >
                <Nav.Link
                  onClick={() => {
                    navigate("/");
                    if (userInfo && userInfo.role === "admin") {
                      setAdminPage("manage");
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  <img
                    src={luxiferLogoSmall}
                    alt="logo"
                    style={{
                      width: "auto",
                      maxWidth: "180px",
                      height: "28px",
                      marginLeft: "124px",
                      marginRight: "60px",
                    }}
                  />
                </Nav.Link>
              </OverlayTrigger>
            </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="d-flex w-100 justify-content-between">
                <div className="d-flex align-items-center">
                  {["client", "owner", "reader", "editor"].includes(
                    userInfo.role,
                  ) && (
                    <div className="d-flex align-items-center">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          !projectsOpen ? (
                            <Tooltip className="v4-tooltip mt-35 topbar-tooltip fw-700">
                              Your projects
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setProjectsOpen(!projectsOpen)}
                        >
                          <img
                            className="img-keep-ratio"
                            src={
                              selectedProject
                                ? selectedProject.image
                                : projectsLogoInactive
                            }
                            alt="Current project image"
                            style={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "5px",
                              marginRight: "10px",
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                      {projectsOpen && (
                        <div className="v4-project-list" ref={projectListRef}>
                          {userInfo &&
                            ["client", "owner", "editor"].includes(
                              userInfo.role,
                            ) && (
                              <div
                                className="navigate-to-manage"
                                onClick={(e) => {
                                  setProjectsOpen(false);
                                  navigate(
                                    `/manage-projects?pid=${selectedProject.value}`,
                                  );
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                  textDecoration: "underline",
                                }}
                              >
                                Manage project
                              </div>
                            )}

                          <Select
                            ref={selectRef}
                            options={projectDropdownOptions}
                            onChange={(project) => {
                              setProjectsOpen(false);
                              onSelect(project);
                            }}
                            value={selectedProject}
                            unstyled
                            menuIsOpen={true}
                            isSearchable={false}
                            styles={{ ...selectBackground }}
                            className="dropdown hidden-scrollbar"
                            classNames={{
                              placeholder: () => "projects-placeholder",
                              control: () =>
                                "expanded-projects-control d-flex align-items-center justify-content-between",
                              menu: () => "projects-menu",
                              option: () => "option",
                            }}
                            components={{
                              SingleValue: CustomOption,
                              Option: CustomOption,
                              NoOptionsMessage: CustomNoOptionsMessage,
                              DropdownIndicator: (props) => (
                                <components.DropdownIndicator {...props}>
                                  <div style={{ display: "none" }}></div>
                                </components.DropdownIndicator>
                              ),
                            }}
                            onMenuOpen={() => setProjectsOpen(true)}
                            defaultMenuIsOpen={true}
                          />
                          <div
                            className="fc-grey fw-500 fs-16 lh-216"
                            style={{
                              position: "relative",
                              paddingTop: "25px",
                              paddingLeft: "20px",
                            }}
                          >
                            {projectDropdownOptions.length > 1
                              ? "Other projects"
                              : "No other projects"}
                          </div>
                        </div>
                      )}

                      {!["reader", "owner", "editor"].includes(
                        userInfo?.role,
                      ) && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip className="v4-tooltip mt-35 topbar-tooltip fw-700">
                              Add a new project
                            </Tooltip>
                          }
                        >
                          <img
                            onClick={() => {
                              navigate("/create-project");
                            }}
                            src={newProject}
                            style={{
                              width: "50px",
                              height: "50px",
                              cursor: "pointer",
                            }}
                            alt="Create new project"
                          />
                        </OverlayTrigger>
                      )}
                    </div>
                  )}
                </div>
                <Nav className="mr-auto">
                  <div>
                    {menuItemsFiltered.map((item) => {
                      return (
                        !item.isMobile && (
                          <div
                            key={item.name}
                            className=""
                            style={{
                              background:
                                activeMenuItem === item.name
                                  ? "var(--main-blue)"
                                  : "inherit",
                            }}
                          />
                        )
                      );
                    })}
                  </div>
                  {menuItemsFiltered
                    .filter((item) => !item.isMobile)
                    .map((item) => (
                      <OverlayTrigger
                        key={item.name}
                        placement="bottom"
                        overlay={
                          <Tooltip className="v4-tooltip mt-35 topbar-tooltip">
                            <div className="fw-700">{item.label}</div>
                            <div className="fw-500 limit-3-lines wrapword lh-192">
                              <div>{item.description}</div>
                            </div>
                          </Tooltip>
                        }
                      >
                        <Nav.Link
                          as={NavLink}
                          to={item.link}
                          active={activeMenuItem === item.name}
                          className={`d-flex align-items-center position-relative nav-link-custom ${
                            activeMenuItem === item.name ? "active-tab" : ""
                          }`}
                        >
                          {item.isIcon ? (
                            <div
                              style={{
                                marginLeft: "0px",
                                marginRight: "0px",
                                height: "24px",
                              }}
                              className={
                                activeMenuItem === item.name
                                  ? "fc-blue"
                                  : "fc-black"
                              }
                            >
                              {item.icon}
                            </div>
                          ) : (
                            <img
                              style={{
                                marginLeft: "0px",
                                marginRight: "0px",
                                height: "24px",
                              }}
                              alt={item.name}
                              src={
                                item.name === activeMenuItem
                                  ? item.icon
                                  : item.iconInactive
                              }
                            />
                          )}
                        </Nav.Link>
                      </OverlayTrigger>
                    ))}
                </Nav>
                <div className="ml-auto d-flex align-items-center">
                  <div ref={selectRef} style={{ marginRight: "33px" }}>
                    <NotificationPopup userInfo={userInfo} />
                  </div>
                  {userInfo.role !== "admin" && (
                    <Nav>
                      {props.userChannels.map((channel) => (
                        <OverlayTrigger
                          key={channel.id}
                          placement="bottom"
                          overlay={
                            <Tooltip className="v4-tooltip mt-35 topbar-tooltip fw-700">
                              Discuss with {channel.title}
                            </Tooltip>
                          }
                        >
                          <Nav.Link
                            className={`message-icon d-flex align-items-center position-relative nav-link-custom-chat ${
                              activeMenuItem === "messages" ? "active-tab" : ""
                            }`}
                            onClick={() => {
                              props.handleChannelClick(channel);
                            }}
                          >
                            <img
                              src={channel.icon}
                              alt="channel"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "5px",
                                marginRight: "10px",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                width: "16px",
                                height: "16px",
                                background: getIsOnline(channel.parties)
                                  ? "#25A93A"
                                  : "#707070",
                                transform: `translateX(34px) translateY(-20px)`,
                                border: "2px solid white",
                                borderRadius: "50%",
                              }}
                            ></div>
                            {props.unreadMessagesArr[channel.id] > 0 && (
                              <div
                                style={{
                                  position: "absolute",
                                  width: "16px",
                                  height: "16px",
                                  background: "#2A00FF",
                                  transform: `translateX(30px) translateY(-10px)`,
                                  border: "2px solid white",
                                  borderRadius: "50%",
                                  color: "white",
                                  fontSize: "10px",
                                  textAlign: "center",
                                  lineHeight: "13px",
                                }}
                              >
                                {props.unreadMessagesArr[channel.id] > 99
                                  ? 99
                                  : props.unreadMessagesArr[channel.id]}
                              </div>
                            )}
                          </Nav.Link>
                        </OverlayTrigger>
                      ))}
                    </Nav>
                  )}
                  {userInfo.role !== "admin" && (
                    <div className="vertical-line"></div>
                  )}
                  <div className="profile-container">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setAccountDropdownOpen(!accountDropdownOpen)
                      }
                    >
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          !accountDropdownOpen ? (
                            <Tooltip className="v4-tooltip mt-35 topbar-tooltip fw-700">
                              Manage your account
                            </Tooltip>
                          ) : (
                            <></>
                          )
                        }
                      >
                        <img
                          src={userInfo?.pfp ?? grayPfp}
                          alt="profile pic"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            marginRight: "16px",
                          }}
                        />
                      </OverlayTrigger>
                      {!accountDropdownOpen ? (
                        <i
                          className="fa-solid fa-chevron-down"
                          style={{ marginRight: "10px", cursor: "pointer" }}
                        ></i>
                      ) : (
                        <i
                          className="fa-solid fa-chevron-up"
                          style={{ marginRight: "10px", cursor: "pointer" }}
                        ></i>
                      )}
                    </div>
                    {accountDropdownOpen && (
                      <div
                        className="v4-project-list v4-profile-list"
                        ref={profileListRef}
                      >
                        <Select
                          options={accountDropdownOptions}
                          onChange={onSelectAccountOption}
                          unstyled
                          menuIsOpen={true}
                          isSearchable={false}
                          styles={{ ...selectBackground }}
                          className="dropdown hidden-scrollbar"
                          classNames={{
                            placeholder: () => "profile-placeholder",
                            control: () =>
                              "expanded-projects-control d-flex align-items-center justify-content-between",
                            menu: () => "profile-menu",
                            option: () => "option",
                          }}
                          components={{
                            SingleValue: CustomProfileOption,
                            Option: CustomProfileOption,
                            NoOptionsMessage: CustomNoOptionsMessage,
                            DropdownIndicator: (props) => (
                              <components.DropdownIndicator {...props}>
                                <div style={{ display: "none" }}></div>
                              </components.DropdownIndicator>
                            ),
                          }}
                          onMenuOpen={() => setAccountDropdownOpen(true)}
                          defaultMenuIsOpen={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Navbar.Collapse>
            {props.isFunnel && (
              <div
                className={
                  !props.hideFunnelFilters ? "" : "calendar-cutoff-fix"
                }
              >
                <Sidebar
                  style={{
                    overflowX: "visible",
                    overflowY: "auto",
                    position: "fixed",
                    height: "100%",
                    width: !props.hideFunnelFilters ? "0px" : "340px",
                    zIndex: 10,
                    border: "none",
                    borderLeft: !props.hideFunnelFilters
                      ? "1px solid transparent"
                      : "1px solid #E5E5E5",
                    background: "transparent",
                    top: "70px",
                    left: "0px",
                  }}
                  className="hidden-scrollbar transparency-fix"
                  breakPoint="sm"
                  collapsed={!props.hideFunnelFilters}
                  collapsedWidth="0px"
                >
                  <div
                    style={{
                      paddingLeft: "51px",
                      paddingRight: "33px",
                      paddingBottom: "300px",
                      overflow: "scroll",
                      width: "390px",
                      backgroundColor: "#F7F7F7",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      height: "100%",
                      WebkitOverflowScrolling: "touch",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        overflow: "visible",
                      }}
                    >
                      <div
                        className="fs-20 fw-700 fc-black"
                        style={{ paddingTop: "114px", overflow: "visible" }}
                      >
                        Filters
                      </div>
                      <Button
                        className={`d-flex justify-content-center align-items-center arrow-button 
                              ${!props.hideFunnelFilters ? "d-none" : ""}`}
                        style={{
                          left: !props.hideFunnelFilters ? "0px" : "20px",
                          position: !props.hideFunnelFilters
                            ? "fixed"
                            : "relative",
                          width: "150px",
                        }}
                        onClick={() => {
                          props.setHideFunnelFilters(false);
                        }}
                      >
                        <img
                          src={
                            !props.hideFunnelFilters ? arrowLeft : arrowRight
                          }
                          key={
                            !props.hideFunnelFilters ? arrowLeft : arrowRight
                          }
                        />
                        <div
                          className="fc-black fs-16 fw-700"
                          style={{ width: "100px" }}
                        >
                          Close filters
                        </div>
                      </Button>
                    </div>
                    <FunnelAnalysisSidebarContent
                      selectedProject={selectedProject}
                      userInfo={userInfo}
                      heatmapOrFunnel={heatmapOrFunnel}
                      setHeatmapFunnelFilters={setHeatmapFunnelFilters}
                      setHideFunnelFilters={props.setHideFunnelFilters}
                      heatmapFunnelFilters={heatmapFunnelFilters}
                      heatmapPrefsLoaded={heatmapPrefsLoaded}
                      setHeatmapPrefsLoaded={setHeatmapPrefsLoaded}
                      storyState={storyState}
                      setStoryState={setStoryState}
                      tagOptions={tagOptions}
                      setTagOptions={setTagOptions}
                      disabledFilters={disabledFilters}
                      {...props}
                    />
                  </div>
                </Sidebar>
              </div>
            )}
          </Navbar>
        </>
      );
    } else {
      return (
        <Navbar bg="dark" variant="dark" expand={navbarExpand} fixed="top">
          <Navbar.Brand>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="v4-tooltip mt-35 topbar-tooltip fw-700">
                  LUXIFER home
                </Tooltip>
              }
            >
              <Nav.Link onClick={() => navigate("/")}>
                <img
                  src={luxiferLogoWhite}
                  alt="logo"
                  style={{
                    width: "auto",
                    maxWidth: "133.4px",
                    height: "32px",
                  }}
                />
              </Nav.Link>
            </OverlayTrigger>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {auth.currentUser && (
                <Nav.Link
                  onClick={() => {
                    auth.signOut();
                    navigate("/");
                  }}
                  className="d-flex align-items-center"
                >
                  <img
                    src={logoutBtnWhite}
                    alt="Logout"
                    style={{
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  Logout
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    }
  } else if (isMobileView) {
    return (
      <div className="mobile-sidebar d-flex align-items-center justify-content-center">
        {menuItemsFiltered.map((item) => {
          return (
            <Link
              className="mobile-sidebar-button"
              to={item.link}
              key={item.name}
            >
              <img
                style={{
                  height: "32px",
                }}
                alt={item.name}
                src={
                  item.name === activeMenuItem
                    ? (item?.mobileIconActive ?? item.icon)
                    : (item?.mobileIconInactive ?? item.iconInactive)
                }
              />
            </Link>
          );
        })}
      </div>
    );
  } else if (profileView) {
    return (
      <div
        style={{
          height: "100vh",
          minWidth: hideSidebarText ? "96px" : "340px",
        }}
        className="profile-sidebar"
      >
        <div style={{ height: "71px" }}>
          <a onClick={() => navigate("/")} role="button">
            {!hideSidebarText ? (
              <img
                src={luxiferLogo}
                alt="logo"
                style={{
                  width: "auto",
                  maxWidth: "133.4px",
                  height: "32px",
                  marginTop: "31.5px",
                  marginLeft: "19.53px",
                }}
              />
            ) : (
              <img
                src={luxiferLogoSmall}
                alt="logo"
                style={{
                  width: "auto",
                  maxWidth: "30px",
                  height: "30px",
                  marginTop: "31.5px",
                  marginLeft: "34px",
                }}
              />
            )}
          </a>
        </div>
        <div
          style={{
            height: "40px",
            paddingLeft: hideSidebarText ? "17px" : "20px",
            paddingBottom: hideSidebarText ? "59.78px" : "49.78px",
            paddingTop: hideSidebarText ? "44.4px" : "54.4px",
          }}
          className="d-flex flex-row"
        >
          <img
            src={userInfo?.pfp ?? grayPfp}
            alt="profile pic"
            className="modal-image flex-column"
            style={{
              width: hideSidebarText ? "60px" : "40px",
              height: hideSidebarText ? "60px" : "40px",
            }}
          />
          {!hideSidebarText && (
            <div
              style={{ marginLeft: "19px" }}
              className="flex-column profile-text-container fs-18 fw-500 fc-grey lh-20"
            >
              <p>Connecté en tant que</p>
              <p
                className="fw-700"
                style={{
                  maxWidth: "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userInfo?.firstName ?? "User"}.
              </p>
            </div>
          )}
        </div>

        <div>
          {sidebarItems.map(
            (item, index) =>
              !item.blockedRoles.includes(userInfo.role) && (
                <NavLink
                  style={{
                    height: "32px",
                    marginBottom: "22px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={index}
                  className={`checklist-page-item d-flex flex-row profile-sidebar-fix profile-sidebar-link ${
                    selectedPage === item.link
                      ? "custom-profile-active-border"
                      : ""
                  }`}
                  to={item.link}
                >
                  {item?.icon ? (
                    <div
                      className={` ${
                        selectedPage === item.link ? "" : "fc-black"
                      }`}
                    >
                      {item.icon}
                    </div>
                  ) : (
                    <div>
                      {selectedPage === item.link
                        ? item.activeImg
                        : item.inactiveImg}
                    </div>
                  )}

                  {!hideSidebarText && (
                    <div
                      className={` flex-column fs-18 lh-20 ${
                        selectedPage === item.link
                          ? "expanded-menu-label-active"
                          : "expanded-menu-label"
                      }`}
                      style={{
                        display: "block",
                        textAlign: "left",
                        paddingTop: "2px",
                      }}
                    >
                      {item.text}
                    </div>
                  )}
                </NavLink>
              ),
          )}
        </div>
      </div>
    );
  }
}

export default TopNavbar;
